import { TextInput } from "react-native-gesture-handler";
import tw from "twrnc";
import { isWeb } from "../../utils/platform";
import InputContainer from "./InputContainer";

export const inputStyle = tw`w-full rounded-lg px-3 h-10 border`;

const Input = ({
	label,
	value,
	onChange: onChangeText,
	placeholder,
	keyboardType = "default",
	isPassword,
}) => {
	return (
		<InputContainer label={label}>
			<TextInput
				style={{ outline: isWeb ? "none" : undefined, ...inputStyle }}
				onChangeText={onChangeText}
				value={value || ""}
				placeholder={placeholder}
				keyboardType={keyboardType}
				secureTextEntry={isPassword}
			/>
		</InputContainer>
	);
};

export default Input;
