import { track } from "@amplitude/analytics-react-native";
import Icon from "@expo/vector-icons/Entypo";
import Icon2 from "@expo/vector-icons/MaterialIcons";
import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import { PlatformContext, UserContext } from "../../contexts";
import { getNextOpenHour } from "../../utils/days";
import { getFile, setFavRestaurant } from "../../utils/db";
import { hasTrans, intTime, openMap, webBack } from "../../utils/others";
import { isDesktop, isIos, isWeb } from "../../utils/platform";
import settings, { styles } from "../../utils/settings";
import ActionButton from "../ActionButton";
import Dot from "../Dot";
import Loading from "../Loading";
import { TransProp } from "../Trans";

const RestaurantHeader = ({
	showHeader = true,
	noPb = false,
	noDots = false,
	restaurant,
	simple = false,
	imageChildren,
	showFav,
	children,
	hasBack,
	actionChildren,
}) => {
	const platform = useContext(PlatformContext);
	const [openHours, setOpenHours] = useState(false);
	const { navigate, goBack } = useNavigation();
	const { t } = useTranslation();
	const { user, lang } = useContext(UserContext);
	const [isUserFav, setIsUserFav] = useState(restaurant?.is_user_fav);

	useEffect(() => {
		setIsUserFav(restaurant?.is_user_fav);
	}, [restaurant]);

	if (!restaurant?.days) return <Loading />;

	const first_avail = restaurant?.avails_times?.[0];
	const nextOpen = getNextOpenHour(restaurant?.days);

	return (
		<>
			<View style={tw`overflow-hidden`}>
				{showHeader && (
					<View>
						<ImageBackground
							resizeMode="cover"
							defaultSource={platform.appLogo}
							style={tw`w-full ${isWeb ? "h-40" : "h-50"}`}
							source={
								!restaurant.image_cover
									? platform.appLogo
									: { uri: getFile(restaurant.image_cover, 500, 70) }
							}
						>
							{imageChildren}
						</ImageBackground>
						<View
							style={{
								...tw`flex-row justify-between`,
								position: "absolute",
								top: isIos ? 50 : 30,
								left: 20,
								right: 20,
							}}
						>
							<ActionButton
								onPress={() => (hasBack ? navigate("main", { screen: "nearby" }) : webBack(goBack))}
							/>
							{user?.id && showFav ? (
								<View style={tw`flex-row justify-between items-center`}>
									<ActionButton
										onPress={() => {
											setFavRestaurant(restaurant.id, !isUserFav);
											setIsUserFav(!isUserFav);
										}}
										source={isUserFav ? "heart" : "hearto"}
										color={isUserFav && platform.colors.red}
									/>
								</View>
							) : null}
							{actionChildren}
						</View>
						{!simple && restaurant.promo ? (
							<View
								style={{
									...tw`px-3 py-1 absolute bottom-5 left--17 rounded-full pl-20`,
									backgroundColor: platform.isPizzy
										? platform.colors.red
										: platform.colors.yellow_main,
								}}
							>
								<Text style={tw`font-medium ${platform.isPizzy ? "text-white" : ""}`}>
									<TransProp v={restaurant.promo} />
								</Text>
							</View>
						) : null}
					</View>
				)}
				<View
					style={{
						...tw`bg-white ${noPb ? "pt-5" : "py-5"} px-4`,
					}}
				>
					<Text style={styles.header_3xl}>
						<TransProp v={restaurant.name} />{" "}
					</Text>
					{hasTrans(restaurant?.description) && (
						<Text style={styles.desc_s}>
							<TransProp v={restaurant.description} />
						</Text>
					)}
					{!simple && (
						<>
							<View style={tw`flex-row items-center justify-between mt-2 mb-2`}>
								<Dot
									mr={8}
									color={
										restaurant.avails_first_in > 60
											? platform.colors.orange
											: restaurant.avails_first_in
											? platform.colors.green
											: platform.colors.red
									}
									style={{ paddingLeft: 4, ...tw`` }}
								>
									<Text style={styles.desc_s}>
										{restaurant.avails_first_in
											? restaurant.avails_first_in > 60
												? t(`restaurant.avail_at`, {
														time: intTime(first_avail),
												  })
												: t(`restaurant.avail_in`, {
														minutes: restaurant.avails_first_in,
												  })
											: nextOpen
											? t("restaurant.opens_on", {
													day: t(`day.${settings.days[nextOpen.day - 1]}`).toLowerCase(),
													time: intTime(nextOpen.hour),
											  })
											: t("restaurant.not_avail_now")}
									</Text>
								</Dot>
								<TouchableOpacity
									onPress={() => {
										if (!openHours)
											track("MENU_SEE_HOURS", {
												restaurant_id: restaurant.id,
											});

										setOpenHours(!openHours);
									}}
									style={tw`flex-row items-center justify-between`}
								>
									<Text
										style={{
											...styles.desc_s,
											textDecorationStyle: "solid",
											textDecorationLine: "underline",
										}}
									>
										{t(openHours ? "word.close" : "word.see_hours")}
									</Text>
								</TouchableOpacity>
							</View>
							{openHours && (
								<View style={tw`mb-2 ml-6`}>
									{[...restaurant.days]
										.sort((a, b) => a.id - b.id)
										.filter((x) => x?.hours?.length && settings.days[x.id - 1])
										.map((d, i) => (
											<View key={`day_${i}`} style={tw`flex-row items-center`}>
												<Text
													style={{
														...styles.desc_s,
														fontSize: 13,
														fontWeight: "400",
														width: 80,
													}}
												>
													{t(`day.${settings.days[d.id - 1]}`)}:
												</Text>
												<Text style={{ ...styles.desc_s, fontSize: 13 }}>
													{d.hours
														?.map((h) => `${intTime(h.from)} - ${intTime(h.until)}`)
														.join(`   ${t("word.and")}   `)}
												</Text>
											</View>
										))}
								</View>
							)}
							<TouchableOpacity onPress={() => openMap(restaurant.name[lang], restaurant.address)}>
								<Text style={styles.desc_s}>
									<Icon name="location-pin" color={platform.colors.red} size={17.5} />{" "}
									{restaurant.address.city} - {Math.round(restaurant.user_distance)} km
								</Text>
							</TouchableOpacity>

							{restaurant.delivery?.enabled && (
								<View style={tw`flex-row items-center mt-2`}>
									<Icon2 name="delivery-dining" color={platform.colors.red} size={17.5} />
									<Text style={{ ...styles.desc_s, marginLeft: 5 }} numberOfLines={1}>
										{t("order.delivery")}{" "}
										{restaurant.delivery?.price ? `${restaurant.delivery.price}€` : t("word.free")}
										{restaurant.delivery?.free_from
											? `  -  ${t("order.delivery_free_from")} ${restaurant.delivery?.free_from}€`
											: null}
									</Text>
								</View>
							)}
						</>
					)}

					{children}
				</View>

				{!isDesktop && !noDots ? (
					<ImageBackground
						source={require("../../../assets/dots.png")}
						style={{
							width: "100%",
							height: 6,
							position: "absolute",
							bottom: -3,
						}}
					/>
				) : null}
			</View>
		</>
	);
};

export default RestaurantHeader;
