import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, Text } from "react-native";
import tw from "twrnc";
import BigButton from "../../components/BigButton";
import { PlatformContext } from "../../contexts";
import { getIntTimeFromDate, getTodayLeftHours, hoursToArray, parseDate } from "../../utils/days";
import { intTime, intTime2 } from "../../utils/others";
import settings from "../../utils/settings";

const TimeSelector = ({ value, restaurant, onPress }) => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();
	const [all, setAll] = useState(false);
	const hours = getTodayLeftHours(
		restaurant.days,
		restaurant.time_before_order || settings.time_before_order
	);
	let selectedHour = getIntTimeFromDate(parseDate(value));
	if (selectedHour === 0) selectedHour = 24;

	const h = hoursToArray(hours, restaurant.time_before_order || settings.time_before_order);

	return (
		<ScrollView
			contentContainerStyle={tw`flex-row flex-wrap justify-start w-full gap-2`}
			style={tw`mt-5`}
		>
			{h
				.filter((x, i) => all || i < 16)
				.map((l) => (
					<BigButton
						onPress={() => {
							onPress(intTime2(l));
						}}
						key={`hour_${l}`}
						style={{ ...tw`py-2 px-0`, width: "23%" }}
						type={l === selectedHour ? "yellow" : "yellow-outline"}
						disabled={!restaurant?.avails_times?.includes(l)}
					>
						<Text
							style={{
								color: platform.isPizzy ? (l === selectedHour ? "white" : "black") : "black",
							}}
						>
							{intTime(l)}
						</Text>
					</BigButton>
				))}
			{!all && h?.length > 16 ? (
				<BigButton
					type="yellow-outline"
					style={tw`w-full py-2`}
					textStyle={tw`${platform.isPizzy ? `text-[${platform.colors.yellow_main}]` : ""}`}
					onPress={() => setAll(true)}
				>
					{t("word.show_all")}
				</BigButton>
			) : null}
		</ScrollView>
	);
};

export default TimeSelector;
