import { Text, View } from "react-native";
import tw from "twrnc";

const InputContainer = ({ label, children }) => {
	return (
		<View style={tw`w-full mb-3`}>
			{label && <Text style={tw`font-bold mb-1`}>{label}</Text>}
			{children}
		</View>
	);
};

export default InputContainer;
