import { useContext } from "react";
import { UserContext } from "../../contexts";

export const DEF_LANG = "en";

const Trans = ({ l }) => {
	return <>{l}</>;
};

export default Trans;

export const TransProp = ({ v }) => {
	const { lang } = useContext(UserContext);

	return v?.[lang] || v?.[DEF_LANG];
};
