import { useContext } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import { TransProp } from "../../components/Trans";
import YellowLine from "../../components/YellowLine";
import { UserContext } from "../../contexts";
import { getProductPriceWithExtras } from "../../utils/products";
import { styles } from "../../utils/settings";

const CheckoutProductCard = ({ item, isLast, onPress, disabled, children }) => {
	const { lang } = useContext(UserContext);

	return (
		<View key={item.uuid}>
			<TouchableOpacity
				disabled={disabled}
				style={tw`flex-row justify-between items-center`}
				onPress={onPress}
			>
				<Text style={{ ...styles.header_lg, width: 50 }}>{item.qty} x</Text>
				<View style={tw`flex-1`}>
					<Text style={styles.header_lg}>
						<TransProp v={item.product?.name} />
					</Text>
					{item.extras?.length > 0 && (
						<Text style={{ ...styles.desc_s, ...tw`pr-3` }}>
							{item.extras
								.map((e) => `${e.selection.name[lang]}${e.qty > 1 ? ` (x${e.qty})` : ""}`)
								.join(" - ")}
						</Text>
					)}
					{item.comment && <Text style={{ ...styles.desc_s }}>{item.comment}</Text>}
				</View>
				<Text style={styles.header_lg}>
					{getProductPriceWithExtras(item.product, item.qty, item.extras).toFixed(2)}€
				</Text>
				{children}
			</TouchableOpacity>
			{!isLast && <YellowLine style={tw`my-5`} />}
		</View>
	);
};

export default CheckoutProductCard;
