import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, ScrollView, Text, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../components/BigButton";
import Loading from "../../components/Loading";
import { InputBase, InputContainer, capitalizeName } from "../../components/QuickAuth";
import SimpleHeader from "../../components/SimpleHeader";
import { PlatformContext, UserContext } from "../../contexts";
import useApi from "../../hooks/useApi";
import { deleteMyUser, getUser, updateMyUser } from "../../utils/db";
import { webBack } from "../../utils/others";
import { isDesktop, isIos, isWeb } from "../../utils/platform";
import { styles } from "../../utils/settings";
import Screen from "../Screen";

const DeleteContainer = ({ children }) => <View style={tw`w-full pl-2 pr-2`}>{children}</View>;

const AccountDeletion = () => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();
	const { goBack } = useNavigation();
	const { signOut } = useContext(UserContext);

	return (
		<DeleteContainer>
			<BigButton
				onPress={async () => {
					if (isWeb) {
						const OK = window.confirm(t("word.are_you_sure"));
						if (OK) {
							await deleteMyUser();
							signOut();
							webBack(goBack);
						}
						return;
					}
					Alert.alert(t("word.are_you_sure"), t("account.delete_account_desc"), [
						{ text: t("word.cancel") },
						{
							text: t("word.confirm"),
							style: "destructive",
							onPress: async () => {
								await deleteMyUser();
								signOut();
								webBack(goBack);
							},
						},
					]);
				}}
				style={tw`py-2 mt-15 mb-5`}
				type="red-outline"
			>
				<Text style={{ color: platform.colors.red }}>{t("account.delete_account")}</Text>
			</BigButton>
		</DeleteContainer>
	);
};

const AccountModule = () => {
	const { goBack } = useNavigation();
	const [saving, setSaving] = useState(false);
	const { data, loading } = useApi(getUser);
	const [form, setForm] = useState(data);
	const { t } = useTranslation();

	useEffect(() => {
		setForm(data);
	}, [data]);

	const onSubmit = async () => {
		setSaving(true);

		await updateMyUser(form);

		webBack(goBack);
	};

	const oc = (f, v) => setForm((x) => ({ ...x, [f]: v }));

	return (
		<Screen Container={View} backgroundColor="white" style={tw`mt-0 pt-0 pb-0`}>
			<SimpleHeader
				title={t("account.my_information")}
				style={isDesktop && { marginTop: 80 }}
				left={!isDesktop && { onPress: () => webBack(goBack) }}
			/>
			{loading && <Loading />}
			{!loading && (
				<ScrollView
					style={{ flex: 1, ...tw`p-3 flex-1` }}
					contentContainerStyle={isDesktop && { maxWidth: "50%", marginLeft: "25%" }}
				>
					<Text style={{ ...styles.header_lg, ...tw`mb-3` }}>{t("field.firstname")}</Text>
					<InputContainer>
						<InputBase
							maxLength={20}
							onChangeText={(v) => oc("firstname", capitalizeName(v))}
							value={form?.firstname}
							style={tw`text-black text-lg mt--1`}
						/>
					</InputContainer>
					<Text style={{ ...styles.header_lg, ...tw`mb-3 mt-3` }}>{t("field.lastname")}</Text>
					<InputContainer>
						<InputBase
							maxLength={20}
							onChangeText={(v) => oc("lastname", capitalizeName(v))}
							value={form?.lastname}
							style={tw`text-black text-lg mt--1`}
						/>
					</InputContainer>
					<Text style={{ ...styles.header_lg, ...tw`mb-3 mt-3` }}>{t("field.email")}</Text>
					<InputContainer>
						<InputBase
							onChangeText={(v) => oc("email", v?.trim())}
							value={form?.email}
							style={tw`text-black text-lg mt--1`}
						/>
					</InputContainer>
					<Text style={{ ...styles.header_lg, ...tw`mb-3 mt-3` }}>{t("field.mobile")}</Text>
					<InputContainer style={tw`opacity-50`}>
						<InputBase editable={false} value={form?.mobile} style={tw`text-black text-lg mt--1`} />
					</InputContainer>
					<AccountDeletion />
				</ScrollView>
			)}

			<View
				style={tw`bg-white border-gray-300 pt-3 ${
					isIos ? "pb-5" : "pb-3"
				} flex-row justify-center px-5 border-t`}
			>
				<BigButton
					style={{ width: isDesktop ? "50%" : "100%" }}
					onPress={onSubmit}
					disabled={saving || !form?.email || !form?.firstname || !form?.lastname}
				>
					{t("word.save")}
				</BigButton>
			</View>
		</Screen>
	);
};

export default AccountModule;
