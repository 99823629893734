import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import { PlatformContext } from "../../contexts";
import { getCategories } from "../../utils/products";
import { styles } from "../../utils/settings";
import Loading from "../Loading";
import ProductCard from "../ProductCard";
import { TransProp } from "../Trans";
import YellowLine from "../YellowLine";

const CategoryHolder = ({ c, restaurant, menu, showAll, onPress, renderRight }) => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();
	const [all, setAll] = useState(false);
	const prods = menu.filter((x) => x.category?.id === c?.id);
	const collapsable = !showAll && c.shorten && prods.length > 5;
	const filteredProds = prods.filter((x, i) => !collapsable || all || i < 5);

	return (
		<View key={`category_${c.id}`} style={tw`mb-8`}>
			<Text style={{ ...styles.header_xl, ...tw`mb-3` }}>
				<TransProp v={c.name} />
			</Text>
			<View style={tw`bg-white rounded-xl overflow-hidden`}>
				{filteredProds.map((p, i) => (
					<View key={`prod_category_${i}`}>
						<ProductCard
							key={`product_${p.id}`}
							restaurant={restaurant}
							onPress={() => onPress(p)}
							p={p}
							renderRight={renderRight}
						/>
						{i !== filteredProds.length - 1 && <YellowLine style={{ marginTop: -1 }} />}
					</View>
				))}
				{collapsable && !all ? (
					<TouchableOpacity
						style={{
							...tw`py-5 flex-col items-center justify-center`,
							backgroundColor: platform.isPizzy ? "#cfddb6" : "#FEF8ED",
						}}
						onPress={() => setAll(true)}
					>
						<Text style={tw`text-center font-medium underline`}>{t("word.show_all")}</Text>
					</TouchableOpacity>
				) : null}
			</View>
		</View>
	);
};

const ProductList = ({ showAll, loading, onPress, restaurant, menu, renderRight }) => {
	if (loading) return <Loading />;
	if (!menu?.length) return null;

	const categories = getCategories(menu);

	return categories.map((c) => (
		<CategoryHolder
			key={`catgegory_holder_${c.id}`}
			c={c}
			showAll={showAll}
			loading={loading}
			onPress={onPress}
			restaurant={restaurant}
			menu={menu}
			renderRight={renderRight}
		/>
	));
};

export default ProductList;
