import Icon from "@expo/vector-icons/FontAwesome5";
import { useNavigation } from "@react-navigation/native";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../components/BigButton";
import HalfModal from "../../components/HalfModal";
import SimpleHeader from "../../components/SimpleHeader";
import { PlatformContext, UserContext } from "../../contexts";
import { parseFee, webBack } from "../../utils/others";
import { isDesktop, isIos } from "../../utils/platform";
import { styles } from "../../utils/settings";

const CheckoutFooter = ({ error, bags, restaurant, meta, status, onOrder, onEdit, menu }) => {
	const platform = useContext(PlatformContext);
	const { user, lang } = useContext(UserContext);
	const { goBack } = useNavigation();
	const { t } = useTranslation();
	const [modal, setModal] = useState(false);

	const order_total = bags.totalize(restaurant.id, menu);

	const getDeliveryPrice = () => {
		if (!meta.delivery) return 0;
		if (!restaurant.delivery) return 0;
		if (!restaurant.delivery.price) return 0;
		if (!restaurant.delivery.free_from) return restaurant.delivery.price;
		if (order_total >= restaurant.delivery.free_from) return 0;
		return restaurant.delivery.price;
	};

	const delivery_fee = getDeliveryPrice();
	const grand_total = order_total + parseFee(restaurant.customer_fee) + delivery_fee;

	return (
		<>
			<View style={tw`bg-white border-gray-300 pt-3 ${isIos ? "pb-5 px-5" : "p-3"} border-t`}>
				{meta.delivery && (
					<View style={tw`flex-row justify-between`}>
						<View>
							<Text style={styles.header_lg}>{t("delivery.fee")}</Text>
						</View>
						<Text style={styles.header_lg}>
							{delivery_fee ? `${delivery_fee.toFixed(2)}€` : t("word.free")}
						</Text>
					</View>
				)}
				<View style={tw`flex-row justify-between`}>
					<View>
						<Text style={styles.header_2xl}>{t("checkout.to_pay")}</Text>
					</View>
					<Text style={styles.header_2xl}>{grand_total.toFixed(2)}€</Text>
				</View>
				<TouchableOpacity onPress={() => setModal(true)} style={tw`flex-row justify-between mb-5`}>
					<Text style={{ ...styles.desc_s, ...tw`text-gray-600` }}>
						{t(!restaurant.customer_fee ? "orders.fee_for_restaurant" : "orders.fee_included", {
							name: restaurant?.name?.[lang],
							amount: parseFee(restaurant?.customer_fee).toFixed(2),
						})}
					</Text>
				</TouchableOpacity>
				{error && (
					<Text style={{ ...styles.error_me, ...tw`text-center mb-3` }}>{t(`error.${error}`)}</Text>
				)}
				{restaurant.minimum_amount_order > 0 && grand_total < restaurant.minimum_amount_order ? (
					<View style={{ ...tw`flex-row items-center justify-center mt--3 mb-3` }}>
						<View
							style={{
								...tw`flex-col items-center justify-center mr-2`,
								backgroundColor: platform.colors.red,
								borderRadius: 15,
								height: 15,
								width: 15,
							}}
						>
							<Icon name="euro-sign" color="white" size={10} />
						</View>
						<Text
							style={{
								...styles.desc_s,
								color: platform.colors.red,
								maxWidth: isDesktop ? "90%" : "70%",
							}}
							numberOfLines={1}
						>
							{t("menu.order_min")} {restaurant.minimum_amount_order}€
						</Text>
					</View>
				) : null}
				<View style={tw`flex-row items-center justify-between gap-2`}>
					{!isDesktop && (
						<BigButton
							icon="plus"
							iconColor={platform.isPizzy ? platform.colors.yellow_main : "black"}
							disabled={status !== 1}
							type={platform.isPizzy ? "yellow-outline" : "outline"}
							onPress={() => webBack(goBack)}
							style={tw`flex-grow flex-1 py-5`}
						/>
					)}
					{!meta.pickup_at && (
						<BigButton
							type={platform.isPizzy ? "yellow-outline" : "outline"}
							onPress={onEdit}
							style={tw`flex-grow flex-4 text-white`}
						>
							<Text style={tw`${platform.isPizzy ? `text-[${platform.colors.yellow_main}]` : ""}`}>
								{t("checkout.define_where_when")}
							</Text>
						</BigButton>
					)}
					{meta.pickup_at && (
						<BigButton
							disabled={
								!restaurant.avails_first_in ||
								status !== 1 ||
								!bags.get(restaurant.id)?.length ||
								!meta.pickup_at ||
								(restaurant.minimum_amount_order > 0 &&
									grand_total < restaurant.minimum_amount_order)
							}
							onPress={onOrder}
							style={tw`flex-grow flex-4`}
							type={!user ? "outline" : "yellow"}
						>
							{t(!user ? "checkout.sign_in" : "checkout.order")}
						</BigButton>
					)}
				</View>
			</View>
			<HalfModal visible={modal}>
				<SimpleHeader hideLeft style={tw`mt-0 p-0 mb-5 h-auto`} title={t("fees.title")} />
				<Text>{t("fees.desc")}</Text>
				<View style={tw`w-full mt-5`}>
					<BigButton onPress={() => setModal(false)}>{t("word.okay")}</BigButton>
				</View>
			</HalfModal>
		</>
	);
};

export default CheckoutFooter;
