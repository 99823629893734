import { track } from "@amplitude/analytics-react-native";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { getUser, updateUserLanguage } from "../utils/db";
import { getLocationAsync, openSettingsAlert } from "../utils/media";
import { isDesktop } from "../utils/platform";
import { readSafe, removeSafe, writeSafe } from "../utils/safe";
import settings from "../utils/settings";

const useAuth = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState(null);
	const [myRestaurants, setMyRestaurants] = useState(null);
	const [location, setLocation] = useState(null);
	const [token, setToken] = useState(null);
	const [lang, setLang] = useState(null);
	const [restaurantView, setRestaurantView] = useState(false);

	const init = async (newToken) => {
		setLoading(true);
		let langToSet = lang;
		let hasLanguageOnProfile = false;

		const savedLocation = await readSafe("location", null);
		if (savedLocation) setLocation(savedLocation);

		if (!newToken) newToken = await readSafe("token");
		if (newToken) {
			const { data: me } = await getUser().catch((e) => {
				if (e?.response?.status === 403) {
					writeSafe("token", null);
				}
			});

			if (me?.id) {
				setToken(newToken);
				setUser(me);

				if (me.language) {
					langToSet = me.language;
					hasLanguageOnProfile = true;
				}

				if (me.myRestaurantsNew) {
					const mr = me.myRestaurantsNew?.filter((x) => x);
					if (mr.length > 0) setMyRestaurants(mr);
				} else if (me.myRestaurants) {
					const mr = me.myRestaurants
						?.filter((x) => x)
						?.map((r) => ({ id: r, is_test: false, validated: true }));
					if (mr.length > 0) setMyRestaurants(mr);
				}
			}
		}

		getLocationAsync().then(async (x) => {
			if (!x) {
				openSettingsAlert(
					t("permission.location"),
					t("permission.location_settings"),
					t("word.cancel"),
					t("account.goto_settings")
				);

				const savedLocation = await readSafe("location", null);
				setLocation(savedLocation || settings.default_location);
			} else {
				const newLocation = [x.coords.longitude, x.coords.latitude];
				setLocation(newLocation);
				writeSafe("location", newLocation);
			}
		});

		if (langToSet) {
			changeLang(langToSet);
			if (!hasLanguageOnProfile && newToken) updateUserLanguage(langToSet);
		} else {
			readSafe("lang", null).then((x) => {
				if (x && x !== lang) {
					langToSet = x;
					changeLang(x);
				} else {
					langToSet = i18n.language;
				}
				if (!hasLanguageOnProfile && newToken) updateUserLanguage(langToSet);
			});
		}

		setLoading(false);
		setLang(langToSet || i18n.language);
	};

	const signIn = async (result) => {
		setLoading(true);

		if (result?.token) {
			await writeSafe("token", result.token);
			await init(result.token);

			track("SIGN_IN");
		}

		setLoading(false);
	};

	const toggleRestaurantView = async () => {
		setLoading(true);

		if (!myRestaurants?.length) return setRestaurantView(false);

		setRestaurantView(!restaurantView);
		writeSafe("restaurant_view", !restaurantView);

		setLoading(false);
	};

	const signOut = async () => {
		setLoading(true);
		await removeSafe("token");
		setUser(null);
		setToken(null);
		setLoading(false);

		track("SIGN_OUT");
	};

	const changeLang = (newLang, doTrack) => {
		setLang(newLang);
		i18n.changeLanguage(newLang);
		writeSafe("lang", newLang);

		if (doTrack) track("CHANGE_LANGUAGE", { language: newLang, isLoggedIn: !!user });

		if (user) updateUserLanguage(newLang);
	};

	useEffect(() => {
		init();
		readSafe("restaurant_view", false).then((x) => {
			if (x && x !== restaurantView) {
				setRestaurantView(x);
			}
		});
	}, []);

	return {
		user,
		token,
		lang,
		loading,
		signIn,
		signOut,
		changeLang,
		toggleRestaurantView,
		location,
		restaurantView: !user ? false : isDesktop ? false : restaurantView && myRestaurants,
		myRestaurants: !user ? null : isDesktop ? null : myRestaurants,
	};
};

export default useAuth;
