import { Audio } from "expo-av";
import { useEffect } from "react";

export const tracks = {
	new_order: require("../../../assets/sounds/new_order.mp3"),
	bloop_1: require("../../../assets/sounds/bloop_1.mp3"),
	coins: require("../../../assets/sounds/coins.mp3"),
	jackpot_1: require("../../../assets/sounds/jackpot_1.mp3"),
	ping_1: require("../../../assets/sounds/ping_1.mp3"),
	ping_2: require("../../../assets/sounds/ping_2.mp3"),
	ping_3: require("../../../assets/sounds/ping_3.mp3"),
	surprise: require("../../../assets/sounds/surprise.mp3"),
};

export const soundObject = new Audio.Sound();

export const playOnce = async (track) => {
	const t = tracks[track] || tracks.new_order;

	await Audio.setAudioModeAsync({ playsInSilentModeIOS: true });

	if (soundObject._loaded) {
		soundObject.unloadAsync();
	}

	await soundObject.loadAsync(t);

	await soundObject.playAsync();
};

const SoundPlayer = ({ track, play }) => {
	const handle = async () => {
		await Audio.setAudioModeAsync({ playsInSilentModeIOS: true });

		const t = tracks[track] || tracks.new_order;

		if (soundObject._loading) return;

		if (!soundObject._loaded) {
			await soundObject.loadAsync(t);
		}

		if (play) {
			await soundObject.playAsync();
			await soundObject.setIsLoopingAsync(true);
		} else {
			await soundObject.pauseAsync();
		}
	};

	useEffect(() => {
		handle();
	}, [play]);

	return null;
};

export default SoundPlayer;
