import { DateTime } from "luxon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import { PlatformContext } from "../../contexts";
import { getNextOpenHour, parseDate } from "../../utils/days";
import { getFile } from "../../utils/db";
import { intTime } from "../../utils/others";
import settings, { styles } from "../../utils/settings";
import ActionButton from "../ActionButton";
import { TransProp } from "../Trans";

const CheckoutCard = ({
	style,
	meta,
	isWarning,
	restaurant,
	isSuccess,
	onEdit,
	children,
	simple,
}) => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();

	const canOrder = restaurant.avails_first_in && onEdit;
	const nextOpen = getNextOpenHour(restaurant?.days);

	return (
		<TouchableOpacity
			style={{
				...tw`flex flex-row items-center p-2 m-3 border border-gray-300 rounded-xl`,
				...(!isSuccess && !canOrder
					? {
							backgroundColor: "rgba(196, 51, 30, 0.1)",
							borderColor: platform.colors.red,
					  }
					: isWarning
					? {
							backgroundColor: platform.isPizzy ? "rgba(136, 170, 73, 0.1)" : "#fef8ed",
							borderColor: platform.colors.yellow_main,
					  }
					: null),
				...style,
			}}
			onPress={onEdit}
			disabled={!canOrder}
		>
			<ImageBackground
				resizeMode="cover"
				style={tw`w-20 h-20 rounded-xl overflow-hidden`}
				source={
					!restaurant.image_cover
						? platform.appLogo
						: { uri: getFile(restaurant.image_cover, 500, 70) }
				}
				defaultSource={platform.appLogo}
			/>
			<View style={tw`flex-1 ml-4 flex-grow`}>
				<Text style={{ ...styles.header_lg, textAlign: "left" }}>
					<TransProp v={restaurant.name} />
				</Text>
				{children}
				{!simple &&
					(!canOrder ? (
						<View style={tw`flex-row items-center gap-2`}>
							<Text style={tw`text-[${platform.colors.red}] font-medium`}>
								{nextOpen
									? t("restaurant.opens_on", {
											day: t(`day.${settings.days[nextOpen.day - 1]}`).toLowerCase(),
											time: intTime(nextOpen.hour),
									  })
									: t("restaurant.not_avail_now")}
							</Text>
						</View>
					) : meta.pickup_at ? (
						<>
							<Text style={styles.desc_s}>
								{meta.eat_in ? (
									<>
										{t("word.eat_in_places")}
										{": "}
										<TransProp v={meta.eat_in.name} />
									</>
								) : meta.delivery ? (
									t("order.be_delivered")
								) : (
									t("order.pickup_asap")
								)}
							</Text>
							<Text style={styles.desc_s}>
								{t("order.ready_at_time", {
									time: parseDate(meta.pickup_at).toLocaleString(DateTime.TIME_24_SIMPLE),
								})}
							</Text>
						</>
					) : (
						<View style={tw`flex-row items-center gap-2`}>
							<ImageBackground
								resizeMode="contain"
								source={
									platform.isPizzy
										? require("../../../assets/burger_frite_time_pizzy.png")
										: require("../../../assets/burger_frite_time.png")
								}
								style={{ height: 25, width: 30 }}
							/>
							<Text style={tw`text-[${platform.colors.yellow_main}] font-medium`}>
								{t("checkout.define_where_when")}
							</Text>
						</View>
					))}
			</View>
			{canOrder && (
				<ActionButton style={tw`bg-transparent`} source="right" size={25} onPress={onEdit} />
			)}
		</TouchableOpacity>
	);
};

export default CheckoutCard;
