import AsyncStorage from "@react-native-async-storage/async-storage";

export const writeSafe = async (name, value) => {
	await AsyncStorage.setItem(name, JSON.stringify({ value }));
	return true;
};

export const readSafe = async (name, def = null) => {
	const raw = await AsyncStorage.getItem(name);
	if (!raw) return def;

	return JSON.parse(raw).value || def;
};

export const removeSafe = async (name) => {
	await AsyncStorage.removeItem(name);
	return true;
};
