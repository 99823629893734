import { track } from "@amplitude/analytics-react-native";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../components/BigButton";
import CheckoutCard from "../../components/CheckoutCard";
import CheckoutProductCard from "../../components/CheckoutProductCard";
import ClosedRestaurant from "../../components/ClosedRestaurant";
import HalfModal from "../../components/HalfModal";
import Mapper from "../../components/Mapper";
import Redirect from "../../components/Redirect";
import SimpleHeader from "../../components/SimpleHeader";
import { BagContext, PlatformContext, UserContext } from "../../contexts";
import { createOrder } from "../../utils/db";
import { webBack } from "../../utils/others";
import { isDesktop } from "../../utils/platform";
import settings, { styles } from "../../utils/settings";
import Screen from "../Screen";
import { AddressBadge } from "./CheckoutAddress";
import CheckoutFooter from "./CheckoutFooter";
import CheckoutModal from "./CheckoutModal";
import CheckoutPaymentTypeModal, { CheckoutPaymentTypeIcons } from "./CheckoutPaymentTypeModal";

const CheckoutForm = ({ checkout, restaurant }) => {
	const [loading, setLoading] = useState(false);
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState(null);
	const { t } = useTranslation();

	const onSubmit = async () => {
		setLoading(true);

		if (!stripe || !elements) {
			return setLoading(false);
		}

		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${window.location.origin}/order?order_id=${checkout.order_id}&restaurant_id=${restaurant.id}`,
			},
		});

		if (result.error) {
			setError(result.error.message);
			setLoading(false);
		}
	};

	return (
		<form>
			<PaymentElement
				options={{
					wallets: {
						applePay: "auto",
						googlePay: "auto",
					},
					defaultValues: {
						billingDetails: {
							name: checkout.payment.customer_name,
						},
					},
				}}
			/>
			{error && (
				<View style={tw`mt-5`}>
					<Text style={styles.error_me}>{error}</Text>
				</View>
			)}
			<BigButton disabled={loading || !stripe || !elements} onPress={onSubmit} style={tw`mt-5`}>
				{t("word.confirm")}
			</BigButton>
		</form>
	);
};

const stripePromise = loadStripe(settings.stripe.pk);

const CheckoutModule = ({ setSelectedProduct, restaurant: _restaurant, menu: _menu }) => {
	const platform = useContext(PlatformContext);
	const { user, lang } = useContext(UserContext);

	const [modal, setModal] = useState(false);
	const [modalPaymentType, setModalPaymentType] = useState(false);

	const [status, setStatus] = useState(1);
	const { goBack, navigate } = useNavigation();
	const {
		params: { restaurant: params_restaurant, menu: params_menu },
	} = useRoute();
	const restaurant = _restaurant || params_restaurant;
	const menu = _menu || params_menu;
	const bags = useContext(BagContext);
	const items = bags.get(restaurant.id);
	const meta = bags.getMeta(restaurant.id, restaurant.time_before_order);
	const { t } = useTranslation();
	const [checkout, setCheckout] = useState(null);
	const [error, setError] = useState();

	const onOrder = async () => {
		if (!user?.id) {
			track("CART_CHECKOUT_WITHOUT_ACCOUNT");
			return navigate("quick-auth");
		}

		setError(null);
		setStatus(2);

		createOrder({
			restaurant_id: restaurant.id,
			items,
			isWebApp: true,
			...meta,
		})
			.then(({ error, data }) => {
				if (data?.order_id && data?.isAfterPay) {
					track("CART_CHECKOUT_START", { restaurant_id: restaurant.id, order_id: data.order_id });
					track("CART_CHECKOUT_SUCCESS", {
						is_after_pay: true,
						restaurant_id: restaurant.id,
						order_id: data.order_id,
					});
					bags.clearBag(restaurant.id);
					window.location.href = `${window.location.origin}/order?order_id=${data.order_id}`;
				} else if (data) {
					track("CART_CHECKOUT_START", { restaurant_id: restaurant.id, order_id: data.order_id });
					setCheckout(data);
				} else {
					track("CART_CHECKOUT_DENIED", { restaurant_id: restaurant.id, error });
					setError(error);
					setStatus(1);
				}
			})
			.catch(({ response }) => {
				track("CART_CHECKOUT_ERROR", {
					restaurant_id: restaurant.id,
					error: response?.data?.error,
				});
				setError(response?.data?.error);
				setStatus(1);
			});
	};

	if (!restaurant?.id) return <Redirect />;

	return (
		<>
			<Screen backgroundColor="white" style={tw`p-0`}>
				<SimpleHeader
					title={t("checkout.bag")}
					left={!isDesktop ? { onPress: () => webBack(goBack) } : null}
				/>
				{restaurant.avails_first_in && isDesktop ? (
					<View style={tw`w-full border-t h-2 border-t border-[${platform.colors.yellow_main}]`} />
				) : null}
				{!restaurant.avails_first_in ? (
					<ClosedRestaurant />
				) : (
					<>
						{bags.get(restaurant.id)?.length > 0 && (
							<>
								<CheckoutCard
									meta={meta}
									restaurant={restaurant}
									onEdit={() => setModal(true)}
									isWarning={!meta.pickup_at}
								/>
								{meta.delivery && (
									<AddressBadge
										onPress={() => setModal(true)}
										type={"gray-outline"}
										style={tw`mx-3`}
										address={meta.delivery}
									/>
								)}
								{restaurant?.accept_cash && user?.allow_cash ? (
									<BigButton
										onPress={() => setModalPaymentType(true)}
										style={tw`justify-between rounded-xl mx-3 py-2 px-3`}
										type="gray-outline"
										rawInner={
											<View>
												<Text style={styles.header_me}>
													{t("checkout.title")}: {t(`checkout.payment_type_${meta.payment_type}`)}
												</Text>
												<CheckoutPaymentTypeIcons type={meta.payment_type} />
											</View>
										}
									/>
								) : null}
							</>
						)}
						{!items?.length ? (
							<View style={tw`flex-col items-center justify-center flex-1`}>
								<Text style={styles.header_2xl}>{t("checkout.empty")}</Text>
								<Text style={styles.header_lg}>
									{t(`checkout.empty_desc.${platform.vertical}`)}
								</Text>
								{!platform.isPizzy && (
									<ImageBackground
										resizeMode="contain"
										source={require("../../../assets/toe.png")}
										style={{
											height: 150,
											width: 155,
											marginTop: 50,
										}}
									/>
								)}
							</View>
						) : (
							<View style={tw`bg-white mt-5 px-4 pb-5`}>
								<Mapper
									data={items}
									loading={!menu}
									render={(item, i) => {
										const product = menu.find((x) => x.id === item.id);
										if (!product) return null;

										return (
											<CheckoutProductCard
												key={item.uuid}
												item={{ ...item, product }}
												disabled={status !== 1}
												onPress={() =>
													setSelectedProduct
														? setSelectedProduct([product, item])
														: navigate("restaurant-product", {
																restaurant,
																menu,
																product,
																selected: item,
														  })
												}
											>
												<TouchableOpacity
													style={tw`pl-5`}
													onPress={() => bags.upd(restaurant.id, product, "del", item.uuid)}
												>
													<FontAwesome5 name="trash-alt" size={20} />
												</TouchableOpacity>
											</CheckoutProductCard>
										);
									}}
								/>
							</View>
						)}
					</>
				)}
			</Screen>
			{bags.get(restaurant.id)?.length > 0 && restaurant.avails_first_in ? (
				<CheckoutFooter
					error={error}
					menu={menu}
					bags={bags}
					meta={meta}
					onOrder={onOrder}
					restaurant={restaurant}
					status={status}
					onEdit={() => setModal(true)}
				/>
			) : null}
			{modal && (
				<CheckoutModal
					bags={bags}
					meta={meta}
					restaurant={restaurant}
					onClose={() => setModal(false)}
				/>
			)}

			{modalPaymentType && (
				<CheckoutPaymentTypeModal
					bags={bags}
					meta={meta}
					restaurant={restaurant}
					onClose={() => setModalPaymentType(false)}
				/>
			)}

			{checkout && (
				<HalfModal visible={checkout}>
					<SimpleHeader
						hideLeft
						style={tw`mt-0 p-0 mb-5 h-auto`}
						title={t("checkout.title")}
						right={{
							onPress: () => [
								track("CART_CHECKOUT_CANCELED", {
									restaurant_id: restaurant.id,
									order_id: checkout.id,
								}),
								setCheckout(null),
								setStatus(1),
							],
							source: "close",
						}}
					/>
					<Elements
						stripe={stripePromise}
						options={{
							locale: lang,
							clientSecret: checkout.payment.paymentIntent,
							customerOptions: {
								ephemeralKey: checkout.payment.ephemeralKey,
								customer: checkout.payment.customer,
							},
						}}
					>
						<CheckoutForm restaurant={restaurant} checkout={checkout} />
					</Elements>
				</HalfModal>
			)}
		</>
	);
};

export default CheckoutModule;
