import { Text } from "react-native";
import tw from "twrnc";

const Heading = ({ children, style, numberOfLines }) => {
	return (
		<Text numberOfLines={numberOfLines} style={{ ...tw`text-xl font-medium mb-5`, ...style }}>
			{children}
		</Text>
	);
};

export default Heading;
