import { useIsFocused, useNavigation, useRoute } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StatusBar, Text, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";
import Loading from "../../../components/Loading";
import RestaurantHeader from "../../../components/RestaurantHeader";
import { PlatformContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import { getRestaurant, getStripeConnect, updateRestaurant, uploadImage } from "../../../utils/db";
import { pickImageAsync } from "../../../utils/media";
import { styles } from "../../../utils/settings";
import { EditButton } from "../ProductEditModule/AdminProductEditModule";

const OverviewModule = () => {
	const platform = useContext(PlatformContext);
	const isFocused = useIsFocused();
	const { t } = useTranslation();
	const { navigate } = useNavigation();
	const {
		params: { restaurant },
	} = useRoute();
	const { data, loading, load } = useApi(getRestaurant, { id: restaurant, edit: true });

	const stripe = async () => {
		const { data: result } = await getStripeConnect({ restaurant });
		if (result.url) WebBrowser.openBrowserAsync(result.url);
	};

	useEffect(() => {
		if (isFocused) load(true);
	}, [isFocused]);

	if (loading || !data) return <Loading />;

	return (
		<ScrollView style={tw`flex-1 bg-[${platform.colors.background}]`}>
			{isFocused && <StatusBar barStyle={"light-content"} />}
			<RestaurantHeader
				restaurant={data}
				imageChildren={
					<View style={tw`absolute bottom-5 right-5`}>
						<EditButton
							icon="camera"
							e={async () => {
								const img = await pickImageAsync();
								if (!img) return;
								const { data: result } = await uploadImage(img);
								const image_cover = result;

								await updateRestaurant(restaurant, { image_cover });

								load();
							}}
						/>
					</View>
				}
			/>
			<View style={tw`p-3`}>
				<Text style={{ ...styles.header_xl, ...tw`mb-3` }}>
					{t(`admin.my_restaurant.${platform.vertical}`)}
				</Text>
				<BigButton
					icon="info-circle"
					iconColor={platform.isPizzy ? "white" : "black"}
					onPress={() => navigate("admin-info", { restaurant })}
					style={tw`mb-3`}
				>
					{t("admin.general_information")}
				</BigButton>
				<BigButton
					icon="building"
					iconColor={platform.isPizzy ? "white" : "black"}
					onPress={() => navigate("admin-invoicing", { restaurant })}
					style={tw`mb-3`}
				>
					{t("admin.invoicing_information")}
				</BigButton>
				{!platform.isPizzy && (
					<BigButton
						icon="star"
						iconColor={platform.isPizzy ? "white" : "black"}
						onPress={() => navigate("admin-challenges", { restaurant })}
						style={tw`mb-3`}
					>
						{t("admin.challenges")}
					</BigButton>
				)}
				<BigButton
					icon="cog"
					iconColor={platform.isPizzy ? "white" : "black"}
					onPress={() => navigate("admin-status", { restaurant })}
					style={tw`mb-3`}
				>
					{t(`admin.status_restaurant.${platform.vertical}`)}
				</BigButton>
				<BigButton
					icon="calendar"
					iconColor={platform.isPizzy ? "white" : "black"}
					onPress={() => navigate("admin-opening-hours", { restaurant })}
					style={tw`mb-3`}
				>
					{t("admin.opening_hours")}
				</BigButton>
				<BigButton
					icon="euro-sign"
					iconColor={platform.isPizzy ? "white" : "black"}
					onPress={stripe}
					style={tw`mb-3`}
				>
					{t("admin.payments")}
				</BigButton>
				<Text style={{ ...styles.header_xl, ...tw`mb-3 mt-3` }}>{t("admin.my_menu")}</Text>
				<BigButton
					icon="ad"
					iconColor={platform.isPizzy ? "white" : "black"}
					onPress={() => navigate("admin-categories", { restaurant })}
					style={tw`mb-3`}
				>
					{t("admin.manage_categories")}
				</BigButton>
				<BigButton
					icon="shopping-cart"
					iconColor={platform.isPizzy ? "white" : "black"}
					onPress={() => navigate("admin-products", { restaurant })}
					style={tw`mb-3`}
				>
					{t("admin.manage_products")}
				</BigButton>
				<BigButton
					icon="check"
					iconColor={platform.isPizzy ? "white" : "black"}
					onPress={() => navigate("admin-options", { restaurant })}
					style={tw`mb-3`}
				>
					{t("admin.manage_options")}
				</BigButton>
				<Text style={{ ...styles.header_xl, ...tw`mb-3 mt-3` }}>{t("admin.my_data")}</Text>
				<BigButton
					icon="print"
					iconColor={platform.isPizzy ? "white" : "black"}
					onPress={() => navigate("admin-print-total", { restaurant })}
					style={tw`mb-3`}
				>
					{t("admin.print_total_ticket")}
				</BigButton>
				<BigButton
					icon="file-invoice"
					iconColor={platform.isPizzy ? "white" : "black"}
					onPress={() => navigate("admin-invoices", { restaurant })}
					style={tw`mb-3`}
				>
					{t("admin.invoices")}
				</BigButton>
			</View>
		</ScrollView>
	);
};

export default OverviewModule;
