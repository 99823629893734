import { track } from "@amplitude/analytics-react-native";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { parseDate } from "../utils/days";
import { getProductPriceWithExtras } from "../utils/products";
import { readSafe, writeSafe } from "../utils/safe";
import settings from "../utils/settings";

const useBag = () => {
	const [loaded, setLoaded] = useState(false);
	const [bags, setBags] = useState([]);

	useEffect(() => {
		if (!loaded) {
			readSafe("bags", []).then((x) => [setBags(x), setLoaded(true)]);
		}
	}, []);

	const saveBags = async (newBags) => {
		setBags(newBags);
		await writeSafe("bags", newBags);
		return newBags;
	};

	const upd = (restaurant, product, action, uuid, qty = 1, extras, comment) => {
		let copy = get(restaurant);

		if (action === "+") {
			track("CART_ADD_PRODUCT", { restaurant_id: restaurant });

			if (uuid) {
				const item = copy.findIndex((x) => x.uuid === uuid);
				if (qty <= 0) copy.splice(item, 1);
				else copy[item] = { ...copy[item], qty, extras, id: product, comment };
			} else {
				if (qty > 0) {
					// look for existing item with exact same config !
					const ind = copy.findIndex(
						(x) =>
							x.id === product &&
							!x.comment &&
							!comment &&
							(x.extras === extras || JSON.stringify(x.extras) === JSON.stringify(extras))
					);
					if (ind > -1) copy[ind].qty += qty;
					else
						copy.push({ id: product, uuid: new Date().getTime().toString(), qty, extras, comment });
				}
			}

			return updateBag(restaurant, copy);
		}

		track("CART_REMOVE_PRODUCT", { restaurant_id: restaurant });

		const i = copy.findIndex((x) => x.uuid === uuid);
		if (!uuid || i === -1) return;

		if (action === "-") {
			copy[i].qty -= 1;
			if (copy[i].qty === 0) return upd(restaurant, product, "del");
		} else if (action === "del") {
			copy.splice(i, 1);
		}

		updateBag(restaurant, copy);
	};

	const updateBag = (restaurant, items) => {
		let copy = [...bags];
		const i = copy.findIndex((x) => x.id === restaurant);

		if (i === -1) copy.push({ id: restaurant, items });
		else copy[i].items = items;

		return saveBags(copy);
	};

	const setMeta = (restaurant, prop, value) => {
		let copy = [...bags];
		const i = copy.findIndex((x) => x.id === restaurant);

		if (i === -1) copy.push({ id: restaurant, meta: { [prop]: value } });
		else copy[i].meta = { ...copy[i].meta, [prop]: value };

		return saveBags(copy);
	};

	const setMetas = (restaurant, metas) => {
		let copy = [...bags];
		const i = copy.findIndex((x) => x.id === restaurant);

		if (i === -1) copy.push({ id: restaurant, meta: metas });
		else copy[i].meta = { ...copy[i].meta, ...metas };

		return saveBags(copy);
	};

	const clearBag = (restaurant) => {
		const i = bags.findIndex((x) => x.id === restaurant);
		if (i === -1) return false;

		let copy = [...bags];
		copy.splice(i, 1);

		return saveBags(copy);
	};

	const getQty = (restaurant, product) => {
		const items = get(restaurant);
		if (!items) return 0;

		return items.filter((x) => x.id === product).reduce((sum, { qty }) => sum + qty, 0) || 0;
	};

	const totalize = (restaurant, menu) => {
		const items = get(restaurant);
		if (!menu || !items) return 0;

		let total = 0;
		items.forEach((it) => {
			let mIt = menu.find((x) => x.id === it.id);
			if (!mIt) return console.log("Missing product in menu!!!", it.id);

			total += getProductPriceWithExtras(mIt, it.qty, it.extras);
		});

		return Math.round(total * 100) / 100;
	};

	const get = (restaurant) => {
		const bag = bags.find((x) => x.id === restaurant);
		if (bag) return bag.items || [];

		return [];
	};

	const getMeta = (restaurant, time_before_order) => {
		const bag = bags.find((x) => x.id === restaurant);

		if (bag?.meta?.pickup_at) {
			const d = parseDate(bag.meta.pickup_at);
			if (
				d.setZone("Europe/Brussels") <
				DateTime.now().plus({ minutes: time_before_order || settings.time_before_order })
			)
				setMeta(restaurant, "pickup_at", null);
		}

		return bag?.meta || { payment_type: 2 };
	};

	return { get, getQty, setMeta, setMetas, getMeta, totalize, upd, clearBag };
};

export default useBag;
