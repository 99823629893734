import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, Text, TextInput, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";
import Card from "../../../components/Card";
import Checkbox from "../../../components/Checkbox";
import HalfModal from "../../../components/HalfModal";
import Loading from "../../../components/Loading";
import SimpleHeader from "../../../components/SimpleHeader";
import Tabs from "../../../components/Tabs";
import { PlatformContext, UserContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import { getRestaurantCategories, upsertRestaurantCategory } from "../../../utils/db";
import { webBack } from "../../../utils/others";
import { isIos, isWeb } from "../../../utils/platform";
import { styles } from "../../../utils/settings";

export const languages = [
	{ lang: "en", flag: "🇬🇧", long: "English" },
	{ lang: "fr", flag: "🇫🇷", long: "Français" },
	{ lang: "nl", flag: "🇳🇱", long: "Nederlands" },
];

const CategoryList = ({ loading, saving, onMove, setCategory, data }) => {
	const { lang: userLang } = useContext(UserContext);

	if (loading) return <Loading />;
	if (!data) return null;

	return (
		<ScrollView contentContainerStyle={tw`p-3`}>
			{data?.map((cat, i) => (
				<Card
					key={`category_${cat.id}`}
					style={tw`p-3 mb-3 flex-row items-center justify-between`}
					onPress={() => setCategory(cat)}
				>
					<View>
						{languages
							.sort((a, b) => a.lang !== userLang)
							.map((l, i) => (
								<Text
									key={`cat_${l.lang}`}
									style={i === 0 ? styles.header_xl : styles.header_light}
								>
									{l.flag} {cat.name?.[l.lang]}
								</Text>
							))}
					</View>
					<View>
						<BigButton
							icon="arrow-up"
							type="yellow-outline"
							style={tw`p-3 mb-1`}
							disabled={saving || i === 0}
							onPress={() => onMove(cat, parseInt(data[i - 1].sort || cat.sort || 0) - 1)}
						/>
						<BigButton
							icon="arrow-down"
							type="yellow-outline"
							style={tw`p-3 mt-1`}
							disabled={saving || i === data.length - 1}
							onPress={() => onMove(cat, parseInt(data[i + 1].sort || cat.sort || 0) + 1)}
						/>
					</View>
				</Card>
			))}
		</ScrollView>
	);
};

export const InputContainer = ({ style, children }) => {
	const platform = useContext(PlatformContext);

	return (
		<View
			style={{
				...tw`flex-row w-full items-center justify-between mb-3 px-3 border rounded-xl`,
				borderColor: platform.colors.yellow_main,
				...style,
			}}
		>
			{children}
		</View>
	);
};

export const InputBase = ({ style, ...props }) => (
	<TextInput
		ref={props.refInner}
		autoCorrect={false}
		placeholderTextColor="#7a7a7a"
		style={{
			...tw`flex-1 text-base pl-1 font-semibold h-full ${
				props.multiline ? "mb-1" : isIos ? "mb-2.5" : ""
			} text-black`,
			...(isWeb && { outline: "none" }),
			...style,
		}}
		{...props}
	/>
);

export const LangField = ({ multiline, onChange, value, field }) => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();
	const { lang: userLang } = useContext(UserContext);
	const [showAll, setShowAll] = useState(false);

	const handleChange = (t, l) => {
		const oldValue = value?.[field]?.[l.lang];
		const newValue = t;
		let curr = {
			...value?.[field],
			[l.lang]: t,
		};

		if (l.lang === userLang)
			["fr", "nl", "en"]
				.filter((x) => x !== l.lang)
				.forEach((lang) => {
					if (!curr[lang] || curr[lang] === oldValue) curr[lang] = newValue;
				});

		onChange({ ...value, [field]: curr });
	};

	const sortLanguages = languages.reduce((acc, element) => {
		if (element.lang === userLang) {
			return [element, ...acc];
		}
		return [...acc, element];
	}, []);

	return (
		<View>
			{sortLanguages
				.filter((x, i) => (showAll ? true : x.lang === userLang))
				.map((l) => (
					<InputContainer key={`edit_lang_${l.lang}`}>
						<Text style={{ ...tw`text-xl font-semibold mr-3`, color: platform.colors.yellow_main }}>
							{l.flag}
						</Text>
						<InputBase
							multiline={multiline}
							onChangeText={(t) => handleChange(t, l)}
							value={value?.[field]?.[l.lang]}
						/>
					</InputContainer>
				))}
			{!showAll && (
				<BigButton style={tw`py-1 mb-3`} onPress={() => setShowAll(true)}>
					{t("word.show_all_langs")}
				</BigButton>
			)}
		</View>
	);
};

const Modal = ({ category, saving, onSubmit, cancel }) => {
	const [form, setForm] = useState(category);
	const { t } = useTranslation();

	useEffect(() => {
		setForm(category);
	}, [category]);

	if (!category) return null;

	const { en, nl, fr } = form?.name || {};

	return (
		<HalfModal visible>
			<SimpleHeader
				hideLeft
				style={tw`mt-0 p-0 mb-5 h-auto`}
				title={t("admin.modify_category")}
				right={{ onPress: cancel, source: "close" }}
			/>
			<TouchableOpacity
				style={tw`flex-row items-center mb-3`}
				onPress={(x) => setForm({ ...form, active: !form?.active })}
			>
				<Checkbox checked={form?.active} />
				<Text style={tw`flex-1 text-base ml-5 py-1.5 font-semibold text-black`}>
					{t("admin.show_category")}
				</Text>
			</TouchableOpacity>
			<TouchableOpacity
				style={tw`flex-row items-center mb-3`}
				onPress={(x) => setForm({ ...form, shorten: !form?.shorten })}
			>
				<Checkbox checked={form?.shorten} />
				<Text style={tw`flex-1 text-base ml-5 py-1.5 font-semibold text-black`}>
					{t("admin.category_shorten")}
				</Text>
			</TouchableOpacity>
			<LangField onChange={setForm} value={form} field="name" />
			<BigButton disabled={saving || !en || !fr || !nl} onPress={() => onSubmit(form)}>
				{t("word.save")}
			</BigButton>
		</HalfModal>
	);
};

const AdminCategoriesModule = () => {
	const {
		params: { restaurant },
	} = useRoute();
	const { goBack } = useNavigation();
	const {
		data: categories,
		load,
		loading,
	} = useApi(getRestaurantCategories, {
		restaurant: restaurant,
	});
	const [category, setCategory] = useState(null);
	const [saving, setSaving] = useState(false);
	const { t } = useTranslation();

	const onSubmit = async (form) => {
		setSaving(true);

		if (!form.id) form.sort = new Date().getTime();

		await upsertRestaurantCategory(restaurant, form);
		await load();

		setCategory(null);
		setSaving(false);
	};

	const onMove = async (category, newSort) => {
		setSaving(true);

		category.sort = newSort || 0;

		await upsertRestaurantCategory(restaurant, category);
		await load();

		setSaving(false);
	};

	return (
		<View style={{ flex: 1, ...tw`bg-white` }}>
			<SimpleHeader
				style={tw``}
				title={t("admin.categories")}
				left={{ onPress: () => webBack(goBack) }}
				right={{ onPress: () => setCategory({ active: true }), source: "plus" }}
			/>
			<Tabs
				tabs={[
					{
						key: "active",
						title: t("word.active"),
						component: () => (
							<CategoryList
								loading={loading}
								saving={saving}
								onMove={onMove}
								setCategory={setCategory}
								data={categories?.filter((x) => x.active) || []}
							/>
						),
					},
					{
						key: "inactive",
						title: t("word.inactive"),
						component: () => (
							<CategoryList
								loading={loading}
								saving={saving}
								onMove={onMove}
								setCategory={setCategory}
								data={categories?.filter((x) => !x.active) || []}
							/>
						),
					},
				]}
			/>
			<Modal
				category={category}
				saving={saving}
				cancel={() => setCategory(null)}
				onSubmit={onSubmit}
			/>
		</View>
	);
};

export default AdminCategoriesModule;
