import AntDesign from "@expo/vector-icons/AntDesign";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TextInput, View } from "react-native";
import tw from "twrnc";
import { PlatformContext } from "../../contexts";
import { isWeb } from "../../utils/platform";

const SimpleSearch = ({
	search,
	setSearch,
	placeholder = "search.find_product",
	size = 53,
	style,
}) => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();

	return (
		<View
			style={{
				...tw`flex-1 bg-white rounded-xl h-10 flex-row items-center px-4 mb-4`,
				...style,
			}}
		>
			<AntDesign name="search1" size={20} />
			<TextInput
				style={{ ...tw`ml-2 mr-0 flex-grow py-3`, ...(isWeb && { outline: "none" }) }}
				autoCorrect={false}
				clearButtonMode="while-editing"
				value={search || ""}
				onChangeText={setSearch}
				placeholder={t(placeholder)}
				placeholderTextColor={platform.colors.gray_light}
			/>
		</View>
	);
};

export default SimpleSearch;
