import { useContext, useMemo, useState } from "react";
import { useWindowDimensions } from "react-native";
import { SceneMap, TabBar, TabView } from "react-native-tab-view";
import { PlatformContext } from "../../contexts";

const Tabs = ({ tabs, defaultTab = 0, onChange }) => {
	const platform = useContext(PlatformContext);
	const layout = useWindowDimensions();

	const [index, setIndex] = useState(defaultTab);
	const [routes] = useState(tabs);

	const renderScene = useMemo(() => {
		const scene = {};

		tabs.forEach((t) => {
			scene[t.key] = t.component;
		});

		return SceneMap(scene);
	}, [tabs]);

	const onChangeTab = (ind) => {
		if (ind === index) return;
		if (onChange) onChange(ind);
		setIndex(ind);
	};

	return (
		<TabView
			navigationState={{ index, routes }}
			renderScene={renderScene}
			onIndexChange={onChangeTab}
			initialLayout={{ width: layout.width }}
			style={{ backgroundColor: platform.colors.background }}
			renderTabBar={(props) => (
				<TabBar
					{...props}
					indicatorContainerStyle={{ backgroundColor: "white" }}
					labelStyle={{ fontWeight: "600", color: platform.colors.yellow_main }}
					activeColor={platform.colors.yellow_main}
					inactiveColor="gray"
					indicatorStyle={{ backgroundColor: platform.colors.yellow_main }}
				/>
			)}
		/>
	);
};

export default Tabs;
