import { DateTime } from "luxon";

export const getIntTimeFromDate = (dt, add = 0) => {
	if (!dt) return null;

	const hour = dt.hour;
	const minute = Math.round((dt.minute / 60) * 100) / 100 + add;

	return hour + minute;
};

export const getTodayLeftHours = (days, time_before_order) => {
	const today = DateTime.now().weekday;
	const todayHours = days?.find((x) => x.id === today);
	const hours = todayHours?.hours?.filter(
		(x) => x.until >= getIntTimeFromDate(DateTime.now().plus({ minutes: time_before_order }))
	);

	return hours;
};

export const hoursToArray = (hours, time_before_order) => {
	let times = [];

	hours?.forEach((h) => {
		for (let i = h.from; i <= h.until; i += 0.25) {
			if (i > getIntTimeFromDate(DateTime.now().plus({ minutes: time_before_order })))
				times.push(i);
		}
	});

	return times;
};

export const parseDate = (dt) => {
	if (!dt) return null;

	if (typeof dt === "string") return DateTime.fromISO(dt);

	return DateTime.fromJSDate(dt);
};

export const googleHoursToOurs = (hours) => {
	if (!hours) return null;

	let days = [];

	const formatTime = (t) => {
		const hour = parseInt(t.substr(0, 2));
		const minutes = parseInt(t.substr(2, 4)) / 60;

		return parseFloat(hour + minutes);
	};

	hours.forEach((h) => {
		let day = days?.find((x) => x.id === h.open.day);
		if (!day) {
			days.push({ id: h.open.day, hours: [] });
			day = days?.find((x) => x.id === h.open.day);
		}

		day.hours.push({ from: formatTime(h.open.time), until: formatTime(h.close.time) });
	});

	return days;
};

export const getNextOpenHour = (days) => {
	if (!days) return null;
	const today = DateTime.now().weekday;
	let nextShift = null;

	for (let i = 1; i < 7; i++) {
		let checkDay = today + i;
		if (checkDay > 7) checkDay -= 7;

		const day = days?.find((x) => x.id === checkDay);
		if (!day?.hours?.length) continue;

		let firstOpenHour = 24;
		day.hours.forEach((h) => {
			if (h.from < firstOpenHour) {
				firstOpenHour = h.from;
			}

			if (DateTime.now().hour <= h.until && !nextShift) {
				nextShift = h;
			}
		});

		return { day: day.id, hour: firstOpenHour, nextShift };
	}

	return null;
};
