import { useNavigation, useRoute } from "@react-navigation/native";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";
import Card from "../../../components/Card";
import Loading from "../../../components/Loading";
import SimpleHeader from "../../../components/SimpleHeader";
import Tabs from "../../../components/Tabs";
import useApi from "../../../hooks/useApi";
import useFocusLoad from "../../../hooks/useFocusLoad";
import { getRestaurantOptions, upsertRestaurantOption } from "../../../utils/db";
import { webBack } from "../../../utils/others";
import { OptionCard } from "../ProductEditModule/AdminProductEditModule";

const OptionsList = ({ loading, saving, onMove, setOption, data }) => {
	if (loading) return <Loading />;
	if (!data) return null;

	return (
		<ScrollView contentContainerStyle={tw`p-3`}>
			{data?.map((opt, i) => (
				<Card
					key={`option_${opt.id}`}
					style={tw`p-3 mb-3 flex-col justify-center items-center w-full`}
				>
					<OptionCard onPress={() => setOption(opt)} style={tw`mb-0`} o={opt}>
						<View>
							<BigButton
								icon="arrow-up"
								type="yellow-outline"
								style={tw`p-3 mb-1`}
								disabled={saving || i === 0}
								onPress={() => onMove(opt, parseInt(data[i - 1].sort || opt.sort || 0) - 1)}
							/>
							<BigButton
								icon="arrow-down"
								type="yellow-outline"
								style={tw`p-3 mt-1`}
								disabled={saving || i === data.length - 1}
								onPress={() => onMove(opt, parseInt(data[i + 1].sort || opt.sort || 0) + 1)}
							/>
						</View>
					</OptionCard>
				</Card>
			))}
		</ScrollView>
	);
};

const AdminOptionsModule = () => {
	const {
		params: { restaurant },
	} = useRoute();
	const { goBack, navigate } = useNavigation();
	const isFocused = useFocusLoad();
	const {
		data: options,
		load,
		loading,
	} = useApi(
		getRestaurantOptions,
		{
			restaurant,
		},
		[isFocused]
	);
	const [saving, setSaving] = useState(false);
	const { t } = useTranslation();

	const onMove = async (option, newSort) => {
		setSaving(true);

		option.sort = newSort || 0;

		await upsertRestaurantOption(restaurant, option);
		await load();

		setSaving(false);
	};

	return (
		<View style={{ flex: 1, ...tw`bg-white` }}>
			<SimpleHeader
				style={tw``}
				title={t("word.options")}
				left={{ onPress: () => webBack(goBack) }}
				right={{
					onPress: () =>
						navigate("admin-option-edit", { restaurant, option: { active: true, type: 1 } }),
					source: "plus",
				}}
			/>
			<Tabs
				tabs={[
					{
						key: "active",
						title: t("word.active"),
						component: () => (
							<OptionsList
								loading={loading}
								saving={saving}
								onMove={onMove}
								setOption={(o) => navigate("admin-option-edit", { restaurant, option: o })}
								data={options?.filter((x) => x.active) || []}
							/>
						),
					},
					{
						key: "inactive",
						title: t("word.inactive"),
						component: () => (
							<OptionsList
								loading={loading}
								saving={saving}
								onMove={onMove}
								setOption={(o) => navigate("admin-option-edit", { restaurant, option: o })}
								data={options?.filter((x) => !x.active) || []}
							/>
						),
					},
				]}
			/>
		</View>
	);
};

export default AdminOptionsModule;
