import axios from "axios";
import { isIos, isWeb } from "./platform";
import { readSafe } from "./safe";
import settings, { VERTICALS } from "./settings";

const METHODS = {
	POST: "post",
	GET: "get",
	PUT: "put",
};

const URL = false ? "http://localhost:3000" : "https://api.fritzy.be";

const reqApi = async (method, path, { body: data, query: params } = {}) => {
	const [vertical, location, lang] = await Promise.all([
		readSafe("vertical", VERTICALS.fritzy),
		readSafe("location", settings.default_location),
		readSafe("lang", null),
	]);
	let headers = {
		"user-language": lang,
		"user-vertical": vertical,
		"user-position": location.join("#"),
		"user-device": isWeb ? "web" : isIos ? "ios" : "android",
	};

	const token = await readSafe("token");
	if (token) headers.Authorization = `Bearer ${token}`;

	const { data: r } = await axios({
		method,
		url: `${URL}/${path}`,
		data,
		params,
		headers,
	});

	return { success: r.success, data: r.data, error: r.error };
};

export const getStripeConnect = ({ restaurant }) =>
	reqApi(METHODS.POST, `stripe-connect/${restaurant}`);

export const getTemplateImages = () => reqApi(METHODS.GET, `templates/images`);

export const deleteMyUser = () => reqApi(METHODS.POST, "me/delete-account");

export const uploadImage = (body) => reqApi(METHODS.POST, "upload", { body });

export const authUser = (body) => reqApi(METHODS.POST, "auth", { body });
export const authUserV2 = (body) => reqApi(METHODS.POST, "auth/v2", { body });

export const updateUserLanguage = (language) =>
	reqApi(METHODS.POST, "me/language", { body: { language } });

export const signIn = (body) => reqApi(METHODS.POST, "signin", { body });

export const getPlatform = () => reqApi(METHODS.GET, "platform");

export const getUser = () => reqApi(METHODS.GET, "me");

export const updateMyUser = (body) => reqApi(METHODS.POST, "me", { body });

export const getRestaurants = (query) => reqApi(METHODS.GET, "restaurants", { query });

export const getRestaurant = ({ id, edit }) =>
	reqApi(METHODS.GET, `restaurants/${id}`, { query: { edit } });

export const getRestaurantInvoices = ({ id }) =>
	reqApi(METHODS.GET, `finance/restaurant-invoices`, { query: { id } });

export const getRestaurantOrders = (query) => reqApi(METHODS.GET, `admin/orders/v2`, { query });

export const getRestaurantCategories = ({ restaurant }) =>
	reqApi(METHODS.GET, `categories/${restaurant}`);

export const getRestaurantOptions = ({ restaurant }) =>
	reqApi(METHODS.GET, `options/${restaurant}`);

export const upsertRestaurantCategory = (restaurant, body) =>
	reqApi(METHODS.POST, `categories/${restaurant}`, { body });

export const upsertRestaurantOption = (restaurant, body) =>
	reqApi(METHODS.POST, `options/${restaurant}`, { body });

export const upsertRestaurantProduct = (restaurant, body) =>
	reqApi(METHODS.POST, `menu/${restaurant}`, { body });

export const getUserOrders = (query) => reqApi(METHODS.GET, `orders/v2`, { query });

export const getUserOrder = (id) => reqApi(METHODS.GET, `orders/v2/${id}`);

export const updateOrder = (id, body) => reqApi(METHODS.POST, `orders/${id}`, { body });

export const createOrder = (body) => reqApi(METHODS.POST, `orders`, { body });

export const updateUserNotificationToken = (token) =>
	reqApi(METHODS.POST, `me/notification-token`, { body: { token } });

export const getRestaurantMenu = ({ restaurant, filters }) =>
	reqApi(METHODS.GET, `menu/${restaurant}`, {
		query: filters,
	});

export const createRestaurant = (body) => reqApi(METHODS.POST, `restaurants`, { body });

export const updateRestaurant = (id, body) =>
	reqApi(METHODS.PUT, `restaurants`, { body: { id, ...body } });

export const getRestaurantOrdersReport = (query) =>
	reqApi(METHODS.GET, `reports/restaurant-orders`, { query });

export const getRestaurantMonthlyReport = (query) =>
	reqApi(METHODS.GET, `reports/restaurant-monthly`, { query });

export const setFavRestaurant = (restaurant, isFavorite) =>
	reqApi(METHODS.POST, `fav-restaurants`, {
		body: { restaurant, isFavorite },
	});

export const getFile = (uuid, width = 400, quality = 20) =>
	!uuid
		? null
		: `https://wmdadhuhhzahjdtmjqne.supabase.co/storage/v1/render/image/public/library/${uuid}?quality=${quality}&width=${width}&resize=contain`;

export default {
	URL,
	signIn,
	getUser,
	getRestaurants,
	getRestaurantMenu,
	getRestaurantOrders,
	getUserOrders,
};
