import { Pressable, Text } from "react-native";
import tw from "twrnc";

const Button = ({ onPress, children, style }) => {
	return (
		<Pressable
			style={{
				...tw`px-3 py-1 bg-blue-400 text-white font-bold max-w-50 text-center rounded-xl mt-2`,
				...style,
			}}
			onPress={onPress}
		>
			<Text style={tw`text-center`}>{children}</Text>
		</Pressable>
	);
};

export default Button;
