import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";
import Checkbox from "../../../components/Checkbox";
import ProductList from "../../../components/ProductList";
import SimpleHeader from "../../../components/SimpleHeader";
import SimpleSearch from "../../../components/SimpleSearch";
import Tabs from "../../../components/Tabs";
import { PlatformContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import useFocusLoad from "../../../hooks/useFocusLoad";
import {
	getRestaurantMenu,
	getRestaurantOptions,
	uploadImage,
	upsertRestaurantProduct,
} from "../../../utils/db";
import { pickImageAsync } from "../../../utils/media";
import { webBack } from "../../../utils/others";
import { EditFieldModal, ImageLibrary } from "../ProductEditModule/AdminProductEditModule";

const Container = ({
	isActive,
	children,
	isMass,
	setIsMass,
	setIsSort,
	isSort,
	setMass,
	onMassAction,
}) => {
	return (
		<View style={tw`flex-1`}>
			<ScrollView contentContainerStyle={tw`p-3`}>
				{isSort ? (
					<BigButton type="dark-outline" onPress={() => [setIsSort(false)]} style={tw`py-2 mb-3`}>
						Annuler
					</BigButton>
				) : isMass ? (
					<>
						<BigButton
							type="dark-outline"
							onPress={() => [setIsMass(false), setMass([])]}
							style={tw`py-2 mb-3`}
						>
							Annuler
						</BigButton>
						<BigButton onPress={() => onMassAction("select_options")} style={tw`py-2 mb-3`}>
							Ajouter une option
						</BigButton>
						<BigButton onPress={() => onMassAction("active")} style={tw`py-2 mb-3`}>
							{isActive ? "Cacher en masse" : "Montrer en masse"}
						</BigButton>
						<BigButton onPress={() => onMassAction("select_price")} style={tw`py-2 mb-3`}>
							Modifier le prix
						</BigButton>
						<BigButton onPress={() => onMassAction("picture")} style={tw`py-2 mb-3`}>
							Modifier la photo
						</BigButton>
						<BigButton onPress={() => onMassAction("select_image_library")} style={tw`py-2 mb-5`}>
							Modifier la photo depuis la galerie
						</BigButton>
					</>
				) : (
					<>
						<BigButton onPress={() => setIsMass(true)} style={tw`py-2 mb-2`}>
							Modifier en masse
						</BigButton>
						<BigButton onPress={() => setIsSort("select")} style={tw`py-2 mb-5`}>
							Modifier l'ordre
						</BigButton>
					</>
				)}
				{children}
			</ScrollView>
		</View>
	);
};

const slowMapper = async (arr, fnc) => {
	for (let i = 0; i < arr.length; i++) {
		await fnc(arr[i]);
	}

	return true;
};

const TabContainer = ({ isActive, restaurant, load, loading, options, menu }) => {
	const [isMass, setIsMass] = useState(false);
	const [isSort, setIsSort] = useState(false);
	const [saving, setSaving] = useState(false);
	const [prop, setProp] = useState([]);
	const [mass, setMass] = useState([]);
	const { navigate } = useNavigation();

	const onMassAction = async (action, value) => {
		if (action === "select_options")
			return setProp({ field: "options", massAction: "options", value: null, type: "options" });
		if (action === "select_price")
			return setProp({ field: "price", massAction: "price", value: {}, type: "price" });
		if (action === "select_image_library") return setProp({ image_library: true });

		setSaving(true);

		if (action === "options") {
			const opts = value.map((o) => ({ id: o, _added: true }));
			await slowMapper(mass, (pId) => {
				try {
					return upsertRestaurantProduct(restaurant, {
						id: pId,
						options: opts,
					});
				} catch (ex) {
					return false;
				}
			});
		}

		if (action === "price") {
			await slowMapper(mass, (pId) => {
				try {
					return upsertRestaurantProduct(restaurant, {
						id: pId,
						price_regular: value?.regular,
						price_discounted: value?.discounted,
					});
				} catch (ex) {
					return false;
				}
			});
		}

		if (action === "active") {
			await slowMapper(mass, (pId) => {
				try {
					return upsertRestaurantProduct(restaurant, {
						id: pId,
						active: !isActive,
					});
				} catch (ex) {
					return false;
				}
			});
		}

		if (action === "picture") {
			const image = await pickImageAsync();
			if (!image) return;
			const { data: result } = await uploadImage(image);
			if (!result) return;

			await slowMapper(mass, (pId) => {
				try {
					return upsertRestaurantProduct(restaurant, { id: pId, image: result });
				} catch (ex) {
					return false;
				}
			});
		}

		if (action === "image_library") {
			await slowMapper(mass, (pId) => {
				try {
					return upsertRestaurantProduct(restaurant, { id: pId, image: value });
				} catch (ex) {
					return false;
				}
			});
		}

		setMass([]);
		load();
		setIsMass(false);
		setSaving(false);
		setProp(null);
	};

	const toggleMass = (pId) => {
		const copy = [...mass];
		const ind = copy.findIndex((x) => x === pId);
		if (ind === -1) copy.push(pId);
		else copy.splice(ind, 1);
		setMass(copy);
	};

	const onMove = async (prod, direction) => {
		setSaving(true);

		let newSort = null;
		if (direction === "down") {
			newSort = parseInt(prod.sort || 0) + 1;
		} else {
			newSort = parseInt(prod.sort || 0) - 1;
		}

		await upsertRestaurantProduct(restaurant, {
			id: isSort.id,
			sort: newSort,
		});

		await load();

		setSaving(false);
	};

	return (
		<Container
			isActive={isActive}
			isMass={isMass}
			setIsMass={setIsMass}
			isSort={isSort}
			setIsSort={setIsSort}
			setMass={setMass}
			onMassAction={onMassAction}
		>
			<ProductList
				loading={loading}
				menu={menu}
				restaurant={restaurant}
				onPress={
					!isMass
						? (product) => navigate("admin-product-edit", { restaurant, menu, product })
						: (p) => toggleMass(p.id)
				}
				renderRight={
					isSort === "select"
						? (p) => (
								<BigButton
									icon="cut"
									type="yellow-outline"
									style={tw`p-3 mb-1`}
									disabled={saving}
									onPress={() => setIsSort(p)}
								/>
						  )
						: isSort
						? (p) => (
								<View>
									<BigButton
										icon="arrow-up"
										type="yellow-outline"
										style={tw`p-3 mb-1`}
										disabled={saving}
										onPress={() => onMove(p, "up")}
									/>
									<BigButton
										icon="arrow-down"
										type="yellow-outline"
										style={tw`p-3 mt-1`}
										disabled={saving}
										onPress={() => onMove(p, "down")}
									/>
								</View>
						  )
						: isMass
						? (p) => <Checkbox checked={mass?.includes(p.id)} onChange={(x) => toggleMass(p.id)} />
						: null
				}
			/>
			{prop?.image_library ? (
				<ImageLibrary
					cancel={() => setProp(null)}
					onSubmit={(image) => onMassAction("image_library", image)}
				/>
			) : prop ? (
				<EditFieldModal
					field={prop?.field}
					saving={saving}
					value={prop?.value}
					type={prop?.type}
					onSubmit={(value) => onMassAction(prop.massAction, value)}
					cancel={() => setProp(null)}
					options={options}
				/>
			) : null}
		</Container>
	);
};

const AdminProductsModule = () => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();
	const [search, setSearch] = useState("");
	const isFocused = useFocusLoad();
	const {
		params: { restaurant },
	} = useRoute();
	const { goBack, navigate } = useNavigation();
	const {
		data: menu,
		load,
		loading,
	} = useApi(
		getRestaurantMenu,
		{
			restaurant: restaurant,
			filters: {
				list: "admin",
			},
		},
		[isFocused]
	);
	const { data: options } = useApi(
		getRestaurantOptions,
		{
			restaurant,
		},
		[isFocused]
	);

	const filterFunc = (x) =>
		!search ||
		search
			.trim()
			.split(" ")
			.map((keyword) => JSON.stringify(x.name).toLowerCase().includes(keyword.toLowerCase()))
			.filter((y) => y).length > 0;

	return (
		<View style={{ flex: 1, ...tw`bg-white` }}>
			<SimpleHeader
				title={t("word.products")}
				left={{ onPress: () => webBack(goBack) }}
				right={{
					onPress: () =>
						navigate("admin-product-edit", {
							restaurant,
							menu,
							product: {
								allow_comment: true,
								active: true,
								price: {},
								category: {},
								name: {},
								description: {},
							},
						}),
					source: "plus",
				}}
			/>
			<View style={tw`mx-2 h-15`}>
				<SimpleSearch
					style={{
						...tw`mb-0 mt-4 border`,
						borderColor: platform.colors.gray_light,
					}}
					search={search}
					setSearch={setSearch}
				/>
			</View>
			<Tabs
				tabs={[
					{
						key: "active",
						title: t("word.active"),
						component: () => (
							<TabContainer
								isActive
								restaurant={restaurant}
								options={options}
								load={load}
								loading={loading}
								menu={menu?.filter((x) => x.active).filter(filterFunc)}
							/>
						),
					},
					{
						key: "inactive",
						title: t("word.inactive"),
						component: () => (
							<TabContainer
								restaurant={restaurant}
								options={options}
								load={load}
								loading={loading}
								menu={menu?.filter((x) => !x.active).filter(filterFunc)}
							/>
						),
					},
				]}
			/>
		</View>
	);
};

export default AdminProductsModule;
