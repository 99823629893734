import { useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import tw from "twrnc";
import Mapper from "../../components/Mapper";
import NoOrder from "../../components/NoOrder";
import RestaurantCard from "../../components/RestaurantCard";
import SimpleHeader from "../../components/SimpleHeader";
import { PlatformContext } from "../../contexts";
import useApi from "../../hooks/useApi";
import { getRestaurants } from "../../utils/db";
import { webBack } from "../../utils/others";
import { isDesktop } from "../../utils/platform";
import Screen from "../Screen";

const FavRestaurantsModule = () => {
	const platform = useContext(PlatformContext);
	const { goBack, navigate } = useNavigation();
	const { data, loading } = useApi(getRestaurants, { favorites: true });
	const { t } = useTranslation();

	return (
		<Screen Container={View} backgroundColor="white" style={{ ...tw`pt-0 pb-0` }}>
			<SimpleHeader
				style={isDesktop && { marginTop: 80 }}
				title={t(`account.favorite_restaurants.${platform.vertical}`)}
				left={!isDesktop && { onPress: () => webBack(goBack) }}
			/>

			{loading || data?.length ? (
				<ScrollView
					style={{
						backgroundColor: platform.colors.background,
						...tw`${!isDesktop ? "p-3" : "pl-5"} flex-1`,
					}}
					contentContainerStyle={
						isDesktop && tw`w-full flex-row flex-wrap justify-start gap-5 mt-5`
					}
				>
					<Mapper
						loading={loading}
						data={data}
						render={(d) => (
							<RestaurantCard
								showBottomPad={!isDesktop}
								width={isDesktop ? (window.innerWidth > 1400 ? "32%" : "47%") : "100%"}
								key={`r_${d.id}`}
								restaurant={d}
								onPress={() => navigate("r", d)}
							/>
						)}
					/>
				</ScrollView>
			) : (
				<NoOrder title="errors.no_restaurant" />
			)}
		</Screen>
	);
};

export default FavRestaurantsModule;
