import { DateTime } from "luxon";
import { showLocation } from "react-native-map-link";
import { isWeb } from "./platform";

export const openMap = (name, a) =>
	isWeb
		? window.open(`https://www.google.com/maps/place/${a.lat},${a.lng}/@${a.lat},${a.lng},12z`)
		: showLocation({
				latitude: a.lat,
				longitude: a.lng,
				title: name,
				googlePlaceId: a.place_id,
		  });

export const upF = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const intTime = (i) => {
	let hour = Math.floor(i);
	let rest = i - hour;
	if (hour < 10) hour = `0${hour}`;

	let mins = rest * 60;
	if (mins < 10) mins = `0${mins}`;

	return `${hour}:${mins}`;
};

export const intTime2 = (i) => {
	let hour = Math.floor(i);
	let rest = i - hour;
	if (hour < 10) hour = `0${hour}`;

	let mins = rest * 60;
	if (mins < 10) mins = `0${mins}`;

	return { hour, mins };
};

export const getOpenTimesToday = (days, from, until) => {
	if (!days || !days?.length || typeof days !== "object") return null;

	const weekday = DateTime.now().weekday;
	const day = days?.find((x) => x.id === weekday);

	if (!day?.hours.length) return null;

	const hours = day.hours.map((h) => `${from} ${intTime(h.from)} ${until} ${intTime(h.until)}`);
	return hours;
};

export const parseRouteJson = (p, def = null) => {
	try {
		if (!p) return def;
		if (typeof p === "string") return def;

		if (JSON.stringify(p).includes("[object Object]")) return null;

		Object.keys(p).forEach((k) => {
			if (p?.[k] === "[object Object]") delete p[k];
		});

		return p;
	} catch (ex) {
		return null;
	}
};

export const webBack = (fn) => {
	if (isWeb) return window.history.back();
	return fn();
};

export const hasTrans = (p) => {
	return p && (p.en || p.fr || p.nl);
};

export const getAnyTrans = (p, lang) => {
	return p?.[lang] || p?.en || p?.fr || p?.nl;
};

export const parseFee = (n) => {
	if (!n) return 0;

	let num = parseFloat(n);
	if (isNaN(num)) return 0;

	return num;
};
