import * as Camera from "expo-camera";
import { SaveFormat, manipulateAsync } from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import * as Linking from "expo-linking";
import * as Location from "expo-location";
import * as MediaLibrary from "expo-media-library";
import { Alert, Platform } from "react-native";

export default async function getPermissionAsync(permission) {
	let { status, granted, canAskAgain } =
		permission === "location"
			? await Location.getForegroundPermissionsAsync()
			: permission === "camera"
			? await Camera.getCameraPermissionsAsync()
			: await MediaLibrary.getPermissionsAsync();

	if (!granted && canAskAgain) {
		const result =
			permission === "location"
				? await Location.requestForegroundPermissionsAsync()
				: permission === "camera"
				? await Camera.requestCameraPermissionsAsync()
				: await MediaLibrary.requestPermissionsAsync();

		if (result.granted) return getPermissionAsync(permission);
	}

	if (status !== "granted") {
		return false;
	}

	return true;
}

export const openSettingsAlert = (title, description, cancel, settings) => {
	Alert.alert(
		title,
		description,
		[
			Platform.OS === "ios"
				? {
						text: settings,
						onPress: () => {
							Linking.openURL("app-settings:");
						},
				  }
				: null,
			{ text: cancel, onPress: () => {}, style: "cancel" },
		].filter((x) => x),
		{ cancelable: true }
	);
};

export async function getLocationAsync() {
	if (await getPermissionAsync("location")) {
		const location = await Location.getCurrentPositionAsync({
			accuracy: Location.Accuracy.Balanced,
		});

		return location;
	}

	return null;
}

const handleFile = async (result) => {
	if (result.canceled) return null;

	const asset = result.assets[0];
	const image = await manipulateAsync(asset.uri, [{ resize: { width: 1000 } }], {
		format: SaveFormat.PNG,
		base64: true,
		compress: 0,
	});

	return image;
};

export async function pickImageAsync() {
	if (await getPermissionAsync("media library")) {
		const result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			quality: 0.2,
			allowsEditing: true,
			presentationStyle: ImagePicker.UIImagePickerPresentationStyle.POPOVER,
		});

		return handleFile(result);
	}
}

export async function takePictureAsync() {
	if (await getPermissionAsync("camera")) {
		const result = await ImagePicker.launchCameraAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			quality: 0.2,
			allowsEditing: true,
		});

		return handleFile(result);
	}
}
