import Constants from "expo-constants";
import { useContext } from "react";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import tw from "twrnc";
import Loading from "../components/Loading";
import { PlatformContext } from "../contexts";
import { isAndroid } from "../utils/platform";

const Screen = ({
	stickyHeaderIndices,
	Container = KeyboardAwareScrollView,
	children,
	loading,
	style,
	backgroundColor,
}) => {
	const platform = useContext(PlatformContext);

	return (
		<Container
			stickyHeaderIndices={stickyHeaderIndices}
			keyboardShouldPersistTaps="handled"
			listViewDisplayed={false}
			style={{ ...tw`flex-1`, backgroundColor: backgroundColor || platform.colors.background }}
			contentContainerStyle={{
				...tw`py-5 flex-grow`,
				paddingTop: isAndroid ? 0 : Constants.statusBarHeight,
				...style,
			}}
		>
			{loading ? <Loading /> : children}
		</Container>
	);
};

export default Screen;
