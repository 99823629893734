import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import { PlatformContext, UserContext } from "../../contexts";
import { openUrlFn } from "../../modules/AuthModule/AuthModule";

const Footer = ({ style }) => {
	const platform = useContext(PlatformContext);
	const { lang } = useContext(UserContext);
	const { t } = useTranslation();

	const links = [
		{ title: t("account.help"), action: openUrlFn(`https://${platform.domain}/${lang}/contact`) },
		{ title: t("account.terms"), action: openUrlFn(`https://${platform.domain}/${lang}/terms`) },
		{
			title: t("account.privacy"),
			action: openUrlFn(`https://${platform.domain}/${lang}/privacy`),
		},
	];
	return (
		<View
			style={{
				...tw`flex-col justify-center items-center py-10 bg-[${platform.colors.background}]`,
				...style,
			}}
		>
			<View style={tw`flex-row items-center justify-center flex-wrap mb-3`}>
				{links.map((l, i) => (
					<TouchableOpacity onPress={l.action} key={`link_${i}`} style={tw`mx-4`}>
						<Text
							style={{
								fontSize: 15,
								fontWeight: 300,
							}}
						>
							{l.title}
						</Text>
					</TouchableOpacity>
				))}
			</View>
			<Text
				style={{
					fontSize: 13,
					fontWeight: 300,
				}}
			>
				&copy; 2023 {platform.name}
			</Text>
		</View>
	);
};

export default Footer;
