import React from "react";
import io from "socket.io-client";
import db from "../../utils/db";

export default class Socketor extends React.Component {
	componentDidMount() {
		this.websocket(this.props.room);

		if (this.props.poll) this.poll();
	}

	poll() {
		this.timeout = setTimeout(async () => {
			try {
				await this.props.onMessage();
			} catch (ex) {
				console.log(`ex`, ex);
			}

			this.poll();
		}, this.props.poll);
	}

	componentWillUnmount() {
		this.socket.close();

		if (this.timeout) clearTimeout(this.timeout);
	}

	websocket = (room) => {
		this.socket = io(db.URL, {
			reconnection: true,
			reconnectionDelay: 500,
			reconnectionDelayMax: 1000,
			reconnectionAttempts: 99999,
			query: {
				room,
			},
		});

		this.socket.on("reconnect", this.props.onMessage);

		this.props.messages.forEach((message) => this.socket.on(message, this.props.onMessage));
	};

	render() {
		return null;
	}
}
