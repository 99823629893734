import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, TextInput, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../components/BigButton";
import Checkbox from "../../components/Checkbox";
import AddressSearch from "../../components/Forms/AddressSearch";
import SimpleHeader from "../../components/SimpleHeader";
import YellowLine from "../../components/YellowLine";
import { PlatformContext } from "../../contexts";
import { isAndroid, isIos, isWeb } from "../../utils/platform";
import { styles } from "../../utils/settings";

export const AddressBadge = ({ iconColor, iconRight, style, type, onPress, address, children }) => (
	<BigButton
		iconRight={iconRight}
		iconColor={iconColor}
		onPress={() => (onPress ? onPress(address) : null)}
		style={{ ...tw`justify-between mb-2 ${isIos ? "pt-3 pb-2" : "py-2"} px-3`, ...style }}
		type={type}
		textStyle={{ color: "black" }}
		rawInner={
			<>
				<View style={tw`flex flex-col items-start justify-center`}>
					<Text style={{ ...styles.header_me, ...tw`text-black`, color: "black" }}>
						{address.street} {address.street_number}
						{address.addition || ""}
					</Text>
					<Text style={{ ...styles.neutral_me, ...tw`text-black` }}>
						{address.postcode} {address.city} ({address.country})
					</Text>
					{address?.comment && (
						<Text style={{ ...styles.desc_s, ...tw`text-black` }}>{address.comment}</Text>
					)}
				</View>
				{children}
			</>
		}
	/>
);

const CheckoutAddress = ({
	addresses,
	constraints,
	onClose,
	address: mainAddress,
	setAddress: setMainAddress,
}) => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();
	const [address, setAddress] = useState(null);
	const [isFocused, setIsFocused] = useState(false);

	const getError = () => {
		if (!address?.place_id) return null;

		if (!address.street_number) return t("address.err_number");
		if (!address.postcode) return t("address.err_postcode");
		if (!constraints?.postcodes?.includes(parseInt(address.postcode)))
			return t(`address.err_not_deliverable_postcode.${platform.vertical}`);

		return null;
	};

	const error = getError();

	useEffect(() => {
		if (mainAddress) setAddress(mainAddress);
	}, [mainAddress]);

	const validAddress = () => {
		if (!address?.place_id) return false;
		if (getError()) return false;

		return true;
	};

	return (
		<>
			<SimpleHeader
				left={{ onPress: onClose, source: "left" }}
				style={tw`mt-0 p-0 mb-5 h-auto`}
				title={t("order.pick_an_address")}
			/>
			{!isFocused && addresses?.length > 0 ? (
				<View>
					<Text style={{ ...styles.header_lg, ...tw`mb-2` }}>{t("word.my_addresses")}</Text>
					{addresses.map((a, i) => (
						<AddressBadge
							key={`address_${i}`}
							onPress={setAddress}
							type={address?.place_id === a.place_id ? "yellow" : "yellow-outline"}
							address={a}
						/>
					))}
					<YellowLine style={tw`my-3`} />
				</View>
			) : null}
			<Text style={{ ...styles.header_lg, ...tw`mb-2` }}>{t("word.search_new_address")}</Text>
			{!address?.isNew && (
				<AddressSearch
					onFocus={(x) => setIsFocused(true)}
					onBlur={(x) => setIsFocused(false)}
					inputContainerStyle={{
						...tw`w-full border border-[#F1F1F1] px-3 rounded-xl ${
							isAndroid ? "py-3.25" : "py-4.5"
						} bg-[#fbfbfb]`,
						zIndex: 99999,
					}}
					resultsContainerStyle={{
						...tw`w-full`,
						zIndex: 999,
					}}
					inputStyle={{ ...tw`w-full`, ...(isWeb && { outline: "none" }) }}
					onChange={(a) => setAddress({ ...a, isNew: true, saveAddress: true })}
					placeholder={t("word.search_address")}
				/>
			)}
			{address?.isNew && (
				<>
					<AddressBadge
						iconRight="times-circle"
						iconColor="black"
						onPress={() => setAddress(null)}
						address={address}
						type="yellow"
					/>
					<TextInput
						value={address?.comment}
						onChangeText={(t) => setAddress({ ...address, comment: t })}
						numberOfLines={3}
						multiline
						placeholder={t("delivery.add_comment")}
						style={{
							...tw`w-full mt-1 p-3 rounded-xl h-30 bg-gray-100 border  border-gray-200`,
							...(isWeb && { outline: "none" }),
						}}
					/>
					<TouchableOpacity
						style={tw`flex-row items-center mb-3 mt-1`}
						onPress={(x) => setAddress({ ...address, saveAddress: !address.saveAddress })}
					>
						<Checkbox checked={address?.saveAddress} />
						<Text style={tw`flex-1 text-base ml-5 py-1.5 font-semibold text-black`}>
							{t("address.save_address")}
						</Text>
					</TouchableOpacity>
				</>
			)}
			{error && <Text style={{ ...styles.error_me, ...tw`mt-2 text-center` }}>{error}</Text>}
			<View style={tw`w-full mt-5`}>
				<BigButton disabled={!validAddress()} onPress={() => [setMainAddress(address), onClose()]}>
					{t("word.confirm")}
				</BigButton>
			</View>
		</>
	);
};

export default CheckoutAddress;
