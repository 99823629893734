import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../components/BigButton";
import HalfModal from "../../components/HalfModal";
import PaymentMethod from "../../components/PaymentMethod";
import RadioButton from "../../components/RadioButton";
import SimpleHeader from "../../components/SimpleHeader";
import { isIos } from "../../utils/platform";
import { styles } from "../../utils/settings";

export const CheckoutPaymentTypeIcons = ({ type }) =>
	type === 2 ? (
		<View style={tw`flex-row mt-1`}>
			{["bancontact", "kbc", "belfius", "revolut", "creditcard", isIos ? "applepay" : null]
				.filter((x) => x)
				.map((pm) => (
					<PaymentMethod
						key={`pm_${pm}`}
						method={pm}
						style={{ width: 25, height: 20, marginRight: 5 }}
					/>
				))}
		</View>
	) : (
		<PaymentMethod method={"cash"} style={{ width: 25, height: 20, marginRight: 5 }} />
	);

const CheckoutPaymentTypeModal = ({ bags, restaurant, meta, onClose }) => {
	const [form, setForm] = useState(meta);
	const { t } = useTranslation();

	const onChange = (f, v) => setForm((d) => ({ ...d, [f]: v }));

	const onConfirm = () => {
		bags.setMetas(restaurant.id, form);
		onClose();
	};

	const Inner = () => (
		<>
			<SimpleHeader
				hideLeft
				right={{ onPress: onClose, source: "close" }}
				style={tw`mt-0 p-0 mb-2 h-auto`}
				title={t("checkout.payment_type")}
			/>
			{[2, 1].map((i) => (
				<TouchableOpacity
					key={`payment_${i}`}
					onPress={() => onChange("payment_type", i)}
					style={tw`flex-row justify-between items-center mb-3`}
				>
					<View>
						<Text style={styles.header_me}>{t(`checkout.payment_type_${i}`)}</Text>
						<CheckoutPaymentTypeIcons type={i} />
					</View>
					<RadioButton
						selected={form.payment_type === i}
						onPress={() => onChange("payment_type", i)}
					/>
				</TouchableOpacity>
			))}

			<View style={tw`w-full mt-3`}>
				<BigButton onPress={onConfirm}>{t("word.confirm")}</BigButton>
			</View>
		</>
	);

	return (
		<HalfModal animationType={"slide"} visible={true}>
			<Inner />
		</HalfModal>
	);
};

export default CheckoutPaymentTypeModal;
