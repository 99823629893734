import Icon from "@expo/vector-icons/Entypo";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageBackground, Linking, Share, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";
import SimpleHeader from "../../../components/SimpleHeader";
import YellowLine from "../../../components/YellowLine";
import { PlatformContext, UserContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import { getRestaurantMonthlyReport } from "../../../utils/db";
import { webBack } from "../../../utils/others";
import { isWeb } from "../../../utils/platform";
import { styles } from "../../../utils/settings";
import Screen from "../../Screen";

const KPI = ({ d1, d2, d3, d4 }) => (
	<View
		style={{
			...tw`mt-3 rounded-xl bg-white p-4 flex-col items-center justify-center`,
			width: "48%",
		}}
	>
		<Text style={{ ...styles.header_2xl, textAlign: "center" }}>{d1}</Text>
		<Text style={{ ...styles.header_xl, textAlign: "center" }}>{d2}</Text>
		<YellowLine style={{ ...tw`my-3`, width: "90%" }} />
		<Text style={{ textAlign: "center" }}>{d3}</Text>
		<Text style={{ height: 45, ...styles.desc, textAlign: "center", ...tw`mt-2` }}>{d4}</Text>
	</View>
);

const AdminChallenges = () => {
	const platform = useContext(PlatformContext);
	const {
		params: { restaurant },
	} = useRoute();
	const { goBack } = useNavigation();
	const { data, loading } = useApi(getRestaurantMonthlyReport, { id: restaurant });
	const { lang } = useContext(UserContext);
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	if (!data || loading) return <Screen loading />;

	const objs = data.objectives.sort((a, b) => a.points - b.points);

	const compl_obj = objs.filter((x) => x.points <= data.total_points);

	const curr_obj = objs.filter((x) => x.points > data.total_points)[0];

	const objective = curr_obj.points;
	const percent = data.total_points / objective;

	return (
		<Screen style={tw`pt-0 pb-0`} loading={loading}>
			<View
				style={tw`bg-[${platform.colors.background_dark}] flex-col items-center justify-center`}
			>
				<ImageBackground
					style={{
						height: 70,
						width: "100%",
					}}
					source={require("../../../../assets/frites_3.png")}
					resizeMode="cover"
				>
					<SimpleHeader left={{ onPress: () => webBack(goBack) }} />
				</ImageBackground>
				<Text style={{ ...styles.header_2xl, color: "white", ...tw`pt-5` }}>
					{t("admin.challenges")}
				</Text>
				<Text style={{ ...styles.desc, color: platform.colors.yellow_main }}>
					{t("admin.challenges_desc")}
				</Text>
				<View
					style={{
						...tw`pt-10 pb-2 mt-5 w-90 bg-white flex-col items-center justify-center`,
						borderRadius: 10,
					}}
				>
					<View
						style={{
							...tw`mx-2`,
							width: "90%",
							height: 10,
							borderRadius: 20,
							backgroundColor: platform.colors.gray_light,
							position: "relative",
						}}
					>
						<View
							style={{
								width: `${percent * 100}%`,
								height: 10,
								borderRadius: 20,
								backgroundColor: platform.colors.red,
							}}
						></View>
						<Text
							style={{
								...styles.desc,
								position: "absolute",
								left: -5,
								top: -18,
								color: platform.colors.gray_dark,
							}}
						>
							0
						</Text>
						<Text
							style={{
								...styles.desc,
								position: "absolute",
								right: -5,
								top: -18,
								color: platform.colors.gray_dark,
							}}
						>
							{objective}
						</Text>
						<Text
							style={{
								...styles.desc,
								position: "absolute",
								left: `${percent * 100}%`,
								top: -35,
								marginLeft: -50,
								fontWeight: "600",
								color: platform.colors.red,
								fontSize: 22,
								width: 100,
								textAlign: "center",
							}}
						>
							{data.total_points}
						</Text>
						<View
							style={{
								position: "absolute",
								left: `${percent * 100}%`,
								marginLeft: -2,
								width: 1,
								height: 12,
								backgroundColor: platform.colors.red,
								top: -8,
							}}
						></View>
					</View>
					<YellowLine style={tw`my-3`} />
					<View style={{ ...tw`pr-3 pl-5 flex-row justify-between items-center`, width: "100%" }}>
						<View>
							<Text style={styles.header_lg}>{curr_obj.title[lang]}</Text>
							<Text style={styles.desc}>{curr_obj.description[lang]}</Text>
						</View>
						<ImageBackground
							style={{
								height: 70,
								width: 70,
								...tw`rounded-xl overflow-hidden`,
							}}
							source={{ uri: curr_obj.image }}
							resizeMode="contain"
						/>
					</View>
				</View>
				{compl_obj?.length > 0 ? (
					<TouchableOpacity
						onPress={() => setOpen(!open)}
						style={{
							backgroundColor: platform.colors.yellow_main,
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10,
							...tw`w-full py-3 px-3 mt-5 flex-row items-center justify-between`,
						}}
					>
						<View></View>
						<Text style={tw`font-bold pl-2`}>{t("admin.challenges_completed")}</Text>
						<Icon name={open ? "chevron-up" : "chevron-down"} size={20} />
					</TouchableOpacity>
				) : (
					<View style={tw`pb-5`}></View>
				)}
				{open && (
					<View
						style={tw`pb-5 w-full flex-col items-center justify-center bg-[${platform.colors.yellow_main}]`}
					>
						{compl_obj?.map((o, i) => (
							<View
								key={`compl_obj_${i}`}
								style={{
									...tw`bg-white rounded-xl mt-5 pr-3 pl-5 flex-row justify-between items-center`,
									width: "90%",
								}}
							>
								<View>
									<Text style={styles.header_lg}>{o.title[lang]}</Text>
									<Text style={styles.desc}>{o.description[lang]}</Text>
								</View>
								<ImageBackground
									style={{
										height: 70,
										width: 70,
										...tw`rounded-xl m-2 overflow-hidden`,
									}}
									source={{ uri: o.image }}
									resizeMode="contain"
								/>
							</View>
						))}
					</View>
				)}
			</View>
			<View style={tw`flex-col items-center justify-center`}>
				<Text style={{ ...tw` mt-10 mb-5`, ...styles.header_2xl, textAlign: "center" }}>
					{t("admin.challenges_numbers")}
				</Text>
				<View
					style={{
						...tw` w-90 p-3 flex-row items-center justify-between bg-white border-2 border-[${platform.colors.yellow_main}]`,
						borderRadius: 10,
					}}
				>
					<Text style={styles.header_lg}>{t("admin.challenges_points")}</Text>
					<Text style={styles.header_2xl}>{data.total_points}</Text>
				</View>
				<View
					style={{
						...tw`mt-3 w-90 p-3 flex-row items-center justify-between bg-white border-2 border-[${platform.colors.red}]`,
						borderRadius: 10,
					}}
				>
					<Text style={styles.header_lg}>{t("admin.challenges_refused")}</Text>
					<Text style={styles.header_2xl}>{data.total_refused}</Text>
				</View>
				<View style={tw`flex-row mt-2 mb-2 flex-wrap items-center justify-between w-90`}>
					<KPI
						d1={data.total_orders}
						d2={t("admin.challenges_orders")}
						d3={t("admin.challenges_orders_desc")}
						d4={t("admin.challenges_orders_count", { count: data.pts_order })}
					/>
					<KPI
						d1={data.total_views}
						d2={t("admin.challenges_clicks")}
						d3={t("admin.challenges_clicks_desc")}
						d4={t("admin.challenges_clicks_count", { count: data.pts_view })}
					/>
				</View>
			</View>
			<View
				style={tw`pt-10 mt-10 bg-[${platform.colors.background_dark}] flex-col items-center justify-center`}
			>
				<Text style={{ ...styles.header_2xl, ...tw`text-white`, textAlign: "center" }}>
					{t("admin.challenges_tips")}
				</Text>
				<View
					style={{
						...tw`p-2 mt-5 w-90 bg-white flex-col items-center justify-center`,
						borderRadius: 10,
					}}
				>
					<Text style={{ ...styles.header_me, textAlign: "center", ...tw`p-1` }}>
						{t("admin.challenges_tip_1")}
					</Text>
					<BigButton
						onPress={() => {
							Share.share({
								message: t("admin.challenges_share_1"),
								url: `https://fritzy.be/${data.slug}`,
							});
						}}
						style={{ width: "100%", ...tw`mt-2` }}
					>
						{t("admin.challenges_action_1")}
					</BigButton>
				</View>
				<View style={tw`flex-row items-center justify-between mt-5 px-5 py-5 w-full`}>
					<View style={{ ...tw`flex-col items-center justify-center`, width: "43%" }}>
						<ImageBackground
							style={{
								height: 60,
								width: 60,
							}}
							source={require("../../../../assets/icon_share.png")}
							resizeMode="contain"
						/>
						<Text
							style={{
								...styles.header_me,
								color: platform.colors.yellow_main,
								textAlign: "center",
								...tw`py-2`,
							}}
						>
							{t("admin.challenges_tip_2")}
						</Text>
						<Text style={{ height: 100, color: "white", textAlign: "center" }}>
							{t("admin.challenges_tip_2_desc")}
						</Text>
						<BigButton
							onPress={() => {
								const url = `https://fritzy.be/flyer_client_${lang}.jpg`;
								if (isWeb) window.open(url);
								else Linking.openURL(url);
							}}
							style={tw`py-2 w-full`}
						>
							{t("admin.challenges_action_2")}
						</BigButton>
					</View>
					<ImageBackground
						style={{
							height: "90%",
							width: 10,
						}}
						source={require("../../../../assets/vertical_line.png")}
						resizeMode="contain"
					/>
					<View style={{ ...tw`flex-col items-center justify-center`, width: "43%" }}>
						<ImageBackground
							style={{
								height: 60,
								width: 60,
							}}
							source={require("../../../../assets/icon_add_fries.png")}
							resizeMode="contain"
						/>
						<Text
							style={{
								...styles.header_me,
								color: platform.colors.yellow_main,
								textAlign: "center",
								...tw`py-2`,
							}}
						>
							{t("admin.challenges_tip_3")}
						</Text>
						<Text style={{ height: 100, color: "white", textAlign: "center" }}>
							{t("admin.challenges_tip_3_desc")}
						</Text>
						<BigButton
							onPress={() => {
								Share.share({
									message: t("admin.challenges_share_2"),
									url: "https://fritzy.be/pro",
								});
							}}
							style={tw`py-2 w-full`}
						>
							{t("admin.challenges_action_3")}
						</BigButton>
					</View>
				</View>
				<ImageBackground
					style={{
						height: 70,
						width: "100%",
						transform: [{ rotateZ: "180deg" }],
					}}
					source={require("../../../../assets/frites_3.png")}
					resizeMode="cover"
				/>
			</View>
		</Screen>
	);
};

export default AdminChallenges;
