import { track } from "@amplitude/analytics-react-native";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, StatusBar, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../components/BigButton";
import Footer from "../../components/Footer";
import Heading from "../../components/Heading";
import LogoHeader from "../../components/LogoHeader";
import Mapper from "../../components/Mapper";
import { VerticalFries } from "../../components/NavBar";
import PromoBanner from "../../components/PromoBanner";
import RestaurantCard from "../../components/RestaurantCard";
import SearchInput from "../../components/SearchInput";
import { PlatformContext, UserContext } from "../../contexts";
import useApi from "../../hooks/useApi";
import useFocusLoad from "../../hooks/useFocusLoad";
import { getRestaurants } from "../../utils/db";
import { isDesktop, isIos } from "../../utils/platform";
import { styles } from "../../utils/settings";
import MapModule from "../MapModule/MapModule.web";
import Screen from "../Screen";

const perPage = 4;

const HomeModule = () => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();
	const { navigate } = useNavigation();
	const { location } = useContext(UserContext);
	const [filters, setFilters] = useState(null);
	const [near, setNear] = useState(null);
	const [page, setPage] = useState(0);
	const focus = useFocusLoad();
	const isFocused = useIsFocused();
	const { data, load, loading } = useApi(
		getRestaurants,
		{
			...filters,
			skip: isDesktop ? page * perPage : 0,
			limit: isDesktop ? perPage : (page + 1) * 2 + 2,
		},
		[filters, location]
	);

	useEffect(() => {
		if (focus && data && !loading) load(true);
	}, [focus]);

	useEffect(() => {
		load(true);
	}, [page]);

	const Location = !loading ? (
		<View style={tw`flex-row items-center mb-5`}>
			<Heading style={{ maxWidth: "90%", marginBottom: 0 }} numberOfLines={1}>
				{near ? (
					<>{t("word.near", { place: `${near.city} (${near.country})`.trim() })}</>
				) : (
					<>{t(`word.results_near_you.${platform.vertical}`)}</>
				)}
			</Heading>
			{near && (
				<TouchableOpacity
					style={tw`ml-3 bg-[${platform.colors.background_dark}] rounded-full h-5 w-5 flex-col items-center justify-center`}
					onPress={() => [setFilters(null), setNear(null)]}
				>
					<Text style={tw`text-white font-bold mb-0.5`}>x</Text>
				</TouchableOpacity>
			)}
		</View>
	) : null;

	const List = ({ cardWidth }) => (
		<Mapper
			loading={loading}
			data={data}
			render={(d) => (
				<RestaurantCard
					showBottomPad={!isDesktop}
					width={cardWidth}
					key={`r_${d.id}`}
					restaurant={d}
					onPress={() => navigate("r", d)}
				/>
			)}
		/>
	);

	const Search = (
		<SearchInput
			onCenter={() => setFilters(null)}
			onChange={({ lat, lng, ...address }) => {
				setFilters({ lat, lng });
				setNear(address);
				track("SEARCH_HOME");
			}}
		/>
	);

	if (isDesktop) {
		return (
			<>
				<View
					style={{
						...tw`flex-col shadow-xl justify-center items-center bg-[${platform.colors.background_dark}] mt--1`,
						zIndex: 2,
						position: "fixed",
						top: 79,
						left: 0,
						right: 0,
					}}
				>
					<VerticalFries style={{ marginTop: -63 }} />
					<Text
						style={{
							...styles.header_3xl,
							...tw`text-white text-center`,
						}}
					>
						{t(`header.title_1.${platform.vertical}`)}
					</Text>
					<Text
						style={{
							...styles.header_3xl,
							...tw`text-white text-center`,
						}}
					>
						{t(`header.title_2.${platform.vertical}`)}
					</Text>
					<View style={tw`w-125 flex-row mt-5 mb-5 gap-2`}>
						{Search}
						<BigButton type={"yellow"} style={tw`py-2`} onPress={() => load()}>
							{t("word.search")}
						</BigButton>
					</View>
				</View>
				<View
					style={{
						...tw`flex-row bg-[${platform.colors.background}]`,
						minHeight: "60vh",
						paddingTop: 240,
					}}
				>
					<View
						style={{
							width: "60%",
							...tw`border-r border-gray-300 py-5 justify-between flex-col`,
						}}
					>
						<View>
							<View style={tw`px-5`}>{Location}</View>
							<View
								style={{
									...tw`flex-row flex-wrap justify-start gap-5 ${
										window.innerWidth < 1200 ? "px-5" : "pl-5"
									}`,
									minHeight: 500,
								}}
							>
								<List cardWidth={window.innerWidth < 1200 ? "100%" : "47%"} />
							</View>
						</View>
						<View style={tw`flex-row justify-center mt-5`}>
							<BigButton
								icon="arrow-left"
								iconColor={platform.colors.background_dark}
								type="dark-outline"
								style={tw`w-35 px-1 py-3 mr-1`}
								disabled={!page}
								onPress={() => setPage((p) => p - 1)}
							>
								<Text style={tw`${platform.isPizzy ? `text-black` : ""}`}>
									{t("word.previous")}
								</Text>
							</BigButton>
							<BigButton
								icon="arrow-right"
								iconColor={platform.colors.background_dark}
								type="dark-outline"
								style={tw`w-35 px-1 py-3 ml-1`}
								disabled={data?.length !== 4}
								onPress={() => setPage((p) => p + 1)}
							>
								<Text style={tw`${platform.isPizzy ? `text-black` : ""}`}>{t("word.next")}</Text>
							</BigButton>
						</View>
					</View>
					<View style={{ width: "40%" }}>
						{data && (
							<MapModule
								showSearch={false}
								forceCenter={filters?.lat ? [filters.lng, filters.lat] : null}
							/>
						)}
					</View>
				</View>
				<PromoBanner />
				{isDesktop && <Footer />}
			</>
		);
	}

	return (
		<>
			{isFocused && <StatusBar barStyle={"light-content"} />}

			<LogoHeader />
			<View
				style={{
					...tw`justify-between flex-row ml-3 mr-3`,
					position: "absolute",
					top: isIos ? 155 + 35 / 2 : 125,
					left: 0,
					right: 0,
					zIndex: 999,
				}}
			>
				{Search}
				<TouchableOpacity
					onPress={() => navigate("map")}
					style={{ marginLeft: 10, borderRadius: 15, ...tw`shadow-md` }}
				>
					<Image
						source={
							platform.isPizzy
								? require("../../../assets/search_icon_pizzy.png")
								: require("../../../assets/search_icon.png")
						}
						style={{
							height: 53,
							width: 53,
						}}
					/>
				</TouchableOpacity>
			</View>
			<Screen style={{ ...tw`pt-10 px-4` }} barStyle={"light-content"}>
				{Location}
				<List />
				{data && data?.length % 2 === 0 ? (
					<BigButton type="dark-outline" disabled={loading} onPress={() => setPage((p) => p + 1)}>
						<Text style={tw`${platform.isPizzy ? `text-black` : ""}`}>{t("word.show_all")}</Text>
					</BigButton>
				) : null}
			</Screen>
		</>
	);
};

export default HomeModule;
