import { useState } from "react";

const useForm = (defData = {}) => {
	const [data, setData] = useState(defData);

	const set = (p, v) => {
		return setData({ ...data, [p]: v });
	};

	return [data, { setFormValue: set, setForm: setData, resetForm: () => setData(defData) }];
};

export default useForm;
