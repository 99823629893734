import { useIsFocused, useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import tw from "twrnc";
import Mapper from "../../components/Mapper";
import NoOrder from "../../components/NoOrder";
import OrderCard from "../../components/OrderCard";
import SimpleHeader from "../../components/SimpleHeader";
import Socketor from "../../components/Socketor";
import Tabs from "../../components/Tabs";
import { UserContext } from "../../contexts";
import useApi from "../../hooks/useApi";
import { getUserOrders } from "../../utils/db";
import { isDesktop } from "../../utils/platform";

const OrdersList = ({ showNoOrder, filter }) => {
	const { user } = useContext(UserContext);
	const isFocused = useIsFocused();
	const { navigate } = useNavigation();
	const { data, loading, load } = useApi(getUserOrders, { filter }, [isFocused]);

	if (showNoOrder && !loading && !data?.length) return <NoOrder title="errors.no_running_orders" />;

	return (
		<ScrollView
			style={tw`flex-1 ${isDesktop ? "pl-5 py-5" : "p-3"}`}
			contentContainerStyle={isDesktop && tw`flex-row flex-wrap justify-start gap-5`}
		>
			<Socketor
				room={`customer/${user.id}/orders`}
				messages={["order_change"]}
				onMessage={(p) => load()}
			/>
			<Mapper
				loading={loading}
				data={data}
				render={(order, i) => (
					<OrderCard
						style={
							isDesktop && {
								width: window.innerWidth > 1400 ? "32%" : "48%",
								marginBottom: 0,
							}
						}
						key={`order_${order.id}`}
						onPress={() => navigate(`order`, { order_id: order.id, order })}
						order={order}
					/>
				)}
			/>
		</ScrollView>
	);
};

const OrdersModule = () => {
	const { t } = useTranslation();
	const { user } = useContext(UserContext);

	return (
		<View style={{ flex: 1, ...tw`bg-white`, marginTop: isDesktop ? 80 : 0 }}>
			<SimpleHeader style={tw``} title={t("route.orders")} />
			{user && (
				<Tabs
					tabs={[
						{
							key: "active",
							title: t("orders.pending"),
							component: () => <OrdersList showNoOrder filter="active" />,
						},
						{
							key: "inactive",
							title: t("orders.past"),
							component: () => <OrdersList filter="inactive" />,
						},
					]}
				/>
			)}
			{!user && <NoOrder />}
		</View>
	);
};

export default OrdersModule;
