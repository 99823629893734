import { useContext } from "react";
import { ImageBackground } from "react-native";
import { PlatformContext } from "../../contexts";

const YellowLine = ({ style }) => {
	const platform = useContext(PlatformContext);

	return (
		<ImageBackground
			source={
				platform.isPizzy
					? require("../../../assets/yellow_line_pizzy.png")
					: require("../../../assets/yellow_line.png")
			}
			resizeMode="cover"
			style={{ height: 2, width: "100%", ...style }}
		/>
	);
};

export default YellowLine;
