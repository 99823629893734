import { Text, View } from "react-native";
import tw from "twrnc";
import Button from "../Button";

const Resultor = ({ result, children, reset }) => {
	if (!result) return children;

	return (
		<View style={tw`p-5 my-5 rounded-xl flex items-center justify-center bg-${result.color}`}>
			<Text style={tw`text-xl font-medium text-center text-black`}>{result.title}</Text>
			<Text style={tw`text-center text-black`}>{result.description}</Text>
			{reset && <Button onPress={reset?.[result.name]}>{result.reset}</Button>}
		</View>
	);
};

export default Resultor;
