import Constants from "expo-constants";
import { Text, View } from "react-native";
import tw from "twrnc";
import { isIos } from "../../utils/platform";
import { styles } from "../../utils/settings";
import ActionButton from "../ActionButton";

const SimpleHeader = ({ hideLeft, title, left, right, style }) => {
	return (
		<View
			style={{
				...tw`p-3 h-15 flex flex-row justify-between items-center`,
				marginTop: isIos ? Constants.statusBarHeight : 5,
				...style,
			}}
		>
			{!hideLeft && <View style={{ width: 50 }}>{left && <ActionButton {...left} />}</View>}
			{title && <Text style={styles.header_xl}>{title}</Text>}
			<View style={{ ...tw`flex-row justify-end`, width: 50 }}>
				{right && <ActionButton style={{ justifyContent: "flex-end" }} {...right} />}
			</View>
		</View>
	);
};

export default SimpleHeader;
