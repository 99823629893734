import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { webBack } from "../../utils/others";

const Redirect = () => {
	const { goBack } = useNavigation();

	useEffect(() => {
		webBack(goBack);
	}, []);

	return null;
};

export default Redirect;
