import { useTranslation } from "react-i18next";
import { Image, ImageBackground, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import boardPng from "../../../assets/board_red.png";
import { styles } from "../../utils/settings";
import YellowLine from "../YellowLine";

const PromoBanner = ({ hideActions, title1, title2, description }) => {
	const { t } = useTranslation();

	return (
		<View style={tw`flex flex-row items-center justify-center bg-white w-full`}>
			<View style={tw`flex flex-col items-center justify-center py-15 mr-25 max-w-100`}>
				<Text style={styles.header_3xl}>{t(title1 || "cta.download_1")}</Text>
				<Text style={styles.header_3xl}>{t(title2 || "cta.download_2")}</Text>
				<YellowLine style={tw`my-5`} />
				<Text style={{ ...styles.desc_s, textAlign: "center" }}>
					{t(description || "cta.download_desc")}
				</Text>
				{!hideActions && (
					<View style={tw`flex-row items-center mt-5 mb-5`}>
						<TouchableOpacity
							onPress={() =>
								window.open("https://apps.apple.com/be/app/fritzy/id6448427635?platform=iphone")
							}
						>
							<ImageBackground
								resizeMode="contain"
								source={{
									uri: "https://fritzy.be/apple-badge.png",
								}}
								style={tw`h-15 w-40 mr-2`}
							/>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() =>
								window.open("https://play.google.com/store/apps/details?id=com.fritzybe.app")
							}
						>
							<ImageBackground
								resizeMode="contain"
								source={{
									uri: "https://fritzy.be/play-badge.png",
								}}
								style={tw`h-15 w-44 ml-2`}
							/>
						</TouchableOpacity>
					</View>
				)}
			</View>
			<View style={{ ...tw`flex-col justify-end h-full items-end`, zIndex: 1 }}>
				<Image
					source={{ uri: "https://fritzy.be/iphones.png" }}
					style={{ zIndex: 1, height: 300, bottom: 0, width: 300 }}
				/>
			</View>
			<ImageBackground
				resizeMode="repeat"
				source={boardPng}
				style={tw`absolute bottom-0 left-0 right-0 w-full h-10`}
			/>
		</View>
	);
};

export default PromoBanner;
