import Loading from "../Loading";

const Mapper = ({ loading, data, render }) => {
	if (loading) return <Loading />;
	if (!data) return null;

	return data.map(render);
};

export default Mapper;
