import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { useContext } from "react";
import { Text, TouchableOpacity } from "react-native";
import tw from "twrnc";
import { PlatformContext } from "../../contexts";

const BigButton = ({
	disabled,
	children,
	iconColor,
	onPress,
	type = "yellow",
	style,
	textStyle,
	icon,
	iconRight,
	rawInner,
}) => {
	const platform = useContext(PlatformContext);
	const styles =
		type === "yellow"
			? { backgroundColor: platform.colors.yellow_main, borderColor: platform.colors.yellow_main }
			: type === "outline"
			? { backgroundColor: "white", ...tw`border` }
			: type === "yellow-outline"
			? {
					...tw`border`,
					borderColor: platform.colors.yellow_main,
			  }
			: type === "red-outline"
			? {
					...tw`border border-red-500`,
			  }
			: type === "dark-outline"
			? {
					...tw`border-[${platform.colors.background_dark}]`,
			  }
			: type === "dark"
			? {
					...tw`bg-[${platform.colors.background_dark}] border-[${platform.colors.background_dark}]`,
			  }
			: type === "red"
			? {
					...tw`bg-[${platform.colors.red}] border-[${platform.colors.red}]`,
			  }
			: type === "hidden-outline"
			? {
					...tw`border`,
					borderColor: "transparent",
			  }
			: type === "gray-outline"
			? { ...tw`border border-gray-300` }
			: {};

	return (
		<TouchableOpacity
			disabled={disabled}
			style={{
				...tw`px-5 py-4 rounded-xl border flex-row items-center justify-center`,
				...styles,
				...style,
				opacity: disabled ? 0.5 : 1,
			}}
			onPress={onPress}
		>
			{icon && (
				<FontAwesome5
					style={tw`${children ? "mr-3" : ""}`}
					name={icon}
					size={15}
					color={iconColor || platform.colors.yellow_main}
				/>
			)}
			{rawInner}
			{children && (
				<Text
					numberOfLines={1}
					adjustsFontSizeToFit
					style={{
						color: platform.isPizzy ? "white" : "black",
						...tw`font-semibold text-base text-center ${icon ? "ml-1" : ""}`,
						...textStyle,
					}}
				>
					{children}
				</Text>
			)}
			{iconRight && (
				<FontAwesome5
					style={tw`${children ? "ml-3" : ""}`}
					name={iconRight}
					size={15}
					color={iconColor || platform.colors.yellow_main}
				/>
			)}
		</TouchableOpacity>
	);
};

export default BigButton;
