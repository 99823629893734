export const getCategories = (products) => {
	const categories = [];
	products.forEach((p) => {
		if (categories.find((x) => x.id === p.category?.id)) return;
		categories.push(p.category);
	});

	return categories;
};

export const getProductPrice = (product, qty = 1) => {
	if (!product.price?.regular && !product.price?.discounted) return 0;
	const total =
		Math.round((product.price.discounted || product.price.regular || 0) * qty * 100) / 100;
	if (isNaN(total)) return 0;
	return total;
};

export const getExtrasPrice = (extras) => {
	let total = 0;
	extras?.forEach((e) => (total += getProductPrice(e.selection, e.qty)));

	return total;
};

export const getProductPriceWithExtras = (product, qty, extras) => {
	const price = product.price.discounted || product.price.regular;
	const priceExtras = getExtrasPrice(extras);

	const total = Math.round((price + priceExtras) * qty * 100) / 100;
	return isNaN(total) ? 0 : total;
};

export const getSelectedOption = (extras, option, selection_id) => {
	const search = extras.find((x) => x.option_id === option.id && x.selection.id === selection_id);
	return search?.qty || 0;
};

// get amount of options in extras
export const getOptionTimes = (extras, option) => {
	return extras.filter((x) => x.option_id === option.id)?.length || 0;
};

export const formatPrice = (p) => {
	p = Number(p || 0);
	if (isNaN(p)) p = 0;
	return Math.round(p * 100) / 100;
};

export const moveInArray = (arr, from, to) => {
	const copy = [...arr];

	var f = copy.splice(from, 1)[0];
	copy.splice(to, 0, f);

	return copy;
};
