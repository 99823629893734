import Icon from "@expo/vector-icons/Entypo";
import { useContext } from "react";
import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import { BagContext, PlatformContext } from "../../contexts";
import { getFile } from "../../utils/db";
import { hasTrans } from "../../utils/others";
import { isDesktop } from "../../utils/platform";
import { getProductPrice } from "../../utils/products";
import { styles } from "../../utils/settings";
import Card from "../Card";
import { TransProp } from "../Trans";

const ProductCard = ({ restaurant, onPress, p, children, renderRight }) => {
	const platform = useContext(PlatformContext);
	const bags = useContext(BagContext);
	const quantity = bags.getQty(restaurant.id, p.id);

	return (
		<Card
			onPress={onPress}
			style={{
				...tw`px-4 rounded-none flex-row justify-between items-center`,
				borderLeftWidth: 4,
				height: 104,
				borderColor: quantity ? platform.colors.yellow_main : "white",
				backgroundColor: quantity
					? platform.isPizzy
						? "rgba(136, 170, 73, 0.1)"
						: "#fef8ed"
					: "white",
			}}
		>
			<View style={tw`flex-grow flex-1`}>
				<Text style={{ ...styles.header_lg, ...tw`` }}>
					<TransProp v={p.name} />{" "}
					{quantity > 0 && <Text style={{ color: platform.colors.yellow_main }}>x{quantity}</Text>}
				</Text>
				{hasTrans(p.description) && (
					<Text numberOfLines={1} style={{ ...styles.desc_s, paddingRight: 15 }}>
						<TransProp v={p.description} />
					</Text>
				)}
				<Text style={tw`font-normal mt-1`}>
					{p.price.discounted > 0 && (
						<>
							<Text style={{ ...tw`line-through`, ...styles.desc_xs }}>
								{p.price.regular.toFixed(2)}€
							</Text>
							{"  "}
						</>
					)}
					<Text>{getProductPrice(p).toFixed(2)}€</Text>
				</Text>
			</View>
			{renderRight
				? renderRight(p)
				: p.image && (
						<ImageBackground
							resizeMode="cover"
							style={tw`w-20  my-1 h-20 rounded-xl overflow-hidden`}
							source={{ uri: getFile(p.image, 300, 20) }}
						/>
				  )}
			{isDesktop && (
				<TouchableOpacity
					onPress={onPress}
					style={tw`pl-5 pr-3 ${!restaurant.avails_first_in ? "opacity-50" : ""}`}
				>
					<Icon name="plus" size={25} />
				</TouchableOpacity>
			)}
			{children}
		</Card>
	);
};

export default ProductCard;
