import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Linking, Text, View } from "react-native";
import tw from "twrnc";
import { PlatformContext, UserContext } from "../../contexts";
import { AddressBadge } from "../../modules/CheckoutModule/CheckoutAddress";
import { updateOrder } from "../../utils/db";
import { printOrder } from "../../utils/print";
import { styles } from "../../utils/settings";
import BigButton from "../BigButton";
import Card from "../Card";
import CheckoutProductCard from "../CheckoutProductCard";
import Mapper from "../Mapper";
import { TransProp } from "../Trans";

const RestaurantOrderCard = ({ onLongPress, order }) => {
	const platform = useContext(PlatformContext);
	const [clicked, setClicked] = useState(false);
	const [confirmCall, setConfirmCall] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const { lang: userLang } = useContext(UserContext);
	const { t } = useTranslation();

	const onAnswer = async (readyIn) => {
		if (readyIn > -1) printOrder(order, readyIn, platform, userLang);
		setClicked(true);
		await updateOrder(order.id, {
			action: "accept_by_restaurant",
			readyIn,
		});
	};

	const onPickup = async () => {
		setClicked(true);
		await updateOrder(order.id, {
			action: "pickup_by_restaurant",
		});
	};

	if (clicked) return null;

	return (
		<Card onLongPress={onLongPress} style={tw`p-0 mb-5 ${clicked ? "opacity-50" : ""}`}>
			<View style={tw`p-4`}>
				<View style={tw`flex-row justify-between items-center`}>
					<Text style={styles.header_2xl}>
						#{order.reference}
						{order.payment_type === 2 && (
							<Text
								style={{
									...styles.desc_xs,
									fontWeight: "bold",
									color: platform.colors.green,
								}}
							>
								{"  "}
								{t("order.already_paid")}
							</Text>
						)}
						{order.payment_type === 1 && (
							<Text
								style={{
									...styles.desc_xs,
									fontWeight: "bold",
									color: platform.colors.red,
								}}
							>
								{"  "}
								{t("order.to_pay")}
							</Text>
						)}
					</Text>
					<Text style={styles.header_2xl}>{order.amount_order.toFixed(2)} €</Text>
				</View>
				{order?.items && (
					<View style={tw`mt-5 w-full`}>
						<Mapper
							data={order.items}
							render={(item, i) => (
								<CheckoutProductCard
									key={`item_${item.product.id}_${i}`}
									item={{
										...item,
										qty: item.quantity,
									}}
									disabled
									isLast={order.items.length === i + 1}
								/>
							)}
						/>
					</View>
				)}
				{order.status === 6 && (
					<View style={tw`flex-col items-center`}>
						<Text style={{ ...styles.header_light, ...tw`mt-5 w-full text-center` }}>
							{DateTime.fromISO(order.pickup_at).toLocaleString(DateTime.DATETIME_SHORT)}
						</Text>
					</View>
				)}
				{order.status === 5 && (
					<View style={tw`flex-col items-center`}>
						<Text
							style={{ ...styles.header_xl, ...tw`mt-4 w-full text-center` }}
							toDate={order.created_at}
						>
							{order.eat_in ? (
								<>
									{t("word.eat_in_places")}
									{": "}
									<TransProp v={order.eat_in.name} />
								</>
							) : order.delivery ? (
								t("delivery.deliver_to")
							) : (
								t("order.pickup_asap")
							)}
						</Text>
						{order.delivery && (
							<AddressBadge
								style={{ width: "100%", ...tw`mt-2` }}
								type={"yellow-outline"}
								address={order.delivery}
							/>
						)}
						<Text style={{ ...styles.header_2xl, ...tw`mb-5 w-full text-center` }}>
							{order.delivery ? `${t("checkout.from_time")} ` : ""}
							{DateTime.fromISO(order.pickup_at).toLocaleString(DateTime.TIME_24_SIMPLE)}
						</Text>
						<View style={tw`flex-row justify-between w-full`}>
							<BigButton
								disabled={clicked || DateTime.fromISO(order.pickup_at).diffNow().as("minutes") > 10}
								onPress={onPickup}
								style={tw`flex-1 mr-2 px-3 py-3 border-0 bg-[${platform.colors.background_dark}]`}
								textStyle={tw`text-white`}
							>
								{t("order.is_ready")}
							</BigButton>
							<BigButton
								disabled={clicked}
								style={tw`flex-1 mr-2 px-3 py-3`}
								onPress={() => {
									if (!confirmCall) return setConfirmCall(true);

									Linking.openURL(`tel:${order.customer_mobile}`);
								}}
							>
								{!confirmCall ? (
									<>
										{t("word.call")} {order.customer_firstname}
									</>
								) : (
									order?.customer_mobile
								)}
							</BigButton>
						</View>
					</View>
				)}
				{order.status === 2 && (
					<>
						<Text style={{ ...styles.header_xl, ...tw`mt-4 w-full text-center` }}>
							{order.eat_in ? (
								<>
									{t("word.eat_in_places")}
									{": "}
									<TransProp v={order.eat_in.name} />
								</>
							) : order.delivery ? (
								t("order.be_delivered")
							) : (
								t("order.pickup_asap")
							)}
						</Text>
						<Text style={{ ...styles.header_2xl, ...tw`mb-5 w-full text-center` }}>
							{order.delivery ? `${t("checkout.from_time")} ` : ""}
							{DateTime.fromISO(order.pickup_at).toLocaleString(DateTime.TIME_24_SIMPLE)}
						</Text>
						{order.delivery && <AddressBadge type={"yellow-outline"} address={order.delivery} />}
						<View style={tw`flex-row justify-between gap-2`}>
							<BigButton
								disabled={clicked}
								onPress={() => onAnswer(0)}
								style={tw`flex-grow py-3 bg-green-400 flex-2 border-0`}
								key={`btn_accept`}
							>
								{t("cta.accept")}
							</BigButton>
							<BigButton
								disabled={clicked}
								onPress={() => (!confirm ? setConfirm(true) : onAnswer(-1))}
								style={tw`bg-red-400 px-3 py-3 flex-1 border-0`}
							>
								{confirm ? t("cta.sure") : t("cta.refuse")}
							</BigButton>
						</View>
						<View style={tw`flex-row justify-between items-center gap-2 mt-2`}>
							{[5, 10, 15, 20, 25, 30].map((x) => (
								<BigButton
									disabled={clicked}
									onPress={() => onAnswer(x)}
									style={tw`${x === 0 ? "px-2" : "px-1"} flex-grow py-3 ${
										x === 0 ? "bg-green-400" : `bg-[${platform.colors.yellow_main}]`
									} flex-grow1 border-0`}
									key={`btn_${x}`}
								>
									+{x}'
								</BigButton>
							))}
						</View>
						<BigButton
							disabled={clicked}
							style={tw`flex-1 mt-2 px-3 py-3 border-0 bg-[${platform.colors.background_dark}]`}
							textStyle={tw`text-white`}
							onPress={() => {
								if (!confirmCall) return setConfirmCall(true);

								Linking.openURL(`tel:${order.customer_mobile}`);
							}}
						>
							{!confirmCall ? (
								<>
									{t("word.call")} {order.customer_firstname}
								</>
							) : (
								order?.customer_mobile
							)}
						</BigButton>
					</>
				)}
			</View>
		</Card>
	);
};

export default RestaurantOrderCard;
