import { useNavigation, useRoute } from "@react-navigation/native";
import { Linking, ScrollView, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/Loading";
import SimpleHeader from "../../../components/SimpleHeader";
import useApi from "../../../hooks/useApi";
import { getRestaurantInvoices } from "../../../utils/db";
import { webBack } from "../../../utils/others";
import { isWeb } from "../../../utils/platform";

const AdminInvoicesModule = () => {
	const { goBack } = useNavigation();
	const { t } = useTranslation();
	const {
		params: { restaurant },
	} = useRoute();
	const { data, loading } = useApi(getRestaurantInvoices, { id: restaurant });

	return (
		<View style={{ flex: 1, ...tw`bg-white` }}>
			<SimpleHeader left={{ onPress: () => webBack(goBack) }} title={t("admin.my_invoices")} />
			{loading ? (
				<Loading />
			) : (
				<ScrollView style={tw`flex-1 p-3`} contentContainerStyle={{ paddingBottom: 20 }}>
					{data.map((inv) => (
						<BigButton
							key={`inv-${inv.id}`}
							icon="file-invoice"
							onPress={() => {
								const url = `https://wmdadhuhhzahjdtmjqne.supabase.co/storage/v1/object/public/invoices/${inv.invoice}`;
								if (isWeb) window.open(url);
								else Linking.openURL(url);
							}}
							style={tw`mb-3`}
						>
							{doRef(inv.reference)} ({DateTime.fromISO(inv.invoiced_on).toFormat("dd-MM-yyyy")})
						</BigButton>
					))}
				</ScrollView>
			)}
		</View>
	);
};

export default AdminInvoicesModule;

const doRef = (num) => {
	const doLength = 5;
	let ref = "";

	const isLength = num.toString().length;
	for (let i = 0; i < doLength - isLength; i++) ref += "0";

	return `FP-${ref}${num.toString()}`;
};
