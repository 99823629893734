import { Modal, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import tw from "twrnc";

const DesktopModal = ({ style, children, visible }) => {
	if (!visible) return null;

	return (
		<Modal animationType="none" visible={visible} transparent>
			<KeyboardAwareScrollView
				contentContainerStyle={{
					...tw`flex-col flex-1 justify-center items-center`,
					backgroundColor: "rgba(0,0,0,0.5)",
				}}
			>
				<View
					style={{
						...tw`flex w-full justify-center flex overflow-hidden bg-white shadow-xl`,
						borderRadius: 20,
						width: window.innerWidth < 1200 ? "70%" : "35%",
						maxWidth: 800,
						maxHeight: "80vh",
						height: "fit-content",
						...style,
					}}
				>
					{children}
				</View>
			</KeyboardAwareScrollView>
		</Modal>
	);
};

export default DesktopModal;
