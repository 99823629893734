import { useIsFocused } from "@react-navigation/native";
import { useEffect, useState } from "react";

const useFocusLoad = (ready = true) => {
	const isFocused = useIsFocused();
	const [reload, setReload] = useState();

	useEffect(() => {
		if (isFocused && ready) setReload(new Date().getTime());
	}, [isFocused]);

	return reload;
};

export default useFocusLoad;
