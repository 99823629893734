import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StatusBar, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";
import Checkbox from "../../../components/Checkbox";
import HalfModal from "../../../components/HalfModal";
import RadioButton from "../../../components/RadioButton";
import SimpleHeader from "../../../components/SimpleHeader";
import YellowLine from "../../../components/YellowLine";
import { PlatformContext } from "../../../contexts";
import { upsertRestaurantOption } from "../../../utils/db";
import { webBack } from "../../../utils/others";
import { moveInArray } from "../../../utils/products";
import { styles } from "../../../utils/settings";
import Screen from "../../Screen";
import { InputBase, InputContainer, LangField } from "../CategoriesModule/AdminCategoriesModule";
import { EditButton, OptionCard, PriceEditor } from "../ProductEditModule/AdminProductEditModule";

const Modal = ({ choice, saving, onSubmit, cancel }) => {
	const [form, setForm] = useState(choice);
	const { t } = useTranslation();

	useEffect(() => {
		setForm(choice);
	}, [choice]);

	if (!choice) return null;

	const { en, nl, fr } = form?.name || {};

	return (
		<HalfModal visible>
			<SimpleHeader
				hideLeft
				style={tw`mt-0 p-0 mb-5 h-auto`}
				title={t("admin.modify_choice")}
				right={{ onPress: cancel, source: "close" }}
			/>
			<TouchableOpacity
				style={tw`flex-row items-center`}
				onPress={(x) => setForm({ ...form, active: !form?.active })}
			>
				<Checkbox checked={form?.active} />
				<Text style={tw`flex-1 text-base ml-5 py-1.5 font-semibold text-black`}>
					{t("admin.show_choice_option")}
				</Text>
			</TouchableOpacity>
			<YellowLine style={tw`my-5`} />
			<LangField onChange={setForm} value={form} field="name" />
			<YellowLine style={tw`my-5`} />
			<PriceEditor onChange={(x) => setForm({ ...form, price: x })} data={form?.price || {}} />
			<BigButton disabled={saving || !en || !fr || !nl} onPress={() => onSubmit(form)}>
				{t("word.save")}
			</BigButton>
		</HalfModal>
	);
};

const AdminOptionEditModule = () => {
	const platform = useContext(PlatformContext);
	const { goBack } = useNavigation();
	const {
		params: { restaurant, option: initOption },
	} = useRoute();
	const [choice, setChoice] = useState(null);

	const [saving, setSaving] = useState(false);
	const [form, setForm] = useState(initOption);
	const { t } = useTranslation();

	useEffect(() => {
		setForm(initOption);
	}, [initOption]);

	const onChoice = (x) => {
		const copy = [...(form?.options || [])];
		const o = copy.findIndex((y) => y.id === x.id);
		if (o === -1) {
			copy.push(x);
		} else {
			copy[o] = x;
		}

		setForm({ ...form, options: copy });
		setChoice(null);
	};

	const onMove = (from, to) => {
		const copy = moveInArray(form.options, from, to);
		setForm({ ...form, options: copy });
	};

	const onRemove = (ind) => {
		const copy = [...(form?.options || [])];

		copy.splice(ind, 1);

		setForm({ ...form, options: copy });
	};

	const onSubmit = async (form) => {
		setSaving(true);
		if (!form.id) form.sort = new Date().getTime();

		await upsertRestaurantOption(restaurant, form);

		webBack(goBack);
	};

	if (!form) return null;

	const { en, nl, fr } = form?.name || {};

	return (
		<>
			<Screen backgroundColor="white" style={tw`p-0`}>
				<StatusBar barStyle={"light-content"} />
				<View style={tw`bg-white py-5 px-4`}>
					<SimpleHeader
						style={tw`mt-0 p-0 mb-5 h-auto`}
						title={t("admin.modify_option")}
						left={{ onPress: () => webBack(goBack), source: "close" }}
					/>
					<TouchableOpacity
						style={tw`flex-row items-center`}
						onPress={(x) => setForm({ ...form, active: !form?.active })}
					>
						<Checkbox checked={form?.active} />
						<Text style={tw`flex-1 text-base ml-5 py-1.5 font-semibold text-black`}>
							{t("admin.show_option_menu")}
						</Text>
					</TouchableOpacity>

					<YellowLine style={tw`my-5`} />

					<Text style={{ ...styles.header_lg, ...tw`mb-3` }}>{t("field.name")}</Text>
					<LangField onChange={setForm} value={form} field="name" />

					<YellowLine style={tw`my-5`} />

					<Text style={{ ...styles.header_lg, ...tw`mb-3` }}>{t("field.description")}</Text>
					<LangField onChange={setForm} value={form} field="description" />

					<YellowLine style={tw`my-5`} />

					<Text style={{ ...styles.header_lg, ...tw`mb-3` }}>{t("field.type")}</Text>
					<TouchableOpacity
						onPress={() => setForm({ ...form, type: 1 })}
						style={tw`flex-row justify-between items-center mb-3`}
					>
						<Text style={styles.header_me}>{t("field.single_choice")}</Text>
						<RadioButton selected={form.type === 1} onPress={() => setForm({ ...form, type: 1 })} />
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => setForm({ ...form, type: 2 })}
						style={tw`flex-row justify-between items-center mb-3`}
					>
						<Text style={styles.header_me}>{t("field.multiple_choice")}</Text>
						<RadioButton selected={form.type === 2} onPress={() => setForm({ ...form, type: 2 })} />
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => setForm({ ...form, type: 3 })}
						style={tw`flex-row justify-between items-center mb-3`}
					>
						<Text style={styles.header_me}>{t("field.extra_choice")}</Text>
						<RadioButton
							selected={form.type === 3}
							onPress={() => setForm({ ...form, type: 3, required: false })}
						/>
					</TouchableOpacity>

					<YellowLine style={tw`my-5`} />

					{[1, 2].includes(form?.type) && (
						<>
							<TouchableOpacity
								style={tw`flex-row items-center`}
								onPress={(x) => setForm({ ...form, required: !form?.required })}
							>
								<Checkbox checked={form?.required} />
								<Text style={tw`flex-1 text-base ml-5 py-1.5 font-semibold text-black`}>
									{t("admin.required_option")}
								</Text>
							</TouchableOpacity>
							<YellowLine style={tw`my-5`} />
						</>
					)}

					{form?.type === 2 && (
						<>
							<Text style={{ ...styles.header_lg, ...tw`mb-3` }}>{t("field.max_choices")}</Text>
							<InputContainer>
								<InputBase
									onChangeText={(t) => setForm({ ...form, max: t })}
									value={form?.max}
									inputMode="decimal"
									style={tw`py-2`}
								/>
							</InputContainer>
							<YellowLine style={tw`my-5`} />
						</>
					)}

					<View style={tw`flex-row items-center justify-between`}>
						<Text style={styles.header_lg}>{t("word.choices")}</Text>
						<EditButton
							label={t("admin.add_choice")}
							icon="plus"
							e={() =>
								setChoice({
									id: new Date().getTime(),
									active: true,
									name: {},
									price: {},
								})
							}
						/>
					</View>
					{form?.options?.map((opt, i) => (
						<OptionCard
							onPress={() => setChoice(opt)}
							key={`choice_${opt.id}`}
							style={tw`m-1 mb-3`}
							o={opt}
						>
							<BigButton
								icon="trash"
								iconColor={platform.colors.red}
								type="red-outline"
								style={tw`mr-2 px-2 py-2`}
								disabled={saving}
								onPress={() => onRemove(i)}
							/>
							<View>
								<BigButton
									icon="arrow-up"
									type="yellow-outline"
									style={tw`p-3 mb-1`}
									disabled={saving || i === 0}
									onPress={() => onMove(i, i - 1)}
								/>
								<BigButton
									icon="arrow-down"
									type="yellow-outline"
									style={tw`p-3 mt-1`}
									disabled={saving || i === form.options.length - 1}
									onPress={() => onMove(i, i + 1)}
								/>
							</View>
						</OptionCard>
					))}
				</View>
			</Screen>
			<View style={tw`bg-white flex-row justify-between border-gray-300 pb-5 pt-4 px-5 border-t`}>
				<BigButton
					style={tw`w-full`}
					disabled={saving || !en || !fr || !nl}
					onPress={() => onSubmit(form)}
				>
					{t("word.save")}
				</BigButton>
			</View>
			<Modal choice={choice} cancel={() => setChoice(null)} onSubmit={onChoice} saving={saving} />
		</>
	);
};

export default AdminOptionEditModule;
