import { track } from "@amplitude/analytics-react-native";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";
import * as WebBrowser from "expo-web-browser";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Image,
	ImageBackground,
	StatusBar,
	Text,
	TextInput,
	TouchableOpacity,
	View,
	useWindowDimensions,
} from "react-native";
import tw from "twrnc";
import { PlatformContext, UserContext } from "../../contexts";
import Screen from "../../modules/Screen";
import { authUserV2 } from "../../utils/db";
import { webBack } from "../../utils/others";
import { isDesktop, isIos, isWeb } from "../../utils/platform";
import { styles } from "../../utils/settings";
import ActionButton from "../ActionButton";
import BigButton from "../BigButton";
import HalfModal from "../HalfModal";
import RadioButton from "../RadioButton";
import SimpleHeader from "../SimpleHeader";

const countries = [
	{ code: "+32", country: "Belgium", flag: "🇧🇪" },
	{ code: "+31", country: "Netherlands", flag: "🇳🇱" },
	{ code: "+33", country: "France", flag: "🇫🇷" },
	{ code: "+352", country: "Luxembourg", flag: "🇱🇺" },
];

const formatPhone = (country, p) => {
	if (!p) return p;

	p = p.split("+32").join("");
	p = p.split("+31").join("");
	p = p.split("+33").join("");
	p = p.split("+352").join("");

	if (p[0] === "0") p = p.substr(1, 13);

	p = p.split(" ").join("");
	p = p.split("+").join("");
	p = p.split("-").join("");
	p = p.split("*").join("");
	p = p.split("#").join("");

	const len = p?.length;
	const phone = [p.substr(0, 3)];

	if (country === "+352") {
		if (len > 3) phone.push(p.substr(3, 3));
		if (len > 6) phone.push(p.substr(6, 3));
	} else {
		if (len > 3) phone.push(p.substr(3, 2));
		if (len > 5) phone.push(p.substr(5, 2));
		if (len > 7) phone.push(p.substr(7, 2));
	}

	return phone.join(" ").trim();
};

export const capitalizeName = (n) =>
	n
		.toLowerCase()
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");

export const InputContainer = ({ children, style }) => {
	const platform = useContext(PlatformContext);

	return (
		<View
			style={{
				...tw`flex-row w-full items-center justify-between mb-3 px-3 border rounded-xl`,
				borderColor: platform.colors.yellow_main,
				...style,
			}}
		>
			{children}
		</View>
	);
};

export const InputBase = ({ style, ...props }) => (
	<TextInput
		ref={props.refInner}
		autoCorrect={false}
		placeholderTextColor="#7a7a7a"
		style={{
			...tw`flex-1 text-xl py-3 pl-1 font-semibold h-full ${isIos ? "mb-2" : ""} text-white`,
			outline: isWeb ? "none" : undefined,
			...style,
		}}
		{...props}
	/>
);

const DEF_FORM = { country: "+32" };

const QuickAuth = ({ showBack }) => {
	const platform = useContext(PlatformContext);
	const { goBack } = useNavigation();
	const { signIn, lang } = useContext(UserContext);
	const otpRef = useRef();
	const firstField = useRef();
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(0);
	const [fields, setFields] = useState(null);
	const [form, setForm] = useState(DEF_FORM);
	const [error, setError] = useState(null);
	const [modal, setModal] = useState(false);
	const isFocused = useIsFocused();
	const { t } = useTranslation();
	const layout = useWindowDimensions();

	const oc = (f, v) => setForm({ ...form, [f]: v });

	const onSubmit = async () => {
		setLoading(true);

		const { data: result, error: raw_error } = await authUserV2({
			lang,
			...form,
			step,
		});

		if (!result || raw_error) {
			setLoading(false);
			return setError(raw_error);
		} else {
			setError(null);
		}

		if (step === 0) {
			setStep(1);
			setTimeout(() => otpRef.current.focus(), 150);
			setLoading(false);
			return;
		}

		if (step === 1 && result.newUser) {
			setFields(result.fields);
			setStep(2);
			setTimeout(() => firstField.current.focus(), 150);
			setLoading(false);
			return;
		}

		await signIn(result);
		if (showBack) return webBack(goBack);
	};

	const getSignInButton = () => {
		let disabled = true;
		let text = t("auth.receive_otp");

		if (step === 0) disabled = !form?.mobile || form?.mobile?.length < 11;
		if (step === 1) {
			text = t("word.confirm");
			disabled = !form?.otp || form?.otp?.length !== 6;
		}
		if (step === 2) {
			disabled = !form?.firstname || !form?.lastname || !form?.mobile || form?.mobile?.length < 11;
			text = t("word.confirm");
		}

		return (
			<BigButton
				type={"yellow"}
				style={tw`w-full`}
				onPress={onSubmit}
				disabled={loading || disabled}
			>
				{text}
			</BigButton>
		);
	};

	return (
		<Screen
			style={{
				...tw`${showBack ? "py-0" : ""} items-center pb-0`,
				...(isDesktop && {
					paddingLeft: layout.width / 2 - 250,
					paddingRight: layout.width / 2 - 250,
					paddingTop: 80,
					paddingBottom: 80,
					justifyContent: "center",
					minHeight: "100vh",
				}),
			}}
		>
			{isFocused && <StatusBar barStyle={"light-content"} />}
			{showBack && (
				<ActionButton
					source="close"
					style={{ position: "absolute", top: 35, right: 25 }}
					size={25}
					onPress={() => webBack(goBack)}
				/>
			)}
			{!showBack && step > 0 ? (
				<ActionButton
					style={{ position: "absolute", top: 35, left: 25 }}
					size={25}
					onPress={() => {
						setStep(0);
						setForm({ country: form.country });
					}}
				/>
			) : null}
			{!isDesktop && (
				<Text style={{ color: "black", fontFamily: "FarsonSolid", fontSize: 40, ...tw`mt-10` }}>
					{platform.name.toUpperCase()}
				</Text>
			)}
			<View style={tw`py-15 px-3`}>
				<Text style={tw`text-center text-black text-3xl font-bold`}>
					{[0].includes(step) && t("auth.sign_in")}
					{[1].includes(step) && t("auth.sign_in_otp")}
					{[2].includes(step) && t("auth.sign_in_info")}
				</Text>
				<Text style={tw`text-center text-black text-lg font-extralight`}>
					{[0].includes(step) && t(`auth.slogan.${platform.vertical}`)}
					{[1].includes(step) &&
						t("auth.sign_in_otp_desc", { country: form?.country, mobile: form?.mobile })}
					{[2].includes(step) && t("auth.sign_in_info_desc")}
				</Text>
			</View>
			<View style={tw`px-3 w-full flex-col items-center justify-center`}>
				{error && <Text style={tw`text-red-400 mb-3 text-base`}>{t(`error.${error}`)}</Text>}
				{[2].includes(step) &&
					fields.map((f, i) => (
						<InputContainer key={`field_${f}_${i}`}>
							<InputBase
								refInner={i === 0 ? firstField : null}
								maxLength={20}
								placeholder={t(`field.${f}`)}
								onChangeText={(v) => oc(f, capitalizeName(v))}
								value={form?.[f]}
								style={tw`text-black`}
							/>
						</InputContainer>
					))}
				{[0].includes(step) && (
					<InputContainer>
						<TouchableOpacity onPress={() => setModal(true)} style={tw`py-4 w-16`}>
							<Text
								style={{
									...tw`text-xl font-semibold text-center`,
									color: platform.colors.yellow_main,
								}}
							>
								{form.country}
							</Text>
						</TouchableOpacity>
						<InputBase
							placeholder={t("field.mobile")}
							onChangeText={(v) => oc("mobile", formatPhone(form.country, v))}
							value={form?.mobile}
							inputMode="tel"
							style={tw`text-black`}
						/>
					</InputContainer>
				)}
				{[1].includes(step) && (
					<InputContainer>
						<TouchableOpacity style={tw`py-4 w-16`}>
							<Text
								style={{
									...tw`text-xl font-semibold`,
									color: platform.colors.yellow_main,
								}}
							>
								CODE
							</Text>
						</TouchableOpacity>
						<InputBase
							refInner={otpRef}
							maxLength={6}
							inputMode="numeric"
							onChangeText={(v) => oc("otp", v)}
							value={form?.otp}
							style={tw`text-black`}
						/>
					</InputContainer>
				)}
				{getSignInButton()}
			</View>
			<TouchableOpacity
				onPress={() => {
					track("CLICK_TERMS", { vertical: platform.vertical });

					if (isWeb) window.open(`https://${platform.domain}/${lang}/terms`);
					else WebBrowser.openBrowserAsync(`https://${platform.domain}/${lang}/terms`);
				}}
			>
				<Text style={tw`text-center text-black mx-10 font-light my-5`}>
					{t("auth.accept_terms")}
				</Text>
			</TouchableOpacity>
			{!isDesktop && (
				<ImageBackground
					style={{
						height: 150,
						width: "100%",
					}}
					source={
						platform.isPizzy
							? { uri: "https://fritzy.be/frites_2_pizzy.png" }
							: require("../../../assets/frites_2.png")
					}
					resizeMode="contain"
				/>
			)}
			<View style={tw`px-3 w-full`}>
				<View
					style={tw`bg-[#f9f7f5] border-[${platform.colors.yellow_main}] border border-dashed w-full rounded-3xl my-5 flex-col items-center justify-center p-10`}
				>
					<Text style={styles.header_lg}>{t(`auth.sign_in_also.${platform.vertical}`)}</Text>
					<Text style={styles.desc}>{t("auth.sign_in_also_desc")}</Text>
					<TouchableOpacity
						onPress={() => {
							track("CLICK_OTHER_VERTICAL", { vertical: platform.isPizzy ? "FRITZY" : "PIZZY" });

							if (isWeb)
								window.open(`https://${platform.isPizzy ? "app.fritzy.be" : "app.pizzy.be"}`);
							else
								Linking.openURL(`https://${platform.isPizzy ? "app.fritzy.be" : "app.pizzy.be"}`);
						}}
						style={tw`flex-row items-center mt-5`}
					>
						<Image
							source={platform.reverseLogo}
							style={{ height: 55, width: 55, borderRadius: 15 }}
						/>
						<Text style={{ ...tw`ml-3 text-2xl text-black font-bold`, fontFamily: "FarsonSolid" }}>
							{platform.isPizzy ? "FRITZY" : "PIZZY"}
						</Text>
					</TouchableOpacity>
				</View>
			</View>

			<HalfModal visible={modal}>
				<SimpleHeader hideLeft style={tw`mt-0 p-0 mb-5 h-auto`} title={t("word.country")} />
				{countries.map((l) => (
					<TouchableOpacity
						onPress={() => oc("country", l.code)}
						key={`lang_${l.code}`}
						style={tw`flex-row justify-between items-center mb-5`}
					>
						<Text style={styles.header_lg}>
							{l.flag} {l.code}
							{"   "} {t(`country.${l.country.toLowerCase()}`)}
						</Text>
						<RadioButton selected={form.country === l.code} onPress={() => oc("country", l.code)} />
					</TouchableOpacity>
				))}
				<View style={tw`w-full mt-5`}>
					<BigButton
						onPress={() => [setModal(false), oc("mobile", formatPhone(form.country, form.mobile))]}
					>
						{t("word.confirm")}
					</BigButton>
				</View>
			</HalfModal>
		</Screen>
	);
};

export default QuickAuth;
