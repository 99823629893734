import { View } from "react-native";
import tw from "twrnc";

const Dot = ({ style, color, size = 10, mr = 10, children }) => (
	<View style={{ ...tw`flex-row items-center`, ...style }}>
		<View
			style={{
				width: size,
				height: size,
				backgroundColor: color,
				borderRadius: size,
				marginRight: mr,
			}}
		/>
		{children}
	</View>
);

export default Dot;
