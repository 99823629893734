import settings from "./settings";

const lists = {
	order_status: {
		1: "awaiting_payment_customer",
		2: "awaiting_confirmation_restaurant",
		3: "refused_by_restaurant",
		4: "canceled_by_customer",
		5: "order_accepted",
		6: "order_picked_up",
	},
	order_status_color: {
		1: "blue",
		2: "orange",
		3: settings.colors.red,
		4: settings.colors.red,
		5: settings.colors.green,
		6: settings.colors.yellow_main,
	},
};

export default lists;
