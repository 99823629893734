import { ImageBackground } from "react-native";

const payment_methods = {
	bancontact: require("../../../assets/payments/bancontact.png"),
	visa: require("../../../assets/payments/visa.png"),
	vpay: require("../../../assets/payments/vpay.png"),
	maestro: require("../../../assets/payments/maestro.png"),
	mastercard: require("../../../assets/payments/mastercard.png"),
	belfius: require("../../../assets/payments/belfius.png"),
	kbc: require("../../../assets/payments/kbc.png"),
	revolut: require("../../../assets/payments/revolut.png"),
	creditcard: require("../../../assets/payments/creditcard.png"),
	applepay: require("../../../assets/payments/applepay.png"),
	amex: require("../../../assets/payments/amex.png"),
	paypal: require("../../../assets/payments/paypal.png"),
	cash: require("../../../assets/payments/cash.png"),
};

const PaymentMethod = ({ method, style }) =>
	!method ? null : (
		<ImageBackground
			style={{ width: 75, height: 40, ...style }}
			resizeMode="contain"
			source={payment_methods[method.toLowerCase()]}
		/>
	);

export default PaymentMethod;
