import LottieView from "lottie-react-native";
import { useContext } from "react";
import { View } from "react-native";
import tw from "twrnc";
import { PlatformContext } from "../../contexts";

const Loading = ({ platform: forcePlatform }) => {
	const platform = useContext(PlatformContext);

	return (
		<View style={tw`flex-1 flex-col items-center justify-center`}>
			<LottieView
				autoPlay
				style={{
					width: 50,
					height: 50,
				}}
				source={
					(platform || forcePlatform).isPizzy
						? require("../../../assets/animations/loading-pizzy.json")
						: require("../../../assets/animations/loading.json")
				}
			/>
		</View>
	);
};

export default Loading;
