import { track } from "@amplitude/analytics-react-native";
import { useContext } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import { PlatformContext } from "../../contexts";
import { isDesktop } from "../../utils/platform";
import { TransProp } from "../Trans";

const CategoryButton = ({ onPress, forceSelected, category, c }) => {
	const platform = useContext(PlatformContext);
	const mainColor = platform.colors.yellow_main;

	return (
		<TouchableOpacity
			onPress={onPress}
			style={tw`px-3 mr-2 rounded-xl border h-6 flex-col items-center justify-center border-[${mainColor}] ${
				forceSelected || category === c.id ? `bg-[${mainColor}]` : ""
			}`}
		>
			<Text
				style={tw`uppercase text-xs font-medium ${
					forceSelected || category === c.id
						? platform.isPizzy
							? `text-white`
							: `text-black`
						: `text-[${platform.colors.gray_dark}]`
				}`}
			>
				<TransProp v={c.name} />
			</Text>
		</TouchableOpacity>
	);
};

const CategoryFilter = ({ categories, style, category, setCategory }) => {
	return (
		<View>
			<ScrollView
				horizontal
				showsHorizontalScrollIndicator={false}
				style={{
					...tw`pt-3 ${isDesktop ? "pb-5" : "pb-3"}`,
					marginHorizontal: isDesktop ? 0 : -15,
					...style,
				}}
				contentContainerStyle={{
					paddingHorizontal: isDesktop ? 0 : 15,
				}}
			>
				<CategoryButton
					onPress={() => setCategory(null)}
					category={null}
					forceSelected={!category}
					c={{ name: { en: "All", nl: "Alles", fr: "Tout" } }}
				/>
				{categories.map((c) => (
					<CategoryButton
						onPress={() => {
							setCategory(c.id);
							track("MENU_USE_CATEGORIES");
						}}
						key={`cat_${c.id}`}
						category={category}
						c={c}
					/>
				))}
			</ScrollView>
		</View>
	);
};

export default CategoryFilter;
