import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import { googleHoursToOurs } from "../../utils/days";

const API_KEY = "AIzaSyC35HgTetUHkPn3CE8gcFrrdrs-1PJsjr8";

const AddressSearch = ({
	placeholder,
	onChange,
	inputStyle,
	inputContainerStyle,
	resultsContainerStyle,
	onFocus,
	onBlur,
}) => {
	return (
		<GooglePlacesAutocomplete
			placeholder={placeholder}
			isRowScrollable={false}
			enablePoweredByContainer={false}
			onPress={(data, details = null) => {
				try {
					const search = (type) => {
						return details.address_components.find((x) => x.types.indexOf(type) > -1) || {};
					};

					const city = search("locality").long_name;
					const postcode = search("postal_code").long_name;
					const street = search("route").long_name;
					const country = search("country").short_name;
					const province = search("administrative_area_level_2").short_name;
					const street_number = search("street_number").long_name || "";

					onChange({
						name: details.name,
						mobile: details.international_phone_number?.split(" ").join(""),
						url: details.url,
						days: googleHoursToOurs(details?.current_opening_hours?.periods),

						place_id: details.place_id,
						lng: details.geometry.location.lng,
						lat: details.geometry.location.lat,

						address: details.vicinity,
						city,
						postcode,
						street,
						street_number,
						country,
						province,
					});
				} catch (ex) {
					return null;
				}
			}}
			fetchDetails
			query={{
				key: API_KEY,
				language: "en",
				components: "country:be",
			}}
			requestUrl={{
				useOnPlatform: "web",
				url: "https://proxy.cors.sh/https://maps.googleapis.com/maps/api",
				headers: {
					"x-cors-api-key": `live_e6b20b267f71cd2b9a4d29b8b5ebf51af9b02fc86f6b02f97b835aa76f3f8867`,
				},
			}}
			textInputProps={{
				style: inputStyle,
				autoCorrect: false,
				onFocus,
				onBlur,
			}}
			styles={{
				textInputContainer: inputContainerStyle,
				listView: resultsContainerStyle,
			}}
		/>
	);
};

export default AddressSearch;
