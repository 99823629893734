import { DateTime } from "luxon";
import ThermalPrinterModule from "react-native-thermal-printer";
import { getAnyTrans } from "./others";
import { isAndroid } from "./platform";
import { getProductPriceWithExtras } from "./products";

const template_order = [
	"[L]",
	"[C]<img>$LOGO$</img>",
	"[L]",
	"[C]<font size='big'>$ORDER_TITLE$ #$REF$</font>",
	"[C]<b>$LOCATION$ $COMMENT$</b>",
	"[C]<b><font size='tall'>$TIME$</font></b>",
	"[L]",
	"[C]<font size='tall'>$CUSTOMER$ $CUSTOMER_MOBILE$</font>",
	"[L]",
	"[C]================================",
	"[L]",
	"$ITEMS$",
	"[C]================================",
	"[L]",
	"[L]<b>$TOTAL$</b>",
	"[L]",
	"[C]$DATE$",
	"[C]www.$VERTICAL$.be",
];

const template_order_report = [
	"[L]",
	"[C]<font size='big'>ORDERS</font>",
	"[L]",
	"$ITEMS$",
	"[L]",
	"[L]<b>$TOTAL$</b>",
	"[L]",
];

const translations = {
	en: {
		pickup: "PICK-UP",
		delivery: "DELIVERY",
		deliver_to: "DELIVER TO:",
		order: "ORDER",
		online_payment: "ONLINE PAYMENT",
		cash_payment: "PAY AT COUNTER",
	},
	fr: {
		pickup: "A EMPORTER",
		delivery: "LIVRAISON",
		deliver_to: "LIVRER A:",
		order: "COMMANDE",
		online_payment: "DEJA PAYE EN LIGNE",
		cash_payment: "A PAYER SUR PLACE",
	},
	nl: {
		pickup: "TAKEAWAY",
		delivery: "BEZORGEN",
		deliver_to: "BEZORGEN TE:",
		order: "BESTELLING",
		online_payment: "ONLINE BETAALD",
		cash_payment: "TE BETALEN AAN KASSA",
	},
};

export const printOrder = async (order, readyIn = 0, platform, lang = "en") => {
	if (!isAndroid) return null;

	const template = [...platform.get("print_template_v4", template_order)];
	const d = DateTime.fromISO(order.pickup_at).plus({ minutes: readyIn });

	const items = [];
	order.items.forEach((i) => {
		items.push(
			`[L]<b>${i.quantity} ${getAnyTrans(
				i.product.name,
				lang
			)?.toUpperCase()}</b>[R]${getProductPriceWithExtras(i.product, i.quantity, i.extras)}e`
		);
		if (i.comment) items.push(`[L]  <b>Comment: ${i.comment}</b>`);

		i.extras.forEach((e) => {
			items.push(`[L]  <b>${e.qty || 1} ${getAnyTrans(e.selection.name, lang)?.toUpperCase()}</b>`);
		});
		items.push("[L]");
	});

	if (order.delivery) {
		template.push("[L]");
		template.push(`[C]<b>${translations[lang].deliver_to}</b>`);
		template.push(
			`[C]<b>${order.delivery.street} ${order.delivery.street_number}</b>`.toUpperCase()
		);
		if (order.delivery.comment) template.push(`[C]<b>${order.delivery.comment}</b>`.toUpperCase());
		template.push(
			`[C]<b>${order.delivery.postcode} ${order.delivery.city} (${order.delivery.country})</b>`.toUpperCase()
		);
		template.push("[L]");
		template.push(`[C]<qrcode size='18'>${order.delivery.url}</qrcode>`);
	}

	let payload = template
		.join("\n")
		.replace("$REF$", order.reference)
		.replace(
			"$LOCATION$",
			order.eat_in
				? getAnyTrans(order.eat_in.name, lang)?.toUpperCase()
				: order.delivery
				? translations[lang].delivery
				: translations[lang].pickup
		)
		.replace("$CUSTOMER_MOBILE$", order.customer_mobile)
		.replace("$ITEMS$", items.join("\n"));

	if (order.payment_type === 1) {
		payload = payload.replace(
			"$PAYMENT$",
			`<font size='tall'><b>${translations[lang].cash_payment}</b></font>`
		);
	} else {
		payload = payload.replace("$PAYMENT$", `<b>${translations[lang].online_payment}</b>`);
	}

	payload = payload.replace(
		"$COMMENT$",
		order.eat_in_comment ? `[C]<font size='tall'>${order.eat_in_comment}</font>` : ""
	);
	payload = payload.replace("$ORDER_TITLE$", translations[lang].order);
	payload = payload.replace("$TIME$", d.toFormat("HH:mm"));
	payload = payload.replace("$DATE$", d.toFormat("dd-MM-yyyy"));
	payload = payload.replace("$CUSTOMER$", `${order.customer_firstname?.toUpperCase()}`);
	payload = payload.replace("$TOTAL$", `TOTAL</b>[R]<b>${order.amount_order}e`);
	payload = payload.replace("$VERTICAL$", platform.vertical);
	payload = payload.replace("$VAT$", "BE0723.470.144");
	payload = payload.replace(
		"$LOGO$",
		`https://api.fritzy.be/public/logo_print_${platform.vertical}.jpeg`
	);

	printTicket(payload);
};

export const printOrderReport = async (data) => {
	if (!isAndroid) return null;

	const template = template_order_report;

	const items = [];
	data.days.forEach((d) => {
		items.push(
			`[L]<b>${DateTime.fromISO(d.date).toFormat("dd/MM/yyyy")} (${d.nr_orders})</b>[R]${
				d.total_restaurant
			}e`
		);
	});

	let payload = template.join("\n").replace("$ITEMS$", items.join("\n"));

	payload = payload.replace("$TOTAL$", `TOTAL</b>[R]<b>${data.total_restaurant_sum}e`);

	printTicket(payload);
};

const printTicket = async (payload) => {
	try {
		const devices = await ThermalPrinterModule.getBluetoothDeviceList();
		ThermalPrinterModule.printBluetooth({
			macAddress: devices?.[0]?.macAddress,
			payload: payload.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
			printerNbrCharactersPerLine: 32,
			printerWidthMM: 50,
			autoCut: false,
		});
	} catch (err) {}
};
