import { useNavigation } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../components/BigButton";
import QuickAuth from "../../components/QuickAuth";
import SimpleHeader from "../../components/SimpleHeader";
import YellowLine from "../../components/YellowLine";
import { PlatformContext, UserContext } from "../../contexts";
import { isWeb } from "../../utils/platform";
import settings, { VERTICALS, styles } from "../../utils/settings";
import Screen from "../Screen";

export const SelectLang = ({ type }) => {
	const { lang, changeLang } = useContext(UserContext);

	return (
		<View style={tw`w-full flex-row mb-3`}>
			{[
				{ lang: "en", flag: "🇬🇧" },
				{ lang: "fr", flag: "🇫🇷" },
				{ lang: "nl", flag: "🇳🇱" },
			].map((l) => (
				<BigButton
					key={`lang_pick_${l.lang}`}
					onPress={() => changeLang(l.lang, true)}
					type={
						type === "outline"
							? lang !== l.lang
								? "dark-outline"
								: "yellow"
							: lang !== l.lang
							? "outline"
							: "yellow"
					}
					style={tw`flex-1 mr-1 py-2`}
					textStyle={{ fontSize: 20 }}
				>
					{l.flag}
				</BigButton>
			))}
		</View>
	);
};

export const openUrlFn = (url) => () => isWeb ? window.open(url) : WebBrowser.openBrowserAsync(url);

const AuthModule = () => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();
	const { user, lang, signOut, myRestaurants, restaurantView, toggleRestaurantView } =
		useContext(UserContext);
	const { navigate } = useNavigation();

	const toggleVertical = () => {
		platform.setVertical(platform.isPizzy ? VERTICALS.fritzy : VERTICALS.pizzy);
	};

	const buttons = [
		{
			title: t(`account.favorite_restaurants.${platform.vertical}`),
			action: () => navigate("favorite-restaurants"),
		},
		{ title: t("account.my_information"), action: () => navigate("account") },
	];

	const links = [
		myRestaurants
			? {
					title: t(`route.my_restaurants.${platform.vertical}`),
					items: [
						{
							title: t(
								restaurantView
									? "admin.customer_view"
									: `admin.restaurant_view.${platform.vertical}`
							),
							action: toggleRestaurantView,
						},
						myRestaurants?.filter((x) => x.vertical === "pizzy")?.length > 0
							? {
									title: t(`admin.activate_vertical_${platform.isPizzy ? "fritzy" : "pizzy"}`),
									action: toggleVertical,
							  }
							: null,
					],
			  }
			: null,
		{
			title: t("account.general_information"),
			items: [
				{
					title: t("account.help"),
					action: openUrlFn(`https://${platform.domain}/${lang}/contact`),
				},
				{
					title: t("account.terms"),
					action: openUrlFn(`https://${platform.domain}/${lang}/terms`),
				},
				{
					title: t("account.privacy"),
					action: openUrlFn(`https://${platform.domain}/${lang}/privacy`),
				},
			],
		},
		{
			title: t(`account.about.${platform.vertical}`),
			items: [
				{
					title: t("account.about"),
					action: openUrlFn(`https://${platform.domain}/${lang}/pro`),
				},
			],
		},
	].filter((x) => x);

	if (user)
		return (
			<Screen backgroundColor="white" style={tw`mt-0 pt-0 px-3`}>
				<SimpleHeader title={t("route.account")} />
				<SelectLang />
				<YellowLine style={tw`mt-2 mb-5`} />
				<View style={tw`flex-col items-start pb-15`}>
					{buttons.map((b, i) => (
						<View key={`button_${i}`} style={tw`w-full`}>
							<TouchableOpacity onPress={b.action}>
								<Text style={styles.header_lg}>{b.title}</Text>
							</TouchableOpacity>
							<YellowLine style={tw`my-5`} />
						</View>
					))}
					{links.map((l, i) => (
						<View
							key={`links_${i}`}
							style={tw`py-8 w-full ${i + 1 < links.length ? "border-b border-gray-300" : ""}`}
						>
							<Text style={{ ...styles.yellow_lg, color: platform.colors.yellow_main }}>
								{l.title}
							</Text>
							{l.items
								.filter((x) => x)
								.map((b, c) => (
									<TouchableOpacity onPress={b.action} style={tw`mt-2`} key={`link_${i}_${c}`}>
										<Text style={styles.light_lg}>{b.title}</Text>
									</TouchableOpacity>
								))}
						</View>
					))}
					<BigButton
						onPress={signOut}
						type="outline"
						style={tw`w-full`}
						textStyle={tw`${platform.isPizzy ? `text-[${platform.colors.yellow_main}]` : ""}`}
					>
						{t("actions.logout")}
					</BigButton>
					<View style={tw`flex-col items-center w-full justify-center`}>
						<Text style={{ ...styles.desc, ...tw`mt-5` }}>{settings.version}</Text>
					</View>
				</View>
			</Screen>
		);

	return <QuickAuth />;
};

export default AuthModule;
