import { useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";

import { useTranslation } from "react-i18next";
import Loading from "../../../components/Loading";
import SimpleHeader from "../../../components/SimpleHeader";
import { PlatformContext } from "../../../contexts";
import useApi from "../../../hooks/useApi";
import { getRestaurant, updateRestaurant } from "../../../utils/db";
import { webBack } from "../../../utils/others";
import { styles } from "../../../utils/settings";
import { InputBase, InputContainer } from "../CategoriesModule/AdminCategoriesModule";

const AdminInvoicingModule = () => {
	const platform = useContext(PlatformContext);
	const { goBack } = useNavigation();
	const [form, setForm] = useState({});
	const [saving, setSaving] = useState(false);
	const { t } = useTranslation();
	const {
		params: { restaurant },
	} = useRoute();
	const { data, loading } = useApi(getRestaurant, { id: restaurant, edit: true });

	useEffect(() => {
		setForm(data?.invoicing || {});
	}, [data]);

	const onSubmit = async () => {
		setSaving(true);

		await updateRestaurant(restaurant, { ...data, invoicing: form });

		webBack(goBack);
	};

	return (
		<View style={{ flex: 1, ...tw`bg-white` }}>
			<SimpleHeader
				left={{ onPress: () => webBack(goBack) }}
				title={t(`admin.my_restaurant.${platform.vertical}`)}
			/>
			{loading && <Loading />}
			{!loading && (
				<ScrollView style={tw`p-3`} contentContainerStyle={tw`pb-20`}>
					<Text style={{ ...styles.header_lg, ...tw`mb-3` }}>{t("field.name")}</Text>
					<InputContainer style={tw`p-2`}>
						<InputBase onChangeText={(t) => setForm({ ...form, name: t })} value={form.name} />
					</InputContainer>
					<Text style={{ ...styles.header_lg, ...tw`mb-3` }}>{t("field.vat")}</Text>
					<InputContainer style={tw`p-2`}>
						<InputBase onChangeText={(t) => setForm({ ...form, vat: t })} value={form.vat} />
					</InputContainer>
					<Text style={{ ...styles.header_lg, ...tw`mb-3` }}>{t("field.address")}</Text>
					<InputContainer style={tw`p-2`}>
						<InputBase
							onChangeText={(t) => setForm({ ...form, address: t })}
							value={form.address}
						/>
					</InputContainer>
					<Text style={{ ...styles.header_lg, ...tw`mb-3` }}>{t("field.address2")}</Text>
					<InputContainer style={tw`p-2`}>
						<InputBase
							onChangeText={(t) => setForm({ ...form, address2: t })}
							value={form.address2}
						/>
					</InputContainer>
				</ScrollView>
			)}
			<View style={tw`bg-white border-gray-300 pb-5 pt-4 px-5 border-t`}>
				<BigButton onPress={onSubmit} disabled={saving}>
					{t("word.save")}
				</BigButton>
			</View>
		</View>
	);
};

export default AdminInvoicingModule;
