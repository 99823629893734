import { DateTime } from "luxon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ImageBackground, Text, View } from "react-native";
import tw from "twrnc";
import Card from "../../components/Card";
import { TransProp } from "../../components/Trans";
import { PlatformContext, UserContext } from "../../contexts";
import { getFile } from "../../utils/db";
import lists from "../../utils/lists";
import { styles } from "../../utils/settings";
import Dot from "../Dot";

const OrderCard = ({ onPress, order, style }) => {
	const platform = useContext(PlatformContext);
	const { lang } = useContext(UserContext);
	const { t } = useTranslation();

	return (
		<Card onPress={onPress} style={{ ...tw`p-0 mb-5`, ...style }}>
			<ImageBackground
				resizeMode="cover"
				source={
					!order.restaurant.image_cover
						? platform.appLogo
						: { uri: getFile(order.restaurant.image_cover, 500, 70) }
				}
				defaultSource={platform.appLogo}
				style={{ height: 175, width: "100%" }}
			/>
			<View style={tw`p-4`}>
				<View style={tw`flex-row justify-between items-center`}>
					<Text style={styles.header_2xl}>
						<TransProp v={order.restaurant.name} />{" "}
					</Text>
					<Text style={styles.header_extralight}>#{order.reference}</Text>
				</View>
				<Text style={{ ...styles.desc_s, ...tw`mt-1` }}>
					{t("order.ordered_on", {
						time: DateTime.fromISO(order.created_at).toFormat(`d MMM '${t("time.at")}' HH:mm`, {
							locale: lang,
						}),
					})}
				</Text>
				<Dot color={lists.order_status_color[order.status]} style={{ paddingLeft: 1.5, ...tw`` }}>
					<Text style={styles.desc_s}>{t(`order.status.${lists.order_status[order.status]}`)}</Text>
				</Dot>
			</View>
		</Card>
	);
};

export default OrderCard;
