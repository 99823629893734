import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, ImageBackground, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import arrow from "../../../assets/flags/arrow.png";
import enFlag from "../../../assets/flags/en.png";
import frFlag from "../../../assets/flags/fr.png";
import nlFlag from "../../../assets/flags/nl.png";
import { PlatformContext, UserContext } from "../../contexts";
import { languages } from "../../modules/Admin/CategoriesModule/AdminCategoriesModule";
import { isIos } from "../../utils/platform";

const flags = {
	fr: frFlag,
	nl: nlFlag,
	en: enFlag,
};

export const LanguageBubbles = ({ style, size = 30 }) => {
	const [open, setOpen] = useState(false);
	const { lang, changeLang } = useContext(UserContext);

	const Flag = ({ l, isLast, op, shadow = false, style, children }) => (
		<TouchableOpacity
			onPress={op}
			style={{
				borderColor: "white",
				zIndex: 1,
				borderWidth: 4,
				padding: 0,
				borderRadius: size,
				marginBottom: isLast ? 0 : 2,
				position: "relative",
				...(shadow ? tw`shadow-md` : {}),
				...style,
			}}
		>
			<Image
				source={flags[l]}
				resizeMethod="resize"
				style={{
					height: size,
					width: size,
					margin: -3,
				}}
			/>
			{children}
		</TouchableOpacity>
	);

	const langs = languages.filter((x) => x.lang !== lang);

	return (
		<View
			style={{
				backgroundColor: "white",
				borderRadius: size,
				position: "absolute",
				top: isIos ? 50 : 15,
				right: 20,
				...style,
			}}
		>
			<Flag style={{ zIndex: 2 }} isLast={!open} op={() => setOpen(!open)} shadow l={lang}>
				<Image
					source={arrow}
					style={{
						height: 12,
						width: 12,
						position: "absolute",
						bottom: -5,
						right: -5,
						transform: !open ? [{ rotateZ: "180deg" }] : [],
					}}
				/>
			</Flag>
			{open && (
				<>
					<Flag l={langs[0].lang} op={() => [setOpen(false), changeLang(langs[0].lang, true)]} />
					<Flag
						isLast
						l={langs[1].lang}
						op={() => [setOpen(false), changeLang(langs[1].lang, true)]}
					/>
				</>
			)}
		</View>
	);
};

const LogoHeader = ({ children }) => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();

	return (
		<ImageBackground
			style={{
				...tw`text-xl font-bold items-center justify-center flex-col w-full`,
				minHeight: isIos ? 200 : 150,
				backgroundColor: platform.colors.background_dark,
			}}
			source={
				platform.isPizzy
					? { uri: "https://fritzy.be/pizzy-bg.png" }
					: require("../../../assets/frites.png")
			}
			resizeMode={platform.isPizzy ? "cover" : "contain"}
		>
			<LanguageBubbles />
			<Text style={{ color: "white", fontFamily: "FarsonSolid", fontSize: 40 }}>
				{platform.name}
			</Text>
			<Text style={{ color: "white", fontFamily: "Autoguard", fontSize: 20, marginTop: 5 }}>
				{t(`home.slogan.${platform.vertical}`)}
			</Text>
			{children}
		</ImageBackground>
	);
};

export default LogoHeader;
