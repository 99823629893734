import Icon from "@expo/vector-icons/Entypo";
import Icon2 from "@expo/vector-icons/FontAwesome5";
import Icon3 from "@expo/vector-icons/MaterialIcons";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ImageBackground, Text, View } from "react-native";
import tw from "twrnc";
import { PlatformContext } from "../../contexts";
import { getNextOpenHour } from "../../utils/days";
import { getFile } from "../../utils/db";
import { intTime } from "../../utils/others";
import { isDesktop } from "../../utils/platform";
import settings, { styles } from "../../utils/settings";
import ActionButton from "../ActionButton";
import Card from "../Card";
import Dot from "../Dot";
import { TransProp } from "../Trans";

export const RestaurantPromo = ({ promo, style, isPizzy }) => {
	const platform = useContext(PlatformContext);

	return (
		<View
			style={{
				...tw`px-3 py-1 absolute ${!style ? "bottom-3 right--7 pr-10" : ""} rounded-full`,
				backgroundColor: isPizzy ? platform.colors.red : platform.colors.yellow_main,
				...style,
			}}
		>
			<Text style={tw`font-medium ${isPizzy ? "text-white" : ""}`}>
				<TransProp v={promo} />
			</Text>
		</View>
	);
};

export const VerticalSeperator = () => {
	const platform = useContext(PlatformContext);

	return (
		<View
			style={{
				...tw`mx-3`,
				backgroundColor: platform.colors.gray_light,
				height: 15,
				width: 1,
			}}
		></View>
	);
};

const RestaurantCard = ({
	width = "100%",
	showBottomPad = true,
	restaurant: d,
	cardHeight = 240,
	onPress,
	onCancel,
	style,
}) => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();

	const first_avail = d?.avails_times?.[0];
	const nextOpen = getNextOpenHour(d?.days);

	return (
		<Card
			style={{
				...tw`${showBottomPad ? "mb-5" : ""} p-0`,
				width,
				height: cardHeight,
				...style,
			}}
			onPress={onPress}
			key={`restaurant-${d.id}`}
		>
			<View style={tw`flex flex-col justify-between h-full items-center`}>
				<ImageBackground
					defaultSource={platform.appLogo}
					resizeMode="cover"
					style={{
						height: cardHeight / 1.65,
						opacity: d.avails_first_in ? 1 : 0.4,
						...tw`w-full`,
					}}
					source={!d.image_cover ? platform.appLogo : { uri: getFile(d.image_cover, 500, 70) }}
				>
					{d.promo && <RestaurantPromo isPizzy={platform.isPizzy} promo={d.promo} />}
					{onCancel && <ActionButton style={tw`m-5`} source="close" onPress={onCancel} />}
				</ImageBackground>

				<View
					style={{
						...tw`flex-col w-full flex-grow justify-center px-4`,
					}}
				>
					<View style={tw`flex-row items-center justify-between mb-3`}>
						<Text numberOfLines={1} style={{ ...styles.header_xl }}>
							<TransProp v={d.name} />
						</Text>
						{d.minimum_amount_order > 0 && (
							<View style={{ ...tw`flex-row items-center justify-end`, width: 100 }}>
								<View
									style={{
										...tw`flex-col items-center justify-center mr-2`,
										backgroundColor: platform.colors.red,
										borderRadius: 15,
										height: 15,
										width: 15,
									}}
								>
									<Icon2 name="euro-sign" color="white" size={10} />
								</View>
								<Text
									style={{ ...styles.desc_s, maxWidth: isDesktop ? "90%" : "70%" }}
									numberOfLines={1}
								>
									min. {d.minimum_amount_order}€
								</Text>
							</View>
						)}
					</View>
					<View style={tw``}>
						<View style={tw`flex-row items-center justify-between`}>
							<Dot
								color={
									d.avails_first_in > 60
										? platform.colors.orange
										: d.avails_first_in
										? platform.colors.green
										: platform.colors.red
								}
								style={tw`pr-2`}
							>
								<Text style={styles.desc_s}>
									{d.avails_first_in
										? d.avails_first_in > 60
											? t(`restaurant.avail_at`, {
													time: intTime(first_avail),
											  })
											: t(`restaurant.avail_in`, {
													minutes: d.avails_first_in,
											  })
										: nextOpen
										? t("restaurant.opens_on", {
												day: t(`day.${settings.days[nextOpen.day - 1]}`).toLowerCase(),
												time: intTime(nextOpen.hour),
										  })
										: t("restaurant.not_avail_now")}
								</Text>
							</Dot>
							{d.delivery?.enabled && (
								<View style={tw`flex-row items-center`}>
									<Icon3 name="delivery-dining" color={platform.colors.red} size={20} />
									<Text style={{ ...styles.desc_s, marginLeft: 5 }} numberOfLines={1}>
										{d.delivery?.price ? `${d.delivery.price}€` : t("word.free")}
									</Text>
								</View>
							)}
							<Text style={{ ...styles.desc_s }} numberOfLines={1}>
								<Icon name="location-pin" color={platform.colors.red} size={17.5} />
								{"  "}
								{Math.round(d.distance || d.user_distance)} km
							</Text>
						</View>
					</View>
				</View>
			</View>
		</Card>
	);
};

export default RestaurantCard;
