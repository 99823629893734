import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

const useApi = (fetcher, params, reloader = [], defData = null) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(defData);
	const [error, setError] = useState(null);

	const load = useDebouncedCallback(async (silent = false) => {
		setLoading(!silent);

		const result = await fetcher(params);
		if (result.error) {
			setError(result.error);
		} else {
			setData(result.data);
		}

		setLoading(false);

		return true;
	}, 50);

	useEffect(() => {
		load();
	}, reloader);

	return { loading, data, load, error, setData };
};

export default useApi;
