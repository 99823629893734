import { Modal, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import tw from "twrnc";
import { isDesktop, isIos } from "../../utils/platform";
import DesktopModal from "../DesktopModal";

const HalfModal = ({
	contentContainerStyle,
	animationType = "slide",
	children,
	innerViewStyle,
	visible,
}) => {
	if (isDesktop)
		return (
			<DesktopModal style={tw`p-4`} visible={visible}>
				{children}
			</DesktopModal>
		);

	if (!visible) return null;

	return (
		<View style={tw`absolute top-0 bottom-0 left-0 right-0 bg-black opacity-50`}>
			<Modal animationType={animationType} visible={visible} transparent>
				<KeyboardAwareScrollView
					keyboardShouldPersistTaps="handled"
					contentContainerStyle={tw`flex-col flex-1 justify-end items-end ${contentContainerStyle}`}
				>
					<View
						style={{
							...tw`flex w-full justify-end flex bg-white p-5 ${isIos ? "pb-7" : "pb-3"}`,
							borderTopRightRadius: 20,
							borderTopLeftRadius: 20,
							maxHeight: "90%",
							...innerViewStyle,
						}}
					>
						{children}
					</View>
				</KeyboardAwareScrollView>
			</Modal>
		</View>
	);
};

export default HalfModal;
