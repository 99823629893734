import NativeCB from "@react-native-community/checkbox";
import { useContext } from "react";
import { CheckBox } from "react-native-web";
import { PlatformContext } from "../../contexts";
import { isWeb } from "../../utils/platform";

const Checkbox = ({ checked, onChange = () => null }) => {
	const platform = useContext(PlatformContext);
	const Container = isWeb ? CheckBox : NativeCB;

	return (
		<Container
			style={{ width: 20, height: 20 }}
			boxType="square"
			color={platform.colors.yellow_main}
			onCheckColor={platform.colors.yellow_main}
			onTintColor={platform.colors.yellow_main}
			value={checked}
			onValueChange={(x) => onChange(x)}
		/>
	);
};

export default Checkbox;
