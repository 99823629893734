import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { NativeModules, Platform } from "react-native";
import * as en from "./locales/en.json";
import * as fr from "./locales/fr.json";
import * as nl from "./locales/nl.json";
import { isWeb } from "./utils/platform";

const defaultLang = "en";
const resources = {
	en: {
		translation: en,
	},
	fr: {
		translation: fr,
	},
	nl: {
		translation: nl,
	},
};

const LanguageDetector = {
	type: "languageDetector",
	detect: () => {
		let locale =
			Platform.OS === "ios"
				? NativeModules.SettingsManager?.settings?.AppleLocale ||
				  NativeModules.SettingsManager?.settings?.AppleLanguages[0] ||
				  ""
				: NativeModules.I18nManager?.localeIdentifier || "";

		if (isWeb) locale = (navigator.userLanguage || navigator.language || "").substr(0, 2);

		const [lowerCaseLocale] = locale.split("_");

		const supportedLanguages = ["en", "fr", "nl"];
		if (supportedLanguages.includes(lowerCaseLocale)) {
			return lowerCaseLocale;
		}

		return defaultLang;
	},
	init: () => {},
	cacheUserLanguage: () => {},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)

	.init({
		compatibilityJSON: "v3",
		resources,
		defaultNS: "translation",
		fallbackLng: defaultLang,

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
