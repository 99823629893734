import { useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import funBurger from "../../../assets/fun_burger2.png";
import funPizza from "../../../assets/fun_pizza.png";
import { PlatformContext } from "../../contexts";
import { isDesktop } from "../../utils/platform";
import { styles } from "../../utils/settings";
import BigButton from "../BigButton";

const ClosedRestaurant = () => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();
	const { navigate } = useNavigation();

	if (!isDesktop)
		return (
			<TouchableOpacity
				onPress={() => navigate("main", { screen: "nearby" })}
				style={tw`flex-row p-5 flex-1 rounded-xl m-4 items-center justify-between bg-[${platform.colors.yellow_main}]`}
			>
				<View style={tw`flex-1`}>
					<Text
						style={{
							...styles.header_lg,
							color: platform.isPizzy ? "white" : platform.colors.background_dark,
						}}
					>
						{t(`errors.sorry_closed`)}
					</Text>
					<Text
						style={{
							...styles.desc_s,
							...tw`mt-3`,
							color: platform.isPizzy ? "white" : platform.colors.background_dark,
						}}
					>
						{t(`errors.sorry_closed_desc.${platform.vertical}`)}
					</Text>
				</View>
				<ImageBackground
					source={platform.isPizzy ? funPizza : funBurger}
					style={tw`w-20 h-full`}
					resizeMode="contain"
				/>
			</TouchableOpacity>
		);

	return (
		<View
			style={tw`flex-1 flex-col items-center justify-around py-5 px-10 bg-[${
				platform.isPizzy ? platform.colors.background_dark : platform.colors.yellow_main
			}]`}
		>
			<View style={tw`flex-col items-center justify-center w-full`}>
				<Text
					style={{
						textAlign: "center",
						...styles.header_3xl,
						color: platform.isPizzy ? "white" : "black",
					}}
				>
					{t("errors.sorry_closed")}
				</Text>
				<Text
					style={{
						...styles.desc,
						...tw`mt-5`,
						textAlign: "center",
						color: platform.isPizzy ? "white" : platform.colors.background_dark,
					}}
				>
					{t(`errors.sorry_closed_desc.${platform.vertical}`)}
				</Text>
			</View>
			<ImageBackground
				source={platform.isPizzy ? funPizza : funBurger}
				style={tw`h-60 w-40`}
				resizeMode="contain"
			/>
			<View style={tw`flex-col items-center justify-center w-full`}>
				<Text
					style={{
						...styles.desc,
						textAlign: "center",
						color: platform.isPizzy ? "white" : platform.colors.background_dark,
					}}
				>
					{t(`cta.find_restaurants_desc.${platform.vertical}`)}
				</Text>
				<BigButton
					type={platform.isPizzy ? "yellow" : "dark-outline"}
					style={tw`w-full mt-5`}
					onPress={() => navigate("main", { screen: "nearby" })}
				>
					<Text style={tw`${platform.isPizzy ? `text-white` : ""}`}>
						{t(`cta.find_restaurants.${platform.vertical}`)}
					</Text>
				</BigButton>
			</View>
		</View>
	);
};

export default ClosedRestaurant;
