import AntDesign from "@expo/vector-icons/AntDesign";
import { TouchableOpacity } from "react-native";
import tw from "twrnc";

const ActionButton = ({ onPress, style, source = "arrowleft", size = 20, color }) => {
	return (
		<TouchableOpacity
			style={{
				...tw`bg-white w-10 h-10 rounded-full flex-row justify-center items-center`,
				...style,
			}}
			onPress={onPress}
		>
			<AntDesign name={source} size={size} color={color} />
		</TouchableOpacity>
	);
};

export default ActionButton;
