import { useState } from "react";

const DefaultStatuses = {
	success: {
		title: "Success",
		description: "Operation succeeded!",
		color: "green-300",
		reset: "continue",
	},
	error: {
		title: "Something went wrong",
		description: "Please try again later",
		color: "red-300",
		reset: "try_again",
	},
};

const useResultor = (def) => {
	const [data, setData] = useState(null);

	const set = (status, props) => {
		setData({
			name: status,
			...DefaultStatuses?.[status],
			...def?.[status],
			...props,
		});
	};

	const reset = () => setData(null);

	return { resetResult: reset, setResult: set, result: data };
};

export default useResultor;
