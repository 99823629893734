import { TouchableOpacity } from "react-native";
import tw from "twrnc";
import { isWeb } from "../../utils/platform";

const Card = ({ onPress, onLongPress, style, children }) => {
	return (
		<TouchableOpacity
			disabled={isWeb ? false : !(onPress || onLongPress)}
			onPress={onPress}
			onLongPress={onLongPress}
			style={{ ...tw`p-2 rounded-xl bg-white w-full overflow-hidden`, ...style }}
		>
			{children}
		</TouchableOpacity>
	);
};

export default Card;
