import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import tw from "twrnc";
import BigButton from "../../components/BigButton";
import HalfModal from "../../components/HalfModal";
import { InputBase, InputContainer } from "../../components/QuickAuth";
import RadioButton from "../../components/RadioButton";
import SimpleHeader from "../../components/SimpleHeader";
import TimeSelector from "../../components/TimeSelector";
import { TransProp } from "../../components/Trans";
import YellowLine from "../../components/YellowLine";
import { PlatformContext } from "../../contexts";
import { isAndroid } from "../../utils/platform";
import { styles } from "../../utils/settings";
import CheckoutAddress, { AddressBadge } from "./CheckoutAddress";

const Field = (props) => (
	<InputContainer style={{ ...tw`mt-2`, backgroundColor: "#F1F1F1", borderColor: "#bfbfbf" }}>
		<InputBase style={tw`text-black text-base`} {...props} />
	</InputContainer>
);

const getHeightOfModal = (canDeliver, isDelivery, isEatIn) => {
	let percentage = isAndroid ? 85 : 80;
	if (canDeliver) {
		if (isDelivery) percentage = isAndroid ? 98 : 90;
		else percentage = isAndroid ? 90 : 80;
	}

	if (isEatIn) percentage += 10;

	return `${Math.min(100, percentage)}%`;
};

const CheckoutModal = ({ bags, restaurant, meta, onClose }) => {
	const platform = useContext(PlatformContext);
	const [isAddressCheckout, setAddressCheckout] = useState(false);
	const [form, setForm] = useState(meta);
	const { t } = useTranslation();

	const onChange = (f, v) => setForm((d) => ({ ...d, [f]: v }));

	const onConfirm = () => {
		bags.setMetas(restaurant.id, form);
		onClose();
	};

	const onChangeWay = (way, v) => {
		if (way === "eat_in") {
			setForm((f) => ({ ...f, eat_in: v, delivery: null }));
			return;
		} else if (way === "delivery") {
			setForm((f) => ({ ...f, eat_in: false, delivery: {}, eat_in_comment: null }));
			return;
		}

		setForm((f) => ({ ...f, eat_in: false, delivery: null, eat_in_comment: null }));
	};

	const canCheckout = () => {
		if (form.delivery && !form.delivery?.place_id) return false;

		return !!form.pickup_at;
	};

	const delivery = restaurant?.delivery;

	const Inner = () =>
		isAddressCheckout ? (
			<CheckoutAddress
				addresses={restaurant?.user_addresses}
				constraints={delivery}
				onClose={() => setAddressCheckout(false)}
				setAddress={(a) => onChange("delivery", a)}
				address={form?.delivery}
			/>
		) : (
			<>
				<SimpleHeader
					hideLeft
					right={{ onPress: onClose, source: "close" }}
					style={tw`mt-0 p-0 mb-5 h-auto`}
					title={t("checkout.where_when")}
				/>
				<TouchableOpacity
					onPress={() => onChangeWay("eat_out")}
					style={tw`flex-row justify-between items-center`}
				>
					<Text style={styles.header_me}>{t("order.pickup_asap")}</Text>
					<RadioButton
						selected={!form.eat_in && !form.delivery}
						onPress={() => onChangeWay("eat_out")}
					/>
				</TouchableOpacity>
				{restaurant?.eat_in?.map((l) => (
					<TouchableOpacity
						key={`eat_in${l.id}`}
						onPress={() => onChangeWay("eat_in", l)}
						style={tw`flex-row justify-between items-center mt-3`}
					>
						<Text style={styles.header_me}>
							{t("word.eat_in_places")}
							{": "}
							<TransProp v={l.name} />
						</Text>
						<View style={tw`flex-row items-center`}>
							<RadioButton
								selected={form.eat_in?.id === l.id}
								onPress={() => onChangeWay("eat_in", l)}
							/>
						</View>
					</TouchableOpacity>
				))}
				{form.eat_in && (
					<Field
						placeholder={t("checkout.eat_in_comment")}
						onChangeText={(v) => onChange("eat_in_comment", v)}
						value={form?.eat_in_comment}
					/>
				)}
				{delivery?.enabled && (
					<TouchableOpacity
						onPress={() => onChangeWay("delivery")}
						style={tw`flex-row justify-between items-center mt-3`}
					>
						<Text style={styles.header_me}>{t("order.be_delivered")}</Text>
						<RadioButton selected={form.delivery} onPress={() => onChangeWay("delivery")} />
					</TouchableOpacity>
				)}
				{form.delivery?.place_id ? (
					<AddressBadge
						iconRight="chevron-right"
						onPress={() => setAddressCheckout(true)}
						address={form.delivery}
						type={"yellow-outline"}
						style={tw`mt-2`}
					/>
				) : form.delivery ? (
					<BigButton
						iconRight="chevron-right"
						onPress={() => setAddressCheckout(true)}
						style={{ ...tw`py-2 px-0 mt-2`, width: "100%" }}
						type={"yellow-outline"}
					>
						<Text
							style={{
								color: "black",
							}}
						>
							{t("order.pick_an_address")}
						</Text>
					</BigButton>
				) : null}
				<YellowLine style={tw`my-5`} />
				<SimpleHeader
					hideLeft
					style={tw`mt-0 p-0 mb-0 h-auto`}
					title={t(!form.delivery ? "checkout.what_time" : "checkout.from_what_time")}
				/>
				<Text style={styles.desc_xs}>{t(`checkout.desired_time_desc.${platform.vertical}`)}</Text>
				<TimeSelector
					value={form?.pickup_at}
					restaurant={restaurant}
					onPress={({ hour, mins }) =>
						onChange("pickup_at", DateTime.now().set({ hour: hour, minute: mins }).toJSDate())
					}
				/>
				<View style={tw`w-full mt-3`}>
					<BigButton disabled={!canCheckout()} onPress={onConfirm}>
						{t("word.confirm")}
					</BigButton>
				</View>
			</>
		);

	return (
		<HalfModal
			innerViewStyle={{
				minHeight: getHeightOfModal(delivery?.enabled, form?.delivery, form?.eat_in),
			}}
			animationType={isAddressCheckout ? "none" : "slide"}
			visible={true}
		>
			<KeyboardAwareScrollView
				showsVerticalScrollIndicator={false}
				keyboardShouldPersistTaps="handled"
				style={tw`flex-1 w-full`}
			>
				<Inner />
			</KeyboardAwareScrollView>
		</HalfModal>
	);
};

export default CheckoutModal;
