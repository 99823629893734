import { useTranslation } from "react-i18next";
import { ImageBackground, Text, View } from "react-native";
import tw from "twrnc";
import { styles } from "../../utils/settings";

const NoInternet = () => {
	const { t } = useTranslation();

	return (
		<View style={tw`flex-col flex-1 items-center justify-center`}>
			<Text style={styles.header_2xl}>{t("error.no_internet")}</Text>
			<ImageBackground
				resizeMode="contain"
				source={require("../../../assets/no-internet.png")}
				style={{
					height: 150,
					width: 155,
					marginTop: 50,
				}}
			/>
		</View>
	);
};

export default NoInternet;
