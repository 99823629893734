import { useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ImageBackground, Text, View } from "react-native";
import tw from "twrnc";
import boardRed from "../../../assets/board_red.png";
import funBurger from "../../../assets/fun_burger.png";
import funBurgerBig from "../../../assets/fun_burgerbig.png";
import funDrink from "../../../assets/fun_drink.png";
import funFlame from "../../../assets/fun_flame.png";
import funHotFresh from "../../../assets/fun_hotfresh.png";
import funPizza from "../../../assets/fun_pizza.png";
import funStar from "../../../assets/fun_star.png";
import { PlatformContext } from "../../contexts";
import { styles } from "../../utils/settings";
import BigButton from "../BigButton";

const NoOrder = ({ title = "errors.no_orders" }) => {
	const platform = useContext(PlatformContext);
	const { t } = useTranslation();
	const { navigate } = useNavigation();

	return (
		<View
			style={tw`flex-1 flex-col items-center justify-center bg-[${platform.colors.background}]`}
		>
			{!platform.isPizzy && (
				<>
					<ImageBackground
						source={funBurger}
						style={tw`absolute bottom-15 right-10 h-35 w-30`}
						resizeMode="contain"
					/>
					<ImageBackground
						source={funDrink}
						style={tw`absolute bottom-15 left-0 h-20 w-30`}
						resizeMode="contain"
					/>
					<ImageBackground
						source={funFlame}
						style={tw`absolute bottom-30 left-20 h-12 w-30`}
						resizeMode="contain"
					/>
					<ImageBackground
						source={funStar}
						style={tw`absolute top-35  h-10 w-30`}
						resizeMode="contain"
					/>
					<ImageBackground
						source={funBurgerBig}
						style={tw`absolute top-10 left-5 h-20 w-30`}
						resizeMode="contain"
					/>
					<ImageBackground
						source={funHotFresh}
						style={tw`absolute top-15 right-5 h-20 w-30`}
						resizeMode="contain"
					/>
					<ImageBackground
						resizeMode="repeat"
						source={boardRed}
						style={tw`absolute bottom-0 w-full h-7`}
					/>
				</>
			)}
			{platform.isPizzy && (
				<ImageBackground source={funPizza} style={tw`mb-5 h-20 w-30`} resizeMode="contain" />
			)}
			<Text style={{ ...styles.header_2xl, textAlign: "center" }}>{t(title)}</Text>
			<BigButton style={tw`mt-10 w-80`} onPress={() => navigate("main", { screen: "nearby" })}>
				{t(`cta.find_restaurants.${platform.vertical}`)}
			</BigButton>
		</View>
	);
};

export default NoOrder;
