import { track } from "@amplitude/analytics-react-native";
import { useNavigation } from "@react-navigation/native";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { ImageBackground, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import ActionButton from "../../components/ActionButton";
import RestaurantCard from "../../components/RestaurantCard";
import SearchInput from "../../components/SearchInput";
import { PlatformContext, UserContext } from "../../contexts";
import useApi from "../../hooks/useApi";
import { getRestaurants } from "../../utils/db";
import { webBack } from "../../utils/others";
import { isDesktop } from "../../utils/platform";
import settings from "../../utils/settings";

let mapboxgl;
try {
	mapboxgl = require("mapbox-gl");
	mapboxgl.accessToken = settings.mapBoxKey;
} catch {
	mapboxgl = View;
}

const Marker = ({ isPizzy, setSelected, selected, feature }) => (
	<TouchableOpacity
		onPress={() => {
			setSelected(feature.properties);
			track("MAP_CLICK_MARKER", { restaurant_id: feature.properties?.id });
		}}
	>
		<ImageBackground
			source={
				feature.properties.id === selected?.id
					? isPizzy
						? require("../../../assets/pin-yellow-pizzy.png")
						: require("../../../assets/pin-yellow.png")
					: feature.properties.is_user_fav
					? require("../../../assets/pin-red.png")
					: isPizzy
					? require("../../../assets/pin-black-pizzy.png")
					: require("../../../assets/pin-black.png")
			}
			style={{ height: feature.properties.id === selected?.id ? 50 : 40, width: 50 }}
			resizeMode="contain"
		/>
	</TouchableOpacity>
);

let exMarkers = [];

const MapModule = ({ forceCenter, showSearch = true }) => {
	const platform = useContext(PlatformContext);
	const mapContainer = useRef(null);
	const map = useRef(null);

	const { navigate, goBack } = useNavigation();
	const [selected, setSelected] = useState(null);
	const [zoom, setZoom] = useState(10);
	const { location } = useContext(UserContext);
	const [center, setCenter] = useState(location || settings.default_location);
	const [searchCenter, setSearchCenter] = useState(null);
	const { data } = useApi(getRestaurants, { limit: 999, lng: center[0], lat: center[1] }, [center]);

	useEffect(() => {
		if (map.current) return;

		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: "mapbox://styles/mapbox/streets-v12",
			center: center,
			zoom: zoom,
			showUserLocation: true,
			minZoom: 9,
		});

		map.current.on("moveend", () => {
			setCenter([map.current.getCenter().lng.toFixed(4), map.current.getCenter().lat.toFixed(4)]);
			setZoom(map.current.getZoom().toFixed(2));
		});

		if (isDesktop) {
			map.current.addControl(new mapboxgl.NavigationControl());
			map.current.addControl(
				new mapboxgl.GeolocateControl({
					positionOptions: {
						enableHighAccuracy: true,
					},
				})
			);
		}
	}, []);

	useEffect(() => {
		if (!forceCenter) return;
		setSearchCenter(forceCenter);
	}, [forceCenter]);

	useEffect(() => {
		if (!searchCenter) return;
		map.current.jumpTo({
			center: searchCenter,
		});
	}, [searchCenter]);

	useEffect(() => {
		if (!location) return;
		map.current.jumpTo({
			center: location,
		});
	}, [location]);

	useEffect(() => {
		exMarkers.forEach((x) => x.current.remove());

		data?.forEach((r) => {
			const ref = React.createRef();
			ref.current = document.createElement("div");

			exMarkers.push(ref);

			const coordinates = [r.longitude, r.latitude];

			ReactDOM.render(
				<Marker
					isPizzy={platform.isPizzy}
					anchor={{ x: 0.5, y: 1 }}
					setSelected={setSelected}
					selected={selected}
					feature={{
						type: "Feature",
						properties: r,
						geometry: {
							coordinates,
							type: "Point",
						},
					}}
				/>,
				ref.current
			);

			new mapboxgl.Marker(ref.current).setLngLat(coordinates).addTo(map.current);
		});
	}, [data, selected]);

	return (
		<View style={{ flex: 1, width: "100%" }}>
			{showSearch && (
				<View
					style={{
						position: "absolute",
						top: 30,
						zIndex: 1,
						width: "100%",
						...tw`flex-row px-3 justify-between`,
					}}
				>
					<ActionButton style={tw`bg-white mt-2`} onPress={() => webBack(goBack)} />
					<SearchInput
						showCenter
						onCenter={() => setSearchCenter(location)}
						onChange={({ lat, lng }) => setSearchCenter([lng, lat])}
						style={tw`ml-5`}
					/>
				</View>
			)}
			{selected && (
				<View
					style={{
						position: "absolute",
						bottom: 0,
						zIndex: 1,
						...tw`flex-row p-5 pb-0 items-center justify-between w-full`,
					}}
				>
					<RestaurantCard
						onCancel={isDesktop ? () => setSelected(null) : null}
						onPress={() => {
							navigate("r", { ...selected, markerRef: null });
							track("MAP_CLICK_RESTAURANT", { restaurant_id: selected?.id });
						}}
						restaurant={selected}
					/>
				</View>
			)}
			<div
				onClick={() => {
					setSelected(null);
				}}
				style={{ flex: 1 }}
				ref={mapContainer}
				className="map-container"
			/>
		</View>
	);
};

export default MapModule;
