import { TouchableOpacity, View } from "react-native";

const RadioButton = ({ onPress, selected }) => (
	<TouchableOpacity
		style={[
			{
				height: 24,
				width: 24,
				borderRadius: 12,
				borderWidth: 1,
				borderColor: "#000",
				alignItems: "center",
				justifyContent: "center",
			},
		]}
		onPress={onPress}
	>
		{selected && (
			<View
				style={{
					height: 15,
					width: 15,
					marginTop: -0,
					borderRadius: 7.5,
					backgroundColor: "#000",
				}}
			/>
		)}
	</TouchableOpacity>
);

export default RadioButton;
