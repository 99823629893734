import { useNavigation } from "@react-navigation/native";
import { useContext, useState } from "react";
import { ScrollView } from "react-native";
import tw from "twrnc";
import BigButton from "../../../components/BigButton";
import AddressSearch from "../../../components/Forms/AddressSearch";
import Input from "../../../components/Forms/Input";
import InputContainer from "../../../components/Forms/InputContainer";
import Resultor from "../../../components/Resultor";
import SimpleHeader from "../../../components/SimpleHeader";
import { PlatformContext } from "../../../contexts";
import useForm from "../../../hooks/useForm";
import useResultor from "../../../hooks/useResultor";
import { createRestaurant } from "../../../utils/db";
import { webBack } from "../../../utils/others";
import { isWeb } from "../../../utils/platform";
import Screen from "../../Screen";

const Statuses = {
	success: {
		title: "Request sent",
		description: "Someone from FRITZY will get in touch with you today",
		reset: "Continue",
	},
};

const AdminCreateRestaurantModule = () => {
	const platform = useContext(PlatformContext);
	const { goBack, navigate } = useNavigation();
	const [loading, setLoading] = useState(false);
	const [data, { setFormValue, setForm, resetForm }] = useForm();
	const { result, resetResult, setResult } = useResultor(Statuses);

	const submit = async () => {
		setLoading(true);
		const { data: result } = await createRestaurant(data);
		setLoading(false);
		if (result.id) return setResult("success");

		setResult("error", { description: result.error });
	};

	return (
		<Screen style={tw`pt-0 bg-white`}>
			<SimpleHeader left={{ onPress: () => webBack(goBack) }} title={"Add a restaurant"} />
			<ScrollView
				keyboardShouldPersistTaps="handled"
				contentContainerStyle={tw`p-4 flex-1 bg-[${platform.colors.background}]`}
			>
				<Resultor
					result={result}
					reset={{
						success: () => [resetForm(), resetResult(), webBack(goBack)],
						error: resetResult,
					}}
				>
					<InputContainer label={"Search a restaurant"}>
						<AddressSearch
							onChange={(x) => {
								setForm({
									...data,
									name: { en: x.name, fr: x.name, nl: x.name },
									mobile: x.mobile,
									longitude: x.lng,
									latitude: x.lat,
									address: x,
								});
							}}
							inputContainerStyle={{
								...tw`w-full rounded-xl border py-2 px-2`,
								zIndex: 99999,
							}}
							resultsContainerStyle={{
								...tw`rounded-xl border pt-9 mt--8.7`,
								borderColor: platform.colors.yellow_main,
							}}
							inputStyle={{ ...tw`w-full`, ...(isWeb && { outline: "none" }) }}
						/>
					</InputContainer>
					{data?.latitude && (
						<>
							<Input
								value={data?.name?.en}
								label="Name of your restaurant"
								onChange={(v) => setFormValue("name", { en: v })}
							/>
							<Input
								value={data.email}
								label="Your business email"
								onChange={(v) => setFormValue("email", v)}
							/>
							<Input
								value={data.mobile}
								label="Your private mobile"
								onChange={(v) => setFormValue("mobile", v)}
							/>
							<BigButton disabled={loading} onPress={submit}>
								Submit information
							</BigButton>
						</>
					)}
				</Resultor>
			</ScrollView>
		</Screen>
	);
};

export default AdminCreateRestaurantModule;
